import React from 'react';

export const Progress: React.FC<{
    percent: number;
    marker?: number;
    gradient?: boolean;
    height?: string;
}> = ({ percent, marker, gradient = true, height = 'h-3' }) => {
    return (
        <div
            className={`overflow-hidden text-xs flex rounded-full relative border border-border border-opacity-5 ${height} ${
                gradient ? 'bg-surface-level-zero' : 'bg-surface-level-one'
            }`}
        >
            {marker && (
                <div
                    className={`absolute w-1 bg-white ${height}`}
                    style={{ left: `${marker}%` }}
                >
                    <div className="absolute text-base flex h-full items-center right-4">
                        <strong>MIN</strong>
                    </div>
                </div>
            )}
            <div
                style={{ width: `${percent}%` }}
                className={`rounded-full ${
                    gradient ? 'bg-gradient' : 'bg-accent'
                }`}
            ></div>
        </div>
    );
};
