import { RadioGroup } from '@headlessui/react';
import { Typography } from '@decub8/ui';

import { ChallengeQuestion } from '@src/ts/interfaces';

import { Card } from './Card';

export const Question: React.FC<{
    num: number;
    q: ChallengeQuestion;
    selected: string;
    setSelected: (v: string) => void;
}> = ({ num, q, selected, setSelected }) => {
    return (
        <div className="my-4">
            <Card>
                <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label>
                        <Typography
                            className="mb-8"
                            variant="primary"
                            size="2xl"
                            allBold
                        >
                            {num}. {q.question}
                        </Typography>
                    </RadioGroup.Label>
                    <div className="w-full space-y-4">
                        {q.answers.map((answer) => (
                            <RadioGroup.Option
                                key={q.question + answer}
                                value={answer}
                                className={({ checked }) =>
                                    `cursor-pointer w-full  py-2 px-4 rounded border border-border  ${
                                        checked
                                            ? 'text-surface-level-zero bg-white border-opacity-10'
                                            : 'bg-surface-level-two text-secondary border-opacity-5 hover:text-primary hover:bg-surface-level-two'
                                    }`
                                }
                            >
                                {answer}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </Card>
        </div>
    );
};
