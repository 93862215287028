import React, { useEffect, useRef } from 'react';
import { LandingPageVideoComp, useBreakpoint } from '@decub8/ui';
import { NextRouter, useRouter } from 'next/router';

import { useLightbox } from '@src/hooks/pages/useLightbox';
import { ContentAlignment } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';

interface LandingPageVideoCompRef {
    handleLightboxToggle: () => void;
    domRef: React.RefObject<HTMLDivElement>;
}
const goToUrl = (url: string, router: NextRouter) =>
    url.toLowerCase().startsWith('http')
        ? window.open(url, '_blank')
        : router.push(url);

export const LandingPageVideoSection: React.FC<{
    section: IContentSection;
}> = ({ section }) => {
    const router = useRouter();
    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < 911;

    const { lightboxOpen, setLightboxOpen } = useLightbox();
    const landingVideoRef = useRef<LandingPageVideoCompRef>(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                lightboxOpen &&
                landingVideoRef.current &&
                !landingVideoRef.current.domRef.current?.contains(event.target)
            ) {
                setLightboxOpen(false);
            }
        };

        if (lightboxOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [lightboxOpen]);

    const desktop_image = section.images?.find((img) => img.name === 'desktop');
    const mobile_image = section.images?.find((img) => img.name === 'mobile');

    const video_btn = section.buttons?.find((btn) => btn.name === 'video');
    const primary_btn = section.buttons?.find((btn) => btn.name === 'primary');
    const secondary_btn = section.buttons?.find(
        (btn) => btn.name === 'secondary',
    );

    return (
        <div className={is_mobile ? '' : 'px-5'}>
            <LandingPageVideoComp
                className={`max-w-screen-lg mx-auto fixed ${
                    lightboxOpen ? 'z-[90]' : 'z-[50]'
                }`}
                video_btn={
                    video_btn
                        ? {
                              button_text: video_btn?.text,
                              embed_url: video_btn?.url,
                          }
                        : undefined
                }
                ref={landingVideoRef}
                lightboxOpen={lightboxOpen}
                setLightboxOpen={setLightboxOpen}
                title={section.title}
                subtitle={section.text}
                images={{
                    desktop: desktop_image?.url,
                    mobile: mobile_image?.url,
                }}
                buttons={[
                    {
                        text: primary_btn?.text || '',
                        handleClick: () => goToUrl(primary_btn?.url, router),
                    },
                    {
                        text: secondary_btn?.text || '',
                        handleClick: () => goToUrl(secondary_btn?.url, router),
                    },
                ]}
                has_centered_info={
                    section.alignment === ContentAlignment.Centre
                }
                secondary_btn_has_outline={secondary_btn?.opaque}
            />
        </div>
    );
};
