import React from 'react';
import ReactTooltip from 'react-tooltip';

import { DECUBATE_UI_THEME } from '@src/config';

export const Tooltip: React.FC<{
    text: string;
    active?: boolean;
    className?: string;
    children?: React.ReactNode;
}> = ({ children, text, active = true, className }) => {
    const background_color = DECUBATE_UI_THEME?.surface?.['level-three']
        ? DECUBATE_UI_THEME.surface?.['level-three']
        : '#3E334D';

    return (
        <div className={className}>
            <div data-html={true} data-tip={active ? text : undefined}>
                {children}
            </div>
            <ReactTooltip
                className="text-white rounded opacity-100"
                backgroundColor={background_color}
                html={true}
            />
        </div>
    );
};
