import { useContext, useState } from 'react';
import { createContext } from 'react';

import { DEFAULT_CHAIN_ID } from '@src/config';
import { ContractType } from '@src/ts/constants';
import { Investment } from '@src/ts/interfaces';

import { useGlobalContext } from '../useGlobalContext';

interface RefundDetails {
    project_name: string;
    total_invested: string;
    payment_symbol: string;
    refund_deadline: string;
    handleRefund: () => Promise<boolean>;
    refund_chain_id: number;
}

interface IInvestmentContext {
    investments: Investment[];
    refund_modal_open: boolean;
    refund_complete_modal_open: boolean;

    setInvestments: (investments: Investment[]) => void;
    setRefundModalOpen: (open: boolean) => void;
    setRefundCompleteModalOpen: (open: boolean) => void;
    setRefundDetails: (details: RefundDetails) => void;
    updateInvestmentVesting: (
        contract_address: string,
        account: string,
        contract_type: ContractType,
        chainId?: number,
    ) => void;

    refund_details?: RefundDetails;
}

const InvestmentContext = createContext<IInvestmentContext>(null);

export const InvestmentContextProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [investments, setInvestments] = useState<Record<string, Investment>>(
        {},
    );
    const [refund_modal_open, setRefundModalOpen] = useState(false);
    const [refund_complete_modal_open, setRefundCompleteModalOpen] =
        useState(false);
    const [refund_details, setRefundDetails] = useState<RefundDetails>();

    const { contract_manager } = useGlobalContext();

    const updateInvestmentVesting = (
        contract_address: string,
        account: string,
        contract_type: ContractType,
        chainId?: number,
    ) => {
        if ([null, undefined].includes(chainId)) chainId = DEFAULT_CHAIN_ID;

        if (ContractType.Investments === contract_type) {
            contract_manager
                .getContract(ContractType.Investments)
                .contract.getVestingInfo(account, contract_address)
                .then(({ available, released }) => {
                    setInvestments({
                        ...investments,
                        [contract_address]: {
                            ...investments[contract_address],
                            released: released.toString(),
                            available: available.toString(),
                        },
                    });
                })
                .catch((err) =>
                    console.log(
                        'error getting updated investment details',
                        err,
                    ),
                );
        } else {
            contract_manager
                .getContract(contract_type, chainId)
                .contract.getInvestmentInfo(account, contract_address)
                .then((res) =>
                    setInvestments({
                        ...investments,
                        [contract_address]: {
                            ...investments[contract_address],
                            released: res.w.distributedAmount.toString(),
                            available: res.claimable.toString(),
                            refunded: res.w.refunded,
                        },
                    }),
                )
                .catch((err) =>
                    console.error(
                        `error getting investment information: Account - ${account}, Event - ${contract_address}`,
                        err,
                    ),
                );
        }
    };

    return (
        <InvestmentContext.Provider
            value={{
                investments: Object.values(investments),
                refund_modal_open,
                refund_complete_modal_open,
                refund_details,
                setInvestments: (investments: Investment[]) =>
                    setInvestments(
                        investments.reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr.contract_address]: curr,
                            }),
                            {},
                        ),
                    ),
                setRefundModalOpen,
                setRefundCompleteModalOpen,
                setRefundDetails,
                updateInvestmentVesting,
            }}
        >
            {children}
        </InvestmentContext.Provider>
    );
};

export function useInvestmentContext(): IInvestmentContext {
    return useContext(InvestmentContext);
}
