import React from 'react';
import Link from 'next/link';

import { User } from '@src/ts/interfaces';

import AccountItem from './AccountItem';

const SecurityInfo: React.FC<{ user: User }> = ({ user }) => {
    const has_2fa = user?.enabled_2fa;

    return (
        <div>
            <h4 className="text-lg my-8">
                <strong>Security</strong>
            </h4>

            <AccountItem title="2-step authentication">
                <div className="flex items-center">
                    <h5 className="text-lg flex-1">
                        {has_2fa ? 'Enabled' : 'Disabled'}
                    </h5>

                    {!has_2fa && (
                        <Link
                            href="/2fa"
                            className="text-accent underline hover:opacity-80"
                        >
                            Enable
                        </Link>
                    )}
                </div>
            </AccountItem>
        </div>
    );
};

export default SecurityInfo;
