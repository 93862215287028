import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { VerifyEmailProps } from '@decub8/ui';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap';
import { IS_PROD } from '@src/config';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { USER } from '@src/services';
import { newAnalyticsEvent } from '@src/utils/user';

import { getAndSetUser } from '../useLogin/utils';

type ConfirmRegister = {
    confirmRegister?: {
        access_token?: string;
        refresh_token?: string;
    };
};

export const useVerifyEmail = (): VerifyEmailProps => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const router = useRouter();
    const [secret, setSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useAppDispatch();

    const { referred, email = '', redirect } = router.query;

    const handleResendEmail = async () => {
        try {
            let captcha;
            if (IS_PROD) {
                captcha = await executeRecaptcha('resend_confirmation_email');
            }
            await api_client.mutate({
                mutation: USER.RESEND_VERIFICATION_EMAIL,
                variables: {
                    email,
                    captcha,
                },
            });
            toast.success('Verification email has been sent successfully');
        } catch (e) {
            toast.error(e.message);
        }
    };

    async function handleSecret() {
        setLoading(true);
        const session = localStorage.getItem('decubate_session') || '';
        try {
            let captcha;
            if (IS_PROD) {
                captcha = await executeRecaptcha('verify_email');
            }
            const {
                confirmRegister: { access_token, refresh_token },
            }: ConfirmRegister = await api_client.mutate({
                mutation: USER.CONFIRM_REGISTER,
                variables: { email, secret, session, captcha },
            });

            //emit analytics event
            newAnalyticsEvent('verify_email');

            //set auth tokens in local storage
            localStorage.setItem('decubate_access_token', access_token);
            localStorage.setItem('decubate_refresh_token', refresh_token);

            // get and set user in global state
            await getAndSetUser(dispatch);

            //push user to correct route
            router.push(
                referred === 'true'
                    ? '/referral-welcome'
                    : '/verify/identity?onboarding=true',
            );
        } catch (e) {
            if (e.message === 'Invalid session') {
                if (!redirect) {
                    // Have to get the user to log in again as they navigated away from the login flow originally and their session which contains the users password to login is no longer valid
                    toast.success(
                        'Email has been confirmed but the registration session expired, please log in again.',
                    );
                }

                return router.push((redirect as string) || '/login');
            }

            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (secret.length === 6) {
            handleSecret();
        }
    }, [secret.length]);

    if (user?.active) {
        router.push('/account');
    }

    return {
        email: email as string,
        loading: loading,
        title: 'Verify email',
        value: secret,
        handleChange: setSecret,
        handleResendCode: handleResendEmail,
    };
};
