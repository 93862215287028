import { LoginStage } from '@decub8/ui';
import router from 'next/router';

import { api_client } from '@src/bootstrap';
import { AppDispatch } from '@src/bootstrap/store';
import { IS_PROD } from '@src/config';
import { setUser } from '@src/features/auth';
import { USER } from '@src/services/user';
import { JWTResponse, User } from '@src/ts/interfaces';

export interface State {
    stage: LoginStage;
    email: string;
    password: string;
    code: string;
}

export const getAndSetUser = async (dispatch: AppDispatch): Promise<User> => {
    const { retrieveMe } = (await api_client.query({
        query: USER.RETRIEVE_ME,
        fetchPolicy: 'network-only',
    })) as { retrieveMe: User };
    dispatch(setUser(retrieveMe));
    return retrieveMe;
};

export const attemptLogin = async (
    state: State,
    setState: (s: State) => void,
    setLoading: (b: boolean) => void,
    setErrors: (obj: Record<string, string>) => void,
    executeRecaptcha: (action?: string) => Promise<string>,
    dispatch: AppDispatch,
): Promise<void> => {
    setLoading(true);
    const { email, password } = state;
    try {
        let captcha;
        if (IS_PROD) {
            captcha = await executeRecaptcha('login');
        }

        const { login } = (await api_client.mutate({
            mutation: USER.LOGIN,
            variables: { email, password, captcha },
        })) as { login: JWTResponse };
        const {
            access_token,
            refresh_token,
            requires_2fa,
            requires_email_verification,
            session,
        } = login;

        localStorage.setItem('decubate_session', session);
        if (requires_email_verification) {
            router.push('/verify/email?email=' + email);
        } else if (requires_2fa) {
            // change state to next state and ask user to get password from authenticator app
            setState({ ...state, stage: LoginStage.MFA });
        } else {
            localStorage.setItem('decubate_access_token', access_token);
            localStorage.setItem('decubate_refresh_token', refresh_token);
            await getAndSetUser(dispatch);
        }
    } catch (err) {
        setErrors({
            form: err.message,
        });
        localStorage.setItem('decubate_access_token', '');
        localStorage.setItem('decubate_refresh_token', '');
    } finally {
        setLoading(false);
    }
};

export const attempVerify = async (
    code: string,
    state: State,
    setLoading: (b: boolean) => void,
    setErrors: (obj: Record<string, string>) => void,
    executeRecaptcha: (action?: string) => Promise<string>,
    dispatch: AppDispatch,
): Promise<void> => {
    setLoading(true);
    try {
        let captcha;
        if (IS_PROD) {
            captcha = await executeRecaptcha('login');
        }
        const session = localStorage.getItem('decubate_session');
        const { email } = state;
        const {
            verify2FA: { access_token, refresh_token },
        } = (await api_client.mutate({
            mutation: USER.VERIFY_2FA,
            variables: { secret: code, email, captcha, session },
        })) as { verify2FA: JWTResponse };

        localStorage.setItem('decubate_access_token', access_token);
        localStorage.setItem('decubate_refresh_token', refresh_token);
        await getAndSetUser(dispatch);
    } catch (err) {
        setErrors({ form: err.message });
    }
    setLoading(true);
};
