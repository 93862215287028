import { useEffect, useState } from 'react';

export const useForceUpdate = (interval = 5000): boolean => {
    const [curr_state, updateState] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => updateState(!curr_state), interval);

        return () => clearTimeout(handler);
    }, [curr_state]);

    return curr_state;
};
