import { ALL_CHAIN_IDS, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { ContractType } from '@src/ts/constants';

import CompoundStaking from './abi/CompoundStaking.json';
import ERC20 from './abi/ERC20.json';
import EventFactory from './abi/EventFactory.json';
import Investments from './abi/Investments.json';
import IUniswapV2Pair from './abi/IUniswapV2Pair.json';
import LegacyCompoundStaking from './abi/LegacyCompoundStaking.json';
import LegacyEventFactory from './abi/LegacyEventFactory.json';
import LegacyLiquidityStaking from './abi/LegacyLiquidityStaking.json';
import LegacyVault from './abi/LegacyVault.json';
import LiquidityStaking from './abi/LiquidityStaking.json';
import MultiAssetStaking from './abi/MultiAssetStaking.json';
import MultiCall from './abi/MultiCall.json';
import NFT from './abi/NFT.json';
import PlatformVesting from './abi/PlatformVesting.json';
import StakingV1 from './abi/StakingV1.json';
import TierMigrator from './abi/TierMigrator.json';
import Tiers from './abi/Tiers.json';
import Vault from './abi/Vault.json';
import WalletStore from './abi/WalletStore.json';
import { ContractManager, IContractManager, RpcManager } from './manager';

export const ABI = {
    [ContractType.BaseToken]: ERC20,
    [ContractType.CompoundStaking]: CompoundStaking,
    [ContractType.LegacyCompoundStaking]: LegacyCompoundStaking,
    [ContractType.ERC20]: ERC20,
    [ContractType.EventFactory]: EventFactory,
    [ContractType.LegacyEventFactory]: LegacyEventFactory,
    [ContractType.Investments]: Investments,
    [ContractType.LegacyLiquidityStaking]: LegacyLiquidityStaking,
    [ContractType.LiquidityStaking]: LiquidityStaking,
    [ContractType.MultiAssetStaking]: MultiAssetStaking,
    [ContractType.MultiCall]: MultiCall,
    [ContractType.NFT]: NFT,
    [ContractType.PaymentToken]: ERC20,
    [ContractType.StakingV1]: StakingV1,
    [ContractType.Tiers]: Tiers,
    [ContractType.TierMigrator]: TierMigrator,
    [ContractType.UniswapV2Pair]: IUniswapV2Pair,
    [ContractType.LegacyVault]: LegacyVault,
    [ContractType.Vault]: Vault,
    [ContractType.WalletStore]: WalletStore,
    [ContractType.PlatformVesting]: PlatformVesting,
};

export const getBaseTokenDecimals = (chain_id?: number) =>
    CONTRACT[ContractType.BaseToken][chain_id || DEFAULT_CHAIN_ID].decimals;

export const BaseTokenSymbol: string =
    CONTRACT[ContractType.BaseToken][DEFAULT_CHAIN_ID].symbol;

export const PaymentTokenSymbol = (chainId: number) =>
    CONTRACT[ContractType.PaymentToken][chainId || DEFAULT_CHAIN_ID].symbol;

export function createContractManager(): IContractManager {
    const rpcManagers = ALL_CHAIN_IDS.reduce((acc, chain_id) => {
        acc[chain_id] = new RpcManager(chain_id);
        return acc;
    }, {});

    return new ContractManager(rpcManagers, CONTRACT, ABI, DEFAULT_CHAIN_ID);
}

export * from './manager';
