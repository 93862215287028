import React from 'react';
import { Alert, Media, MediaVariant } from '@decub8/ui';

import { CONTENT } from '@src/config';

export const EventCompleteAlert: React.FC = () => {
    return (
        <Alert className="w-full" variant="note" title="Event Complete">
            To stay informed about future opportunities, join our community to
            receive updates on upcoming events and whitelist openings.
            <span className="flex space-x-10 mt-[16px]">
                {CONTENT.social.slice(0, 3).map(({ type, url }) => (
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={type + url}
                    >
                        <Media
                            variant={type as MediaVariant}
                            size={0}
                            className="text-accent h-[18px]"
                        />
                    </a>
                ))}
            </span>
        </Alert>
    );
};
