import React, { useState } from 'react';

import { Check } from '@src/components/Check';
import { SelectProps } from '@src/ts/props';

export const Select: React.FC<SelectProps> = ({
    className,
    id,
    label,
    onSelect,
    selected,
    options,
    error,
    valid,
    placeholder,
    initialized = true,
    getId = (i: string) => i,
    getLabel = (i: string) => i,
    onBlur = () => null,
}) => {
    const [focused, setFocused] = useState(false);
    return (
        <div className={`w-full ${className || ''}`}>
            <div className="flex space-between items-center text-secondary">
                <label className="text-sm mb-2" htmlFor={id}>
                    {label}
                </label>
                {initialized && error && (
                    <div className="flex-1 text-right text-xs text-accent mb-2">
                        {error}
                    </div>
                )}{' '}
                {valid && (
                    <div className={`text-right flex-1`}>
                        <Check className="text-green-500 mr-auto mb-2 ml-2 float-right" />
                    </div>
                )}
            </div>
            <select
                onFocus={() => setFocused(true)}
                onBlur={() => {
                    onBlur();
                    setFocused(false);
                }}
                onChange={(e) => onSelect(e.target.value)}
                className={`shadow appearance-none w-full bg-surface-level-zero py-3 px-3 leading-tight border focus:outline-none focus:shadow-outline rounded dark-placeholder ${
                    (initialized && error && ' border-accent') ||
                    (focused && 'border-secondary') ||
                    'border-border border-opacity-5'
                } ${
                    ['', undefined, null].includes(selected)
                        ? 'text-placeholder'
                        : ''
                }`}
                value={selected}
                id={id}
            >
                <option value="" disabled>
                    {placeholder}
                </option>

                {options.map((item) => (
                    <option
                        key={getId(item)}
                        id={getId(item)}
                        value={getId(item)}
                    >
                        {getLabel(item)}
                    </option>
                ))}
            </select>
        </div>
    );
};
