import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ResetPasswordProps, ResetPasswordStage } from '@decub8/ui';
import { useRouter } from 'next/router';

import { validatePassword } from '@src/utils/user';

import { attemptPasswordChange, attemptRequestPasswordChange } from './util';

export interface ResetPasswordState {
    stage: ResetPasswordStage;
    email: string;
    password: string;
    retype_password: string;
    code: string;
}

export const useResetPassword = (): ResetPasswordProps => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<Record<string, unknown>>({});
    const router = useRouter();
    const [isDisabled, setIsDisabled] = useState(true);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const { email } = router.query;

    const [state, setState] = useState({
        stage: email ? ResetPasswordStage.Password : ResetPasswordStage.Email,
        email: (email as string) || '',
        password: '',
        retype_password: '',
        code: '',
    });

    const handleChange = (name: string, value: string) => {
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        const password_not_valid = !validatePassword(state.password);
        if (password_not_valid) {
            setErrors({ ...errors, password: 'password not valid' });
        } else if (state.password !== state.retype_password) {
            setErrors({ ...errors, password: 'passwords do not match' });
        } else {
            setErrors({ ...errors, password: '' });
        }
    }, [state.password, state.retype_password]);

    const handlePasswordChange = useCallback(
        () => attemptPasswordChange(state, errors, setLoading, setErrors),
        [state, errors, setLoading, setErrors],
    );

    useEffect(() => {
        setIsDisabled(
            state.stage === ResetPasswordStage.Password
                ? state.password === '' ||
                      state.code === '' ||
                      errors.password !== '' ||
                      state.code.length < 6 ||
                      state.retype_password !== state.password
                : state.email === '',
        );
    }, [state, errors]);

    const handleRequestPasswordChange = useCallback(() => {
        return attemptRequestPasswordChange(
            state,
            setLoading,
            setState,
            setErrors,
            executeRecaptcha,
        );
    }, [state, setLoading, setState, setErrors, executeRecaptcha]);

    return {
        handleClick:
            state.stage === ResetPasswordStage.Password
                ? handlePasswordChange
                : handleRequestPasswordChange,
        state,
        handleChange,
        handleResendCode: handleRequestPasswordChange,
        handleCreateAccount: () => router.push('/register'),
        handleLogin: () => router.push('/login'),
        errors: errors,
        loading,
        className: 'w-full',
        disabled: isDisabled,
    };
};
