import { BigNumber } from '@ethersproject/bignumber';

import { CONTRACT } from '@src/config';
import { StakingPool } from '@src/ts/interfaces';
import { legacy_staking_versions, staking_versions } from '@src/ts/types';

export const parseLockPeriod = (pool: StakingPool): string =>
    pool && pool.lock_period && pool.lock_period !== '0'
        ? pool.lock_period
        : '1';

export const effectiveApy = (r: number, n: number): number =>
    (1 + r / n) ** n - 1;

export const getPoolMultiplier = (
    pool: StakingPool,
): { boost: number; has_boost: boolean } => {
    const is_liquidity = ['liquidity', 'legacy-liquidity'].includes(pool?.type);

    const base = is_liquidity ? 100 : 10;

    const {
        user_multiplier = base.toString(),
        nft_multiplier = base,
        nft_multiplier_used,
        apr,
    } = pool;

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / base - parseFloat(apr)) *
                base,
        ) / base;

    const has_boost =
        nft_multiplier_used &&
        BigNumber.from(user_multiplier).eq(nft_multiplier);

    return { boost, has_boost };
};

export const getStakingVersions = (is_legacy: boolean): string[] =>
    is_legacy
        ? legacy_staking_versions
        : Array.from(staking_versions).filter(
              (v: string) => !(legacy_staking_versions as string[]).includes(v),
          );

export const getSupportedStakingVersions = () => {
    const versions = [];

    if (CONTRACT.StakingV1) versions.push('v1');
    if (CONTRACT.MultiAssetStaking) versions.push('multi-asset');
    if (CONTRACT.LegacyCompoundStaking) versions.push('legacy-compound');
    if (CONTRACT.LegacyLiquidityStaking) versions.push('legacy-liquidity');
    if (CONTRACT.LiquidityStaking) versions.push('liquidity');
    if (CONTRACT.CompoundStaking) versions.push('compound');

    return versions;
};
