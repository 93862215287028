import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BigNumber } from '@ethersproject/bignumber';

import { DEFAULT_CHAIN_ID } from '@src/config';
import { cancellablePromise } from '@src/utils/promise';

import { useSwitchChain } from '../useSwitchChain';
import { useERC20Contract } from '..';

const MAX_APPROVE_AMOUNT =
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const useTokenApproval = (
    address: string,
    approver: string,
    approvee: string,
    approve_amount: string,
    chainId?: number,
): {
    approved: boolean;
    loading: boolean;
    handleApprove: () => Promise<void>;
} => {
    if ([null, undefined].includes(chainId)) chainId = DEFAULT_CHAIN_ID;

    const [approved, setHasApproval] = useState(false);
    const [loading, setLoading] = useState(false);

    const token = useERC20Contract(address, true);
    const { isConnectedChainEventChain, setChainID } = useSwitchChain();

    useEffect(() => {
        if (token) {
            const p = token
                .allowance(approver, approvee)
                .then((amount: BigNumber) => {
                    return approve_amount === '0'
                        ? amount.gt(0)
                        : amount.gte(approve_amount);
                });

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((approved: boolean) => setHasApproval(approved))
                .catch(() => true);

            return cancel;
        }
    }, [approve_amount, approver, approvee]);

    const handleApprove = async () => {
        // first let metamask change chain if user is not on default chain
        if (!isConnectedChainEventChain(chainId)) {
            await setChainID(chainId);
            return;
        }

        setLoading(true);
        toast.info('Awaiting successful transaction');
        try {
            const estimated_gas = await token.estimateGas.approve(
                approvee,
                MAX_APPROVE_AMOUNT,
            );

            const approve_tx = await token.approve(
                approvee,
                MAX_APPROVE_AMOUNT,
                {
                    gasLimit: estimated_gas.mul(6).div(5), // increase by 20 %
                },
            );
            await approve_tx.wait();
            toast.success('Transaction successfully complete');
            setHasApproval(true);
        } catch (err) {
            toast.error(err.reason || err.message);
        }
        setLoading(false);
    };

    return { approved, loading, handleApprove };
};
