import { RewardModal } from '@decub8/ui';

import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';

export const CongratulationModal: React.FC<{
    show: boolean;
    setShow: (bool: boolean) => void;
    tier: number;
    reward: string;
}> = ({ show, setShow, tier, reward }) => {
    return (
        <RewardModal
            show={show}
            onClose={() => setShow(false)}
            image_url={CONTENT.referralConfig.rewardModal.image_urls[tier]}
            reward_amount={`${reward} ${BaseTokenSymbol}`}
        ></RewardModal>
    );
};
