import React, { useCallback, useRef } from 'react';
import {
    BreakPoint,
    PoolContainer,
    PoolHeader,
    useBreakpoint,
} from '@decub8/ui';

import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { scrollIntoView } from '@src/utils/device';

import { useFilteredPools } from './hooks';
import PoolItem from './PoolItem';

const PoolsContainer: React.FC<{
    title: string;
    children?: React.ReactNode;
}> = ({ title, children }) => {
    const breakpoint = useBreakpoint();
    const use_mobile_pool = breakpoint < BreakPoint.LG;

    return (
        <div className="mb-8 mt-7">
            <h2 className="text-3xl mb-8">
                <strong>{title}</strong>
            </h2>
            {use_mobile_pool ? <div></div> : <PoolHeader className="mb-4" />}
            <div
                className="space-y-5 lg:space-y-"
                data-testid={`${title
                    .replace(' ', '-')
                    .toLowerCase()}-container`}
            >
                {' '}
                {children}
            </div>
        </div>
    );
};

export const StakingPools: React.FC<{
    is_legacy?: boolean;
}> = ({ is_legacy }) => {
    const { account, connect } = useWeb3Onboard();
    const active_pools_ref = useRef(null);
    const [has_stake, active_no_stake] = useFilteredPools(is_legacy);

    const breakpoint = useBreakpoint();
    const use_mobile_pool = breakpoint < BreakPoint.LG;

    const executeScroll = useCallback(
        () => scrollIntoView(active_pools_ref),
        [active_pools_ref],
    );

    return (
        <div className="mt-8 mb-32">
            <PoolsContainer title="Engaged pools">
                {has_stake.length > 0 ? (
                    has_stake.map((p) => (
                        <PoolItem
                            key={p.id}
                            pool={p}
                            is_mobile={use_mobile_pool}
                        />
                    ))
                ) : account ? (
                    <PoolContainer
                        isEmpty
                        variant="stake-none"
                        handlePoolClick={executeScroll}
                    />
                ) : (
                    <PoolContainer
                        isEmpty
                        variant="conn-none"
                        handleConnect={() => connect()}
                    />
                )}
            </PoolsContainer>

            <div ref={active_pools_ref}>
                <PoolsContainer title="Active pools">
                    {active_no_stake.length > 0 ? (
                        active_no_stake.map((p) => (
                            <PoolItem
                                key={p.id}
                                pool={p}
                                is_mobile={use_mobile_pool}
                            />
                        ))
                    ) : has_stake.length > 0 ? (
                        <PoolContainer isEmpty variant="stake-full" />
                    ) : (
                        <PoolContainer isEmpty variant="stake-none" />
                    )}
                </PoolsContainer>
            </div>
        </div>
    );
};
