import React, { useState } from 'react';

import { ModalProps } from '@src/ts/props';

interface ImageModalProps extends ModalProps {
    url: string;
    alt: string;
}

export const ImageModal: React.FC<ImageModalProps> = ({
    onClose,
    url,
    alt,
}) => {
    const [inside_card, setInsideCard] = useState(false);
    return (
        <div
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover text-white"
            id="modal-id"
            onClick={() => !inside_card && onClose()}
        >
            <div className="absolute bg-surface-level-zero opacity-80 inset-0 z-0"></div>
            <div
                className="w-full max-w-6xl relative mx-auto my-auto"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
            >
                <button
                    onClick={onClose}
                    className="mr-2 top-2 right-0 hidden sm:abolute"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-black"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <img src={url} className="w-full rounded" alt={alt} />
            </div>
        </div>
    );
};
