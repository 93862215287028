import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import auth_reducer from '@src/features/auth';
import layout_reducer from '@src/features/layout';
import staking_reducer from '@src/features/staking';

const reducer = combineReducers({
    auth: auth_reducer,
    layout: layout_reducer,
    staking: staking_reducer,
});

export const store = createStore(reducer, applyMiddleware(thunk));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// eslint-disable-next-line
export type AppDispatch = ThunkDispatch<RootState, void, Action>;
