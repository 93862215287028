import React, { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';
import dayjs from 'dayjs';
import Link from 'next/link';

import { CONTENT } from '@src/config';
import { useCountdown } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';
import { User } from '@src/ts/interfaces';
import { displayCountdown } from '@src/utils/format';
import { shortenHex } from '@src/utils/web3';

import AccountItem from './AccountItem';

const getText = (user: User, is_verified: boolean): string => {
    if (user && !user.wallet_address) {
        return 'No verified wallet';
    }
    if (user?.wallet_address && !is_verified) {
        return 'Wallet verification pending...';
    }

    return shortenHex(user?.wallet_address || '', 6);
};

const Timer: React.FC<{ cooldown: string }> = ({ cooldown }) => {
    const time_left = useCountdown(cooldown);

    return (
        <p className="text-white">
            <strong>
                {time_left &&
                    !isNaN(time_left.seconds()) &&
                    displayCountdown(time_left)}
            </strong>
        </p>
    );
};

const WalletInfo: React.FC<{ user: User }> = ({ user }) => {
    const { account, connect } = useWeb3Onboard();
    const [is_whitelisted, setIsWhitelisted] = useState(false);
    const [cooldown, setCooldown] = useState(null);
    const { wallet_address } = user || {};
    const { contract_manager } = useGlobalContext();

    const connected_wallet_is_selected =
        account && account?.toLowerCase() === wallet_address?.toLowerCase();

    useEffect(() => {
        let is_mounted = true;
        if (wallet_address) {
            const wallet_store = contract_manager.getContract(
                ContractType.WalletStore,
            );
            wallet_store.contract
                .isVerified(wallet_address)
                .then((res: boolean) => is_mounted && setIsWhitelisted(res))
                .catch((err: unknown) =>
                    log.error('Error checking if wallet is verified', err),
                );

            wallet_store.contract
                .users(wallet_address)
                .then(async (res: { lastAccessTime: BigNumber }) => {
                    const wait_time = await wallet_store.contract.waitTime();

                    const when_available = dayjs(
                        res.lastAccessTime.add(wait_time).mul(1000).toNumber(),
                    );

                    if (dayjs().isBefore(when_available) && is_mounted) {
                        setCooldown(when_available.toISOString());
                    } else if (is_mounted) {
                        setCooldown(null);
                    }
                })
                .catch((err: unknown) =>
                    log.error('error checking users wallet info', err),
                );
        }

        return () => {
            is_mounted = false;
        };
    }, [wallet_address, contract_manager]);

    return (
        <div>
            <h4 className="text-lg my-8">
                <strong>Verified wallet</strong>
            </h4>

            {(!wallet_address || !connected_wallet_is_selected || cooldown) && (
                <div className="mb-6 border border-secondary rounded text-secondary p-4">
                    {!wallet_address &&
                        `You need to verify your wallet to use all ${CONTENT.companyName} products without restrictions.`}
                    {!connected_wallet_is_selected &&
                        `Connect your verified wallet to use all ${CONTENT.companyName} products without restrictions.`}
                    {connected_wallet_is_selected && cooldown && (
                        <div className="flex">
                            <div className="flex-1">
                                You can change your verified wallet again in
                            </div>
                            <Timer cooldown={cooldown} />
                        </div>
                    )}
                </div>
            )}

            <AccountItem title="Verified wallet">
                <div className="flex items-center">
                    <h5 className="text-lg flex-1">
                        <div className="md:flex items-center">
                            <p>{getText(user, is_whitelisted)}</p>

                            {wallet_address && (
                                <div className="md:ml-4 px-2 py-1 w-28 text-center my-1 md:my-0 md:w-auto rounded-full bg-surface-level-two border border-border border-opacity-5 text-secondary text-sm">
                                    {connected_wallet_is_selected
                                        ? 'Connected'
                                        : 'Not connected'}
                                </div>
                            )}
                        </div>
                    </h5>
                    {(!wallet_address && (
                        <Link
                            className="text-accent underline"
                            href="/verify/wallet"
                        >
                            Verify wallet
                        </Link>
                    )) ||
                        (!account && (
                            <button
                                className="text-accent underline"
                                onClick={() => connect()}
                            >
                                Connect
                            </button>
                        )) ||
                        (!cooldown && connected_wallet_is_selected && (
                            <Link
                                href="/change/wallet"
                                className="text-accent underline"
                            >
                                Change
                            </Link>
                        ))}
                </div>
            </AccountItem>
        </div>
    );
};

export default WalletInfo;
