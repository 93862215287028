import { NextRouter } from 'next/router';
import PasswordValidator from 'password-validator';

import { store } from '@src/bootstrap';
import { setUser } from '@src/features/auth';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { User } from '@src/ts/interfaces';

const password_schema = new PasswordValidator();
password_schema.is().min(8).has().uppercase(1).has().digits(2).has().symbols(1);

type AnalyticEvent =
    | 'create_account'
    | 'verify_email'
    | 'verify_identity'
    | 'verify_identity_onboarding'
    | 'verify_wallet'
    | 'tier_upgrade';

export const newAnalyticsEvent = (
    event: AnalyticEvent,
    other: Record<string, unknown> = {},
): void => {
    (window as AnalyticWindow).dataLayer =
        (window as AnalyticWindow).dataLayer || [];
    (window as AnalyticWindow).dataLayer.push({
        event,
        ...other,
    });
};

export const validateEmail = (email: string): boolean => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

export const validatePassword = (password: string): boolean => {
    return <boolean>password_schema.validate(password);
};

export const getPortfolioURL = (user: User): string => {
    if (user && user.wallet_address) {
        return `/portfolio/${user.wallet_address}`;
    } else if (user) {
        return '/account';
    }
    return '/login';
};

export const handleLogout = (router: NextRouter): void => {
    const { dispatch } = store;

    localStorage.setItem('decubate_access_token', '');
    localStorage.setItem('decubate_refresh_token', '');
    router.push('/login');
    dispatch(setUser(null));
};

export const getAccountProgress = (
    user: User,
    wallet_verified: boolean,
): number => {
    const has_identity_verified =
        user?.identity_verified === IdentityVerifiedStatus.VERIFIED;
    const has_whitelisted_wallet = !!user?.wallet_address && wallet_verified;
    const has_2fa = user?.enabled_2fa;

    let progress = 40;

    if (has_identity_verified) {
        progress += 20;
    }
    if (has_whitelisted_wallet) {
        progress += 20;
    }
    if (has_2fa) {
        progress += 20;
    }

    return progress;
};

export const uiLibTierColor = (multiplier: number): string => {
    if (multiplier < 10) {
        return 'blue';
    }
    if (multiplier < 25) {
        return 'green';
    }
    if (multiplier < 50) {
        return 'yellow';
    }
    if (multiplier < 100) {
        return 'orange';
    }
    return 'pink';
};

export const getRefundFee = (fee: number, multiplier: number) => {
    if (multiplier > 1) {
        const multi_reduction = (multiplier - 1) * 0.5;
        return fee > multi_reduction ? fee - multi_reduction : 0;
    }
    return fee;
};
