import React from 'react';
import Link from 'next/link';

import { NFT } from '@src/components';
import { INFT } from '@src/ts/interfaces';

export const NFTInventory: React.FC<{ nfts: INFT[] }> = ({ nfts }) => {
    return nfts.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 my-8">
            {nfts.map((d) => (
                <Link href={`/nft/${d.id}`} key={d.id}>
                    <NFT data={d} />
                </Link>
            ))}
        </div>
    ) : (
        <div className="my-32 text-center">
            <h2 className="text-2xl md:text-4xl mb-4 md:mb-8">
                <strong>Your inventory is empty</strong>
            </h2>
            <p className="text-secondary md:text-lg">
                Build your collection by participating in the platform events
            </p>
        </div>
    );
};
