import { toast } from 'react-toastify';
import { ResetPasswordStage } from '@decub8/ui';
import router from 'next/router';

import { api_client } from '@src/bootstrap';
import { IS_PROD } from '@src/config';
import { USER } from '@src/services';

import { ResetPasswordState } from '.';

export const attemptRequestPasswordChange = async (
    state: ResetPasswordState,
    setLoading: (b: boolean) => void,
    setState: (s: ResetPasswordState) => void,
    setError: (e: Record<string, unknown>) => void,
    getCaptcha: (action?: string) => Promise<string>,
) => {
    if (state.stage === ResetPasswordStage.Email) {
        setLoading(true);
    }

    let captcha;
    if (IS_PROD) {
        captcha = await getCaptcha();
    }

    try {
        await api_client.mutate({
            mutation: USER.REQUEST_PASSWORD_CHANGE,
            variables: { email: state.email, captcha },
        });
        if (state.stage === ResetPasswordStage.Password) {
            toast.success('New code was successfully sent');
        }
        setState({ ...state, stage: ResetPasswordStage.Password });
    } catch (err) {
        if (err.message === 'Email not verified') {
            toast.info(
                'Email not verified, please confirm email before resetting your password',
            );
            // set session to null incase they have one stored from registration attempt, so they can come back to reset their password
            // otherwise they will get logged in without resetting it
            localStorage.setItem('decubate_session', '');
            const encoded_email = encodeURIComponent(state.email);

            return router.push(
                `/verify/email?email=${encoded_email}&redirect=/login/reset?email=${encoded_email}`,
            );
        }

        toast.error(err.message);
        setError({ email: err.message });
    }
    setLoading(false);
};

export const attemptPasswordChange = async (
    state: ResetPasswordState,
    error: Record<string, unknown>,
    setLoading: (b: boolean) => void,
    setError: (e: Record<string, unknown>) => void,
) => {
    setLoading(true);
    try {
        await api_client.mutate({
            mutation: USER.CHANGE_PASSWORD,
            variables: {
                email: state.email,
                password: state.password,
                token: state.code,
            },
        });
        toast.success('Password has been changed successfully');
        router.push('/login');
    } catch (err) {
        setError({ ...error, code: err.message });
    }
    setLoading(false);
};
