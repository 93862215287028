import { useMemo } from 'react';
import crypto from 'crypto';

import { Content } from '../..';

export const Loader: React.FC = () => {
    const gradient_id = useMemo(
        () => `lin_grad_${crypto.randomBytes(8).toString('hex')}`,
        [],
    );
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-accent w-[50px] h-[50px] animate-spin mx-auto"
                viewBox="0 0 88 88"
                fill="none"
            >
                <circle
                    cx="44"
                    cy="44"
                    r="40"
                    stroke={`url(#${gradient_id})`}
                    strokeWidth="8"
                />
                <defs>
                    <linearGradient
                        id={gradient_id}
                        x1="15.1111"
                        y1="72.8889"
                        x2="78.4444"
                        y2="9.55557"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="currentColor" />
                        <stop
                            offset="1"
                            stopColor="currentColor"
                            stopOpacity="0"
                        />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export const PageLoader: React.FC<{ text: string }> = ({ text }) => (
    <Content>
        <div className="text-center my-48">
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-accent w-[100px] h-[100px] animate-spin mx-auto"
                    viewBox="0 0 88 88"
                    fill="none"
                >
                    <circle
                        cx="44"
                        cy="44"
                        r="40"
                        stroke="url(#paint0_linear_3494_15206)"
                        strokeWidth="8"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_3494_15206"
                            x1="15.1111"
                            y1="72.8889"
                            x2="78.4444"
                            y2="9.55557"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="currentColor" />
                            <stop
                                offset="1"
                                stopColor="currentColor"
                                stopOpacity="0"
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <h5 className="mt-8">{text}</h5>
        </div>
    </Content>
);
