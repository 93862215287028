import { useEffect, useState } from 'react';
import useSWR from 'swr';

/*
    useBuildID() Gets the build id from api/build_id, which is generated at build time
    and uses vercel's env variable VERCEL_GIT_COMMIT_SHA
*/

export const useBuildID = () => {
    const [buildID, setBuildID] = useState<string | null>(null);

    // This should run whenever a new build is deployed to vercel
    const fetcher = async (url: string) => {
        const res = await fetch(url);
        const data = await res.json();

        return data;
    };

    const { data } = useSWR('/api/build_id', fetcher);

    useEffect(() => {
        if (data?.buildId) {
            setBuildID(data.buildId);
        }
    }, [data]);

    return {
        buildID,
    };
};
