import React, { useEffect, useState } from 'react';

import { CurrencyInputProps } from '@src/ts/props';
import { ChangeEvent } from '@src/ts/types';

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
    currency,
    value,
    onChange,
    onMaxClick,
    loading,
    valid = true,
    error,
    onBlur,
    disabled,
}) => {
    const [focused, setFocused] = useState(false);
    const [buffer, setBuffer] = useState('0');

    const inputBorderColor = () => {
        if (!valid || error) {
            return 'border-accent';
        }
        if (focused) {
            return 'border-secondary';
        }
        return 'border-border border-opacity-5';
    };

    useEffect(() => {
        setBuffer(value);
    }, [value]);

    return (
        <div
            className={`w-full flex items-center bg-surface-level-one py-2 rounded border h-11 ${inputBorderColor()}`}
        >
            <input
                className={`appearance-none bg-transparent border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none text-white ${
                    disabled ? 'cursor-not-allowed' : ''
                }`}
                type="text"
                value={buffer}
                onChange={(e) => setBuffer(e.target.value)}
                lang="en"
                onFocus={() => setFocused(true)}
                onBlur={() => {
                    if (onBlur) onBlur();
                    setFocused(false);
                    onChange({ target: { value: buffer } } as ChangeEvent);
                }}
                data-testid="currency-input"
                disabled={disabled}
            />
            <div className="border-r-2 pr-3">
                <strong>{currency}</strong>
            </div>
            {loading ? (
                <div className="loader w-20">
                    <img src="/img/Spinner-Small.png" className="mx-auto" />
                </div>
            ) : (
                <button
                    onClick={onMaxClick}
                    className="flex-shrink-0 border-transparent border-4 text-sm py-1 px-2 rounded underline text-accent"
                    type="button"
                >
                    Max
                </button>
            )}
        </div>
    );
};
