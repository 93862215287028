import React, { useContext, useEffect, useState } from 'react';
import { FormData, KYCSteps } from '@decub8/ui';
import { useRouter } from 'next/router';

import { api_client, store } from '@src/bootstrap';
import OnboardingHeader from '@src/components/OnboardingHeader';
import { ProtectedRoute } from '@src/components/ProtectedRoute';
import { useAppSelector } from '@src/hooks';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { ChangeEvent } from '@src/ts/types';

const kyc_form_data: FormData[] = [
    {
        name: 'first_name',
        label: 'First name',
        type: 'text',
        placeholder: 'Enter your first name',
    },
    {
        name: 'last_name',
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter your last name',
    },
    {
        name: 'dob',
        label: 'Date of birth',
        type: 'date',
        placeholder: 'Enter your date of birth',
    },
];
export const UpdateUser: React.FC<{
    handleComplete: () => Promise<void>;
}> = ({ handleComplete }) => {
    // Get the authenticated user from the global state
    const { user } = useAppSelector((state) => state?.auth) || {};

    // State variables to manage form state and loading status
    const [tried_set, setTriedSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        dob: '',
    });

    // Access the global context for setting KYC completion status
    const { setShowKycCompleted } = useContext(GlobalContext);

    const router = useRouter();

    // Handle form field changes
    const handleChange = (e: ChangeEvent) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const { user } = store.getState().auth;
            const { first_name, last_name, dob } = state;

            if (
                first_name !== user?.first_name ||
                last_name !== user?.last_name ||
                dob !== user?.dob
            ) {
                // Update user data via API call if there are changes
                await api_client.mutate({
                    mutation: USER.UPDATE_USER,
                    variables: { id: user.id, first_name, last_name, dob },
                });
            }
            await handleComplete();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    // Handle skipping the KYC process
    const handleSkipNow = async () => {
        localStorage.setItem('kycSkipped', 'true');
        router.push('/account');
    };

    // Effect to initialize form state and handle verified users
    useEffect(() => {
        if (
            !tried_set &&
            user &&
            !state.first_name &&
            !state.last_name &&
            (!!user?.first_name || !!user?.first_name)
        ) {
            setTriedSet(true);
            setState({
                ...state,
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                dob: user?.dob || '',
            });
        }
        if (
            user &&
            user.identity_verified === IdentityVerifiedStatus.VERIFIED
        ) {
            setShowKycCompleted(true);
            router.push('/account');
        }
    }, [user, state.first_name, state.last_name]);

    return (
        <ProtectedRoute>
            <OnboardingHeader current_step={2} />
            <KYCSteps
                className="mb-[100px]"
                show_skip={true}
                handleSkipNow={handleSkipNow}
                handleClick={handleUpdate}
                loading={loading}
                disabled={
                    loading || !state['first_name'] || !state['last_name']
                }
                Kyc_form_data={kyc_form_data}
                state={state}
                handleChange={handleChange}
            />
        </ProtectedRoute>
    );
};
