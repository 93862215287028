import React from 'react';

export interface IButton
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    dark?: boolean;
    loading?: boolean;
}

export const Button: React.FC<IButton> = ({
    className,
    children,
    disabled = false,
    onClick,
    loading,
    dark,
    id = 'btn',
    ...rest
}) => {
    const classes = dark
        ? `text-sm py-2 px-6 rounded focus:outline-none bg-surface-level-two hover:bg-surface-level-two-hover text-secondary hover:text-primary border border-border border-opacity-5 ${className} ${
              !disabled ? '' : 'cursor-not-allowed'
          }`
        : `text-sm py-2 px-6 rounded focus:outline-none text-button bg-gradient-button border border-border border-opacity-5 ${className} ${
              !disabled ? '' : 'cursor-not-allowed opacity-60'
          }`;

    return (
        <button
            className={classes}
            disabled={disabled}
            onClick={(e) => {
                if (!disabled && onClick) {
                    onClick(e);
                }
            }}
            {...rest}
            data-testid={id}
        >
            {loading ? (
                <div className="loader">
                    <img
                        src="/img/Spinner-Small.png"
                        className="mx-auto"
                        style={{
                            width: '20px',
                            height: '20px',
                        }}
                        alt="Loading..."
                    />
                </div>
            ) : (
                children
            )}
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    children: null,
    className: '',
};
