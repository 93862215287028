import { useMemo } from 'react';
import { FooterProps } from '@decub8/ui';
import { useRouter } from 'next/router';

import { CONTENT } from '@src/config';
import { useAppSelector } from '@src/hooks';
import { LinkPosition } from '@src/ts/constants';
import { getPortfolioURL } from '@src/utils/user';

export const useFooter = (): FooterProps => {
    const router = useRouter();
    const { user } = useAppSelector((state) => state?.auth) || {};

    const links = useMemo(
        () => [
            {
                name: `${
                    CONTENT.hasCapsFooter
                        ? 'Projects'.toUpperCase()
                        : 'Projects'
                }`,
                handleClick: () => {
                    router.push('/#events');
                },
            },
            {
                name: `${
                    CONTENT.hasCapsFooter
                        ? 'Portfolio'.toUpperCase()
                        : 'Portfolio'
                }`,
                handleClick: () => router.push(getPortfolioURL(user)),
            },
            {
                name: `${
                    CONTENT.hasCapsFooter ? 'Staking'.toUpperCase() : 'Staking'
                }`,
                handleClick: () => router.push('/staking'),
            },
            ...CONTENT.links
                .filter(({ position }) =>
                    [LinkPosition.Both, LinkPosition.Footer].includes(position),
                )
                .map(({ name, link }) => ({
                    name: name,
                    handleClick: () => {
                        link.toLowerCase().startsWith('http')
                            ? window.open(link, '_blank')
                            : router.push(link);
                    },
                })),

            {
                name: `${
                    CONTENT.hasCapsFooter
                        ? 'Terms & Conditions'.toUpperCase()
                        : 'Terms & Conditions'
                }`,
                handleClick: () =>
                    window.open(CONTENT.termsAndConditions, '_blank'),
            },
        ],
        [router, user],
    );

    return {
        audited_by_img_urls: [
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Hacken-Logo-Dark.svg',
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Certik-Logo-Dark.svg',
        ],
        links,
        launchpad_show_powered_by_logo: CONTENT.launchpadShowPoweredByLogo,
        social_links: CONTENT.social,
        company_name: CONTENT.companyName,
        evm_chains_supported: CONTENT.EVMIcons,
        className: 'max-w-screen-lg mx-auto px-5',
        has_caps_labels: CONTENT.hasCapsFooter,
        powered_by_link: CONTENT.powered_by_link,
    };
};
