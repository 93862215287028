import { Button, Modal } from '@decub8/ui';

import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { useSwitchChain } from '@src/hooks/useSwitchChain';

export const ClaimRewardModal: React.FC<{
    show: boolean;
    setShow: (bool: boolean) => void;
    handleClaim: () => void;
    reward: string;
    loading?: boolean;
}> = ({ show, setShow, handleClaim, reward, loading }) => {
    const { isConnectedChainEventChain, setChainID, settingChain } =
        useSwitchChain();

    return (
        <Modal
            noClose
            title="Claim reward"
            desc="Are you sure you want to claim your reward now?
            You will no longer be able to increase and claim a bigger reward."
            show={show}
            onClose={() => setShow(false)}
        >
            <div className="flex flex-row-reverse">
                {!isConnectedChainEventChain(DEFAULT_CHAIN_ID) ? (
                    <Button
                        className="min-w-[140px]"
                        onClick={async () => {
                            if (settingChain) return;
                            await setChainID(DEFAULT_CHAIN_ID);
                        }}
                    >
                        {settingChain
                            ? 'Switching Networks'
                            : `Switch to ${NETWORKS[DEFAULT_CHAIN_ID].network_name}`}
                    </Button>
                ) : (
                    <Button
                        className="min-w-[140px]"
                        loading={loading}
                        onClick={handleClaim}
                    >{`Claim ${reward} ${BaseTokenSymbol}`}</Button>
                )}
                <Button
                    onClick={() => setShow(false)}
                    className="border-none text-secondary mr-5"
                    variant="custom"
                >
                    Back
                </Button>
            </div>
        </Modal>
    );
};
