import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { UpgradeTierStage } from '@decub8/ui';
import { log } from '@logtail/next';

import { createContractManager, IContractManager } from '@src/contracts';
import { ITier, IUserTier } from '@src/ts/interfaces';
import { getTierOfUser, getTiers } from '@src/utils/getters';

import { useAppSelector } from '..';

export interface IGlobalContext {
    /**
     * All tiers available - coming from the backend
     */
    _tiers: ITier[];
    _setTiers: React.Dispatch<React.SetStateAction<ITier[]>>;
    /**
     * The current tier of the logged in user
     */
    _userTier: IUserTier;
    /**
     * Set the current tier of the logged in user with the data from the backend
     */
    _setUserTier: React.Dispatch<React.SetStateAction<IUserTier>>;
    _hasFetchedUserTier: boolean;
    _setHasFetchedUserTier: React.Dispatch<React.SetStateAction<boolean>>;

    /**
     * Toggle the tier drawer
     * todo: specify maybe which drawer this is? there are multiple drawers, right?
     */
    _tierDrawerOpen: boolean;
    /**
     * Set the tier drawer open state
     */
    _setTierDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    _tierUpgradeStage: UpgradeTierStage;
    _setTierUpgradeStage: React.Dispatch<
        React.SetStateAction<UpgradeTierStage>
    >;
    _requiredDCB: number;
    _setRequiredDCB: React.Dispatch<React.SetStateAction<number>>;

    /**
     * Contract Manager - can be used to get any contract and will make sure
     * that the provider being used is always working and the lowest latency
     */
    contract_manager: IContractManager;

    /**
     allocation popup in the onboarding flow
     */
    showAllocationPopup: boolean;
    setShowAllocationPopup: React.Dispatch<React.SetStateAction<boolean>>;

    /**
     kyc modal popup in the onboarding flow
     */
    showKycCompleted: boolean;
    setShowKycCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalContext = createContext<IGlobalContext>(null);

export const useTiers = () => {
    const [tiers, setTiers] = React.useState<ITier[]>([]);
    const [userTier, setUserTier] = React.useState<IUserTier | null>(null);
    const [tierDrawerOpen, setTierDrawerOpen] = React.useState<boolean>(false);
    const [tier_upgrade_stage, setTierUpgradeStage] =
        React.useState<UpgradeTierStage>(UpgradeTierStage.Tier);
    const [requiredDCB, setRequiredDCB] = React.useState<number>(0);
    const [hasFetchedUserTier, setHasFetchedUserTier] =
        React.useState<boolean>(false);

    const [showAllocationPopup, setShowAllocationPopup] =
        React.useState<boolean>(false);
    const [showKycCompleted, setShowKycCompleted] =
        React.useState<boolean>(false);

    const { user } = useAppSelector((state) => state.auth);

    const _getTierOfUser = useCallback(async () => {
        const userTier = await getTierOfUser();

        setUserTier(userTier);
        setHasFetchedUserTier(true);
    }, []);

    const _getTiers = useCallback(async () => {
        const tiers = await getTiers();
        setTiers(tiers);
    }, []);

    useEffect(() => {
        if (user) {
            _getTierOfUser();
        } else {
            setUserTier(null);
        }
    }, [user, _getTierOfUser]);

    useEffect(() => {
        _getTiers();
    }, [_getTiers]);

    return {
        _tiers: tiers,
        _setTiers: setTiers,
        _userTier: userTier,
        _setUserTier: setUserTier,
        _tierDrawerOpen: tierDrawerOpen,
        _setTierDrawerOpen: setTierDrawerOpen,
        _tierUpgradeStage: tier_upgrade_stage,
        _setTierUpgradeStage: setTierUpgradeStage,
        _requiredDCB: requiredDCB,
        _setRequiredDCB: setRequiredDCB,
        _hasFetchedUserTier: hasFetchedUserTier,
        _setHasFetchedUserTier: setHasFetchedUserTier,
        showAllocationPopup,
        setShowAllocationPopup,
        showKycCompleted,
        setShowKycCompleted,
    };
};

export const GlobalContextProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const tiers = useTiers();
    const contract_manager = useMemo(createContractManager, []);

    // Initialize the contract manager
    useEffect(() => {
        contract_manager
            .init()
            .then(() => log.info('Contract manager initialized'))
            .catch((err) =>
                log.error('Error initializing contract manager', err),
            );
    }, []);

    const globalContext = {
        ...tiers,
        contract_manager,
    };

    return (
        <GlobalContext.Provider value={globalContext}>
            {children}
        </GlobalContext.Provider>
    );
};

export function useGlobalContext() {
    return React.useContext(GlobalContext);
}
