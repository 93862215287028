import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const useCountdown = (time: string): Duration => {
    const [time_left, setTimeLeft] = useState(
        dayjs.duration(dayjs(time).diff(dayjs())),
    );
    useEffect(() => {
        const handler = setInterval(
            () => setTimeLeft(dayjs.duration(dayjs(time).diff(dayjs()))),
            1000,
        );

        return () => {
            clearInterval(handler);
        };
    }, [time]);

    return time_left;
};
