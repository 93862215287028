import { useContext, useEffect, useState } from 'react';
import { InviteEarnProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';

import { CONTENT, DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { parseBalance } from '@src/utils/web3';

const getButtonText = (
    account: string,
    correct_wallet_address: boolean,
    is_below_gold: boolean,
    connected_wrong_chain: boolean,
) => {
    if (!account) return 'Connect wallet';
    if (connected_wrong_chain)
        return `Switch to ${NETWORKS[DEFAULT_CHAIN_ID].network_name}`;
    if (!correct_wallet_address) return 'Switch to verified wallet';
    if (is_below_gold) return 'Upgrade tier';
    return 'Claim reward';
};

export const useInviteEarn = (
    referral_id: string,
    user_referred: number,
    rewards_earned: string,
    claimable: BigNumber,
    handleRewardClaim: () => void,
    loading: boolean,
    min_referrer_tier: number,
): InviteEarnProps => {
    const { _userTier, _setTierDrawerOpen } = useContext(GlobalContext);
    const [base, setBase] = useState('');
    const { user } = useAppSelector((state) => state.auth);

    const { account, connect } = useWeb3Onboard();
    const { isConnectedChainEventChain, setChainID, settingChain } =
        useSwitchChain();

    const correct_wallet_address =
        user?.wallet_address.toLowerCase() === account?.toLowerCase();

    const connected_wrong_chain = !isConnectedChainEventChain(DEFAULT_CHAIN_ID);

    const setChain = async () => {
        if (settingChain) return;
        await setChainID(DEFAULT_CHAIN_ID);
    };

    useEffect(() => {
        const [protocol, url] = window.location.href.split('://');
        const [base_url] = url.split('/');
        setBase(`${protocol}://${base_url}`);
    }, []);

    const is_below_min_tier = _userTier?.id < min_referrer_tier;

    const referral_url = `${base}/register?referred_by=${referral_id}`;

    const handleClick = () => {
        if (!account || !correct_wallet_address) return connect();
        if (connected_wrong_chain) setChain();
        if (is_below_min_tier) return _setTierDrawerOpen(true);
        return handleRewardClaim();
    };

    return {
        loading: loading || settingChain,
        disabled:
            !account || !correct_wallet_address || is_below_min_tier
                ? false
                : !claimable?.gt(0),
        alert: is_below_min_tier,
        handleClick,
        button_text: getButtonText(
            account,
            correct_wallet_address,
            is_below_min_tier,
            connected_wrong_chain,
        ),
        referral_url,
        referral_info: [
            { label: 'Referrals complete', value: user_referred.toString() },
            {
                label: 'Rewards available',
                value: `${
                    parseBalance(claimable || 0) || 0
                } ${BaseTokenSymbol}`,
            },
            {
                label: 'Rewards claimed',
                value: `${rewards_earned || 0} ${BaseTokenSymbol}`,
            },
        ],
        share_options: CONTENT.referralConfig.share_options.map((option) => {
            const { name, url } = option;
            return {
                name,
                // url: `https://t.me/share/url?url=${referral_url}&text=${referral_text}
                url:
                    name === 'linkedin'
                        ? 'https://www.linkedin.com/'
                        : `${url}url=${referral_url}&text=${CONTENT.referralConfig.referral_text}`,
            };
        }),
        company_name: CONTENT.companyName,
    };
};
