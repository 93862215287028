import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TierCarouselProps, UpgradeTierStage } from '@decub8/ui';

import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { getRefundFee } from '@src/utils/user';
import { parseBalance } from '@src/utils/web3';

import { TierRewards } from '..';

import { addRewardToTiers, getButtonText, getTierContent } from './util';

export const useRewardCarousel = (
    tier_rewards: TierRewards,
    setShowClaimModal: (bool: boolean) => void,
    setClaimTier: (num: number) => void,
): TierCarouselProps => {
    const {
        _setRequiredDCB,
        _setTierUpgradeStage,
        _setTierDrawerOpen,
        _tiers,
        _userTier,
    } = useContext(GlobalContext);
    const [multi, setMulti] = useState(1);
    const [initial, setInitial] = useState(true);
    const { user } = useAppSelector((state) => state.auth);

    const { account, connect } = useWeb3Onboard();

    const user_tier = (_userTier?.id >= 0 && _tiers[_userTier.id]) || null;

    const is_max = _userTier?.id === _tiers.length - 1;

    const tiers_with_rewards = addRewardToTiers(tier_rewards, _tiers);

    const filtered_tiers = tiers_with_rewards.filter((t) =>
        is_max
            ? t.id === user_tier?.id
            : t.id >= (user_tier?.id || -1) && t.reward?.gt(0),
    );

    const num_filtered = tiers_with_rewards.length - filtered_tiers.length;

    const updateMulti = useCallback(
        (newValue: number) => {
            const minimum = is_max ? _userTier?.multiplier : 1;
            // Prevent the state from going below 1
            if (newValue < minimum) {
                setMulti(is_max ? _userTier?.multiplier : minimum);
            } else {
                setMulti(newValue);
            }
        },
        [is_max, _userTier.multiplier, setMulti],
    );

    useEffect(() => {
        if (!initial) return;

        if (_tiers && _userTier?.id >= 0) {
            setMulti(_userTier?.multiplier);
            setInitial(false);
        }
    }, [_userTier.multiplier]);

    return useMemo(() => {
        const cards = filtered_tiers.map(
            ({ min_limit, refund_fee: fee, reward }, idx) => {
                const tier_idx = idx + num_filtered;
                const is_max = tier_idx === _tiers?.length - 1;
                const multiplier = is_max ? multi : 1;
                const amount = is_max ? min_limit * multiplier : min_limit;
                const refund_fee = getRefundFee(fee, multiplier);

                const diff = amount - _userTier?.power;

                const reward_with_multi = is_max
                    ? reward?.mul(multiplier)
                    : reward;

                const is_user_tier = tier_idx === user_tier?.id;

                const is_connected_verified_wallet =
                    user.wallet_address === account;

                const handleClick = () => {
                    if (
                        is_user_tier &&
                        multi === _userTier?.multiplier &&
                        is_connected_verified_wallet
                    ) {
                        setShowClaimModal(true);
                        setClaimTier(tier_idx);
                        return;
                    }
                    if (!is_connected_verified_wallet || !account)
                        return connect();

                    _setTierDrawerOpen(true);
                    _setRequiredDCB(diff);
                    _setTierUpgradeStage(UpgradeTierStage.Pools);
                };

                return {
                    src: CONTENT.referralConfig.rewardModal.image_urls[
                        tier_idx
                    ],
                    reward: `${parseBalance(
                        reward_with_multi || 0,
                    )} ${BaseTokenSymbol}`,
                    content: getTierContent(
                        tier_idx,
                        `${(100 - refund_fee).toFixed(1)}%`,
                        is_max,
                        multi,
                    ),
                    button_text: getButtonText(
                        is_user_tier,
                        multi,
                        _userTier?.multiplier || 1,
                        is_connected_verified_wallet,
                        diff,
                        account,
                    ),
                    handleClick,
                    handleMinusClick: is_max
                        ? () => {
                              updateMulti(multi - 1);
                          }
                        : undefined,

                    handlePlusClick: is_max
                        ? () => updateMulti(multi + 1)
                        : undefined,
                };
            },
        );

        return {
            cards: cards.reverse(),
        };
    }, [
        filtered_tiers,
        multi,
        _userTier.multiplier,
        account,
        _userTier.power,
        _tiers,
        user_tier,
        num_filtered,
        is_max,
        updateMulti,
        setShowClaimModal,
        setClaimTier,
        connect,
    ]);
};
