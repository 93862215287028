import { useState } from 'react';

import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ConfirmWhitelistModalProps } from '@src/ts/props';
import { shortenHex } from '@src/utils/web3';

import { Button } from '../..';

export const ConfirmWhitelistModal: React.FC<ConfirmWhitelistModalProps> = ({
    onClose,
    onConfirm,
    loading,
}) => {
    const [inside_card, setInsideCard] = useState(false);
    const { account } = useWeb3Onboard();
    return (
        <div
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover text-white"
            id="modal-id"
            onClick={() => !inside_card && onClose()}
        >
            <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div
                className="w-full  max-w-lg p-8 relative mx-auto my-auto rounded-lg shadow-lg bg-surface-level-one border border-border border-opacity-5"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
            >
                <h3 className="text-3xl mb-8">
                    <strong>Verify selected wallet?</strong>
                </h3>

                <div className="w-full p-5 mb-8 flex items-center text-left bg-surface-level-two rounded-lg border border-secondary cursor-pointer">
                    <div>
                        <div className="rounded-full p-3 bg-gradient">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={3}
                                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex-1 px-4">
                        {account ? shortenHex(account, 6) : 'Connect wallet'}
                    </div>
                </div>
                <p className="mb-8">
                    Only one wallet can be verified per account.
                </p>
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <Button dark className="w-full" onClick={onClose}>
                            Cancel
                        </Button>
                    </div>
                    <div className="flex-1">
                        <Button
                            className="w-full"
                            onClick={onConfirm}
                            loading={loading}
                            disabled={loading}
                        >
                            Verify wallet
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
