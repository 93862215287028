import React, { useContext } from 'react';
import { Button, Modal, Typography } from '@decub8/ui';

import { GlobalContext } from '@src/hooks/useGlobalContext';

const KycStepsCompleted: React.FC = () => {
    const { showKycCompleted, setShowKycCompleted } = useContext(GlobalContext);

    const onClose = () => {
        setShowKycCompleted(false);
        // ! Allocation pop is not correct. using hardcode images and values
        // ! also, not all white lable clients have a free allocation, which is communicated in the modal
        // setShowAllocationPopup(true);
    };

    return (
        <Modal
            noClose
            show={showKycCompleted}
            onClose={() => setShowKycCompleted(false)}
        >
            <Typography className="mb-8" allBold size="4xl">
                Data submitted
            </Typography>
            <Typography className="mb-8" variant="secondary">
                Thank you for submitting your verification data. <br /> You’ll
                receive an email with the result, or you can check it in ‘My
                Profile’.
            </Typography>
            <Typography className="mb-8" variant="secondary">
                Once your ID is verified, you can participate in an event.
            </Typography>

            <Button className="w-full" onClick={onClose}>
                Got it
            </Button>
        </Modal>
    );
};

export default KycStepsCompleted;
