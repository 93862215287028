export interface IBadge extends React.BaseHTMLAttributes<HTMLDivElement> {
    color?: string;
}

export const Badge: React.FC<IBadge> = ({
    className = '',
    children,
    color = 'surface-level-two',
    id = 'badge',
}) => {
    return (
        <span
            className={`bg-${color} text-light-800 text-sm font-medium px-4 py-2 rounded border border-border border-opacity-5 ${className}`}
            data-testid={id}
            id={id}
        >
            {children}
        </span>
    );
};
