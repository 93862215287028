import React, { Fragment } from 'react';
import { Typography } from '@decub8/ui';

export const TextSection: React.FC<{
    title: string;
    text: string[];
    className?: string;
}> = ({ title, text, className = '' }) => {
    return (
        <div className={`md:flex items-start space-x-4 my-12 ${className}`}>
            <div className="mb-4 md:mb-0 flex-1">
                <Typography allBold as="h2" size="3xl">
                    {title}
                </Typography>
            </div>
            <div className="flex-1">
                {text.map((t, idx) => (
                    <Fragment key={t + idx}>
                        <Typography variant="secondary" className="mb-4">
                            {t}
                        </Typography>
                    </Fragment>
                ))}
            </div>
        </div>
    );
};
