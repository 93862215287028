import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';

import { CONTENT } from '@src/config';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { ITier, User } from '@src/ts/interfaces';

export const getFilteredButtonOptions = (
    identity_verified: boolean,
    wallet_verified: boolean,
    has_min_tier: boolean,
    user: User,
    min_referrer_tier: number,
    is_tier_rewards_uniform: boolean,
    last_key_with_zero_reward: number,
    current_tier: ITier,
    is_bonus: boolean,
) => {
    const { _setTierDrawerOpen } = useContext(GlobalContext);
    const router = useRouter();

    const identity_pending =
        user.identity_verified === IdentityVerifiedStatus.PENDING;
    const is_wallet_verified = !!user.wallet_address && wallet_verified;

    const is_wallet_pending = user.wallet_address && !wallet_verified;

    const can_claim_reward = current_tier.id > last_key_with_zero_reward;

    return useMemo(
        () =>
            [
                {
                    label: identity_pending
                        ? 'Identification verification - Pending'
                        : 'Verify identity',
                    handleClick: () =>
                        router.push('/verify/identity?referred=true'),
                    loader: identity_pending,
                    disabled: identity_pending,
                    complete: identity_verified,
                },
                {
                    label: is_wallet_pending
                        ? 'Wallet verification - Pending'
                        : 'Verify wallet',
                    handleClick: () =>
                        router.push(`/verify/wallet?referred=true`),
                    loader: is_wallet_pending,
                    disabled: is_wallet_pending,
                    complete: is_wallet_verified,
                },
                {
                    label: is_tier_rewards_uniform
                        ? is_bonus
                            ? `Get ${CONTENT.tiers.tierConstants[
                                  last_key_with_zero_reward + 1
                              ].name.toUpperCase()} tier or above`
                            : `Get ${CONTENT.tiers.tierConstants[
                                  min_referrer_tier
                              ].name.toUpperCase()} tier or above`
                        : `Get ${CONTENT.tiers.tierConstants[
                              min_referrer_tier
                          ].name.toUpperCase()} tier or above`,
                    handleClick: () => {
                        _setTierDrawerOpen(true);
                    },
                    // has_min_tier always true for bonus tab
                    complete: is_tier_rewards_uniform
                        ? (current_tier?.id !== undefined
                              ? current_tier?.id
                              : -1) >= 0
                            ? is_bonus
                                ? can_claim_reward
                                : has_min_tier
                            : false
                        : has_min_tier,
                },
            ].filter((e) => !e.complete),
        [
            identity_pending,
            identity_verified,
            router,
            is_wallet_pending,
            is_wallet_verified,
            min_referrer_tier,
            has_min_tier,
            current_tier,
            is_tier_rewards_uniform,
        ],
    );
};
