import React, { useContext, useState } from 'react';
import { Button, Card } from '@decub8/ui';

import { Typography } from '@src/components/Typography';
import { DEFAULT_CHAIN_ID } from '@src/config';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { EventType } from '@src/ts/constants';
import { Project, ProjectEvent } from '@src/ts/interfaces';

import { L2ESocials } from '../Socials';

import { Question } from './Question';
import { reduceSocials } from './util';

export const Challenge: React.FC<{
    project: Project;
    event: ProjectEvent;
    registerForWhitelist: (event_id: number, answers: string[]) => void;
    whitelist_loading: boolean;
}> = ({ project, event, registerForWhitelist, whitelist_loading }) => {
    const { _setTierDrawerOpen } = useContext(GlobalContext);

    const { challenge = [] } = event;

    const [error, setError] = useState(false);

    const { social_platforms } = project;

    const [{ socials, resources }] = useState(reduceSocials(social_platforms));

    const [state, setState] = useState<{ [key: number]: string }>(
        challenge.reduce((acc, _, idx) => ({ ...acc, [idx]: '' }), {}),
    );

    const { isConnectedChainEventChain, setChainID } = useSwitchChain();

    const all_questions_answered = () => {
        return Object.values(state).every((answer) => answer.trim() !== '');
    };

    const handleSubmit = () => {
        setError(false);
        const single_ans = Object.entries(state)
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .map((a) => a[1]) as string[];

        return registerForWhitelist(event.id, single_ans);
    };

    return (
        <div>
            <L2ESocials
                social_platforms={resources}
                title={`${project.name} resources`}
            />
            {challenge.map((q, idx) => (
                <Question
                    num={idx + 1}
                    key={JSON.stringify(q)}
                    q={q}
                    selected={state[idx]}
                    setSelected={(a) =>
                        setState({
                            ...state,
                            [idx]: a,
                        })
                    }
                />
            ))}
            {event.type === EventType.TokenClaim && (
                <L2ESocials
                    social_platforms={socials}
                    title={`Join ${project.name} social communities`}
                />
            )}
            {event.type === EventType.TokenClaim && (
                <Card className="p-4 flex items-center justify-between">
                    <Typography className="text-xl font-bold">
                        Want to increase your allocation?
                    </Typography>

                    <Button
                        variant="secondary"
                        className="w-40"
                        onClick={
                            isConnectedChainEventChain(DEFAULT_CHAIN_ID)
                                ? () => _setTierDrawerOpen(true)
                                : () => setChainID(DEFAULT_CHAIN_ID)
                        }
                    >
                        Upgrade
                    </Button>
                </Card>
            )}

            <div className="sm:flex my-4 justify-end">
                {error && (
                    <div className="bg-clip-padding border border-border border-opacity-[0.03] error-gradient mb-4 md:mb-0 px-4 py-2 flex-1 sm:mr-4 rounded flex items-center h-full space-x-4">
                        <img src="/icons/error.svg" alt="error.." />
                        <Typography type="h5">
                            Some answers were incorrect. Try again.
                        </Typography>
                    </div>
                )}

                <Button
                    loading={whitelist_loading}
                    disabled={!all_questions_answered()}
                    onClick={handleSubmit}
                    className="h-10 w-28 float-right mb-12 sm:my-0"
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};
