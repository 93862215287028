import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { getTokenPrice } from '@src/components/Staking/Analytics/util';

export const useTokenPriceWei = (
    symbol: string,
    base_token_decimals: number,
): BigNumber => {
    const [price, setPrice] = useState(BigNumber.from(0));

    useEffect(() => {
        getTokenPrice(symbol).then((price) =>
            setPrice(parseUnits(price.toString(), base_token_decimals)),
        );
    }, []);

    return price;
};
