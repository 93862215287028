import React, { useRef } from 'react';
import useSmoothScroll from 'react-smooth-scroll-hook';

export const RadioButtons: React.FC<{
    options: { id: string | number; label: string; disabled?: boolean }[];
    curr: string | number;
    // eslint-disable-next-line
    setCurr: any;
    mobile_fixed?: boolean;
}> = ({ options = [], curr, setCurr, mobile_fixed = false }) => {
    const ref = useRef(null);
    const { scrollTo } = useSmoothScroll({
        ref,
        speed: 100,
        direction: 'x',
    });
    const handleClick = (id) => {
        const rect = document
            .querySelector(`#option-${id}`)
            .getBoundingClientRect();
        scrollTo(`#option-${id}`, -rect.width / 2);
        setCurr(id);
    };
    return (
        <div
            className="flex w-full text-center mb-8 cursor-pointer justify-start overflow-x-auto border-b-2 border-gray-300 border-opacity-10"
            id="radio-button-container"
            ref={ref}
        >
            {options.map(({ id, label, disabled }) => (
                <button
                    data-testid="radio-btn"
                    key={id}
                    id={`option-${id}`}
                    onClick={() => !disabled && handleClick(id)}
                    className={`text-center py-3 px-3 sm:px-6 text-sm ${
                        curr === id
                            ? 'border-b-2 border-accent'
                            : 'text-secondary'
                    } ${mobile_fixed ? 'w-full md:w-auto' : ''}`}
                >
                    <div
                        className={`sm:w-auto sm:min-w-max ${
                            mobile_fixed ? 'md:w-36' : 'w-36'
                        }`}
                    >
                        {(curr === id && (
                            <strong className="text-md">{label}</strong>
                        )) || (
                            <div className="flex items-center justify-center">
                                {label}
                                {disabled && (
                                    <div className="ml-2 rounded-full border border-accent px-2 text-accent">
                                        Soon
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </button>
            ))}
        </div>
    );
};
