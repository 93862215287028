import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { is_browser } from '@src/constants';
import { User } from '@src/ts/interfaces';
interface AuthState {
    user: User | null;
    token: string;
    not_supported_but_collected_email: boolean;
    country: string;
    wallet_verified: boolean;
    loading: boolean;
    initial_user_loaded: boolean;
}
const initialState = {
    user: null,
    token: is_browser ? localStorage.getItem('decubate_access_token') : '',
    not_supported_but_collected_email: false,
    country: '',
    wallet_verified: false,
    loading: true,
    initial_user_loaded: false,
} as AuthState;
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload;
        },
        setInitialUserLoaded(state, action: PayloadAction<boolean>) {
            state.initial_user_loaded = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setNotSupportedButCollectedEmail(
            state,
            action: PayloadAction<{ value: boolean; country: string }>,
        ) {
            state.not_supported_but_collected_email = action.payload.value;
            state.country = action.payload.country;
        },
        setWalletVerified(state, action: PayloadAction<boolean>) {
            state.wallet_verified = action.payload;
        },
    },
});
export const {
    setUser,
    setNotSupportedButCollectedEmail,
    setWalletVerified,
    setLoading,
    setInitialUserLoaded,
} = authSlice.actions;
export default authSlice.reducer;
