import React from 'react';

import { EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { CrowdfundingComplete } from './CrowdfundingComplete';
import { TokenClaimComplete } from './TokenClaimComplete';

export const EventFooter: React.FC<{
    event: ProjectEvent;
}> = ({ event }) => {
    switch (event.type) {
        case EventType.TokenClaim:
            return <TokenClaimComplete />;
        default:
            return <CrowdfundingComplete />;
    }
};
