import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    DataLines,
    HelperBox,
    Modal,
    RefundModal,
    Typography,
} from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';

import { Card } from '@src/components';
import { useTimedText } from '@src/components/Project/Event/TimeText';
import { NETWORKS } from '@src/config';
import { useAppSelector, useDecubateContract } from '@src/hooks';
import { useDecubateContractByAddress } from '@src/hooks/useContract';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useInvestmentContext } from '@src/hooks/usePortfolioContext';
import { useRefundModal } from '@src/hooks/useRefundModal';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';
import { Investment } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

/* eslint-disable-next-line */
declare let window: any;

const VestingChart = dynamic(() => import('../../Chart'), { ssr: false });

const data_title = ['Start date', 'End date', 'Refund deadline'];

const RefundModals: React.FC<{ event_name: string }> = ({ event_name }) => {
    const [refund_modal_props, refund_complete_modal_props] =
        useRefundModal(event_name);

    return (
        <>
            <RefundModal {...refund_modal_props} />
            <Modal {...refund_complete_modal_props} />
        </>
    );
};

export const Dropdown: React.FC<Investment> = ({
    token_symbol,
    available,
    released,
    cliff,
    end_date,
    start_date,
    total,
    initial_unlock,
    contract_address,
    decimals,
    refund_deadline,
    vesting_address,
    contract_type,
    invest_amount,
    name,
    is_airdrop,
    payment_symbol,
    refunded,
    chainId,
    token_address,
}) => {
    const { user } = useAppSelector((state) => state?.auth) || {};

    const { contract_manager } = useGlobalContext();
    const [show_helper_box, setShowHelperBox] = useState(false);
    const [claim_loading, setClaimLoading] = useState(false);
    const { account, connect, connecting } = useWeb3Onboard();

    const claimed = BigNumber.from(released || 0);
    const { isConnectedChainEventChain, setChainID, settingChain } =
        useSwitchChain();

    const { updateInvestmentVesting, setRefundDetails, setRefundModalOpen } =
        useInvestmentContext();

    const formatted_claimed = `${parseBalance(
        claimed,
        decimals,
    )} ${token_symbol}`;
    const formatted_locked = `${parseBalance(
        BigNumber.from(total || 0).sub(claimed.add(available || 0)),
        decimals,
    )} ${token_symbol}`;
    const formatted_available = `${parseBalance(
        available || 0,
        decimals,
    )} ${token_symbol}`;
    const can_claim = BigNumber.from(available || 0).gt(0);

    const [investment_contract] = useDecubateContract(
        contract_type,
        true,
        chainId,
    );
    const vesting_contract = useDecubateContractByAddress(
        vesting_address,
        ContractType.PlatformVesting,
        true,
    );

    const is_legacy = contract_type === ContractType.Investments;

    const deadline = dayjs(start_date).add(refund_deadline, 'seconds');

    const cant_refund =
        !refund_deadline ||
        dayjs().isBefore(start_date) ||
        claimed.gt(0) ||
        dayjs().isAfter(deadline);

    const refund_available = !(cant_refund || !account || refunded);
    const end_time = dayjs(end_date).format('MMM DD, YY');

    const start_time = useTimedText(dayjs(start_date), false, true, true);
    const deadline_time = useTimedText(deadline, false, true, true);

    const is_connected_verified_wallet =
        user?.wallet_address?.toLowerCase() === account?.toLowerCase();

    const handleClaim = async () => {
        if (!account) {
            connect();
            return;
        }

        setClaimLoading(true);
        toast.info('Awaiting successful transaction');

        try {
            const estimated_gas =
                await investment_contract.estimateGas.claimDistribution(
                    ...(is_legacy
                        ? [account, contract_address]
                        : [contract_address]),
                );

            // 20% increase in gas
            const gas_limit = estimated_gas.mul(6).div(5);

            const tx = await investment_contract.claimDistribution(
                ...(is_legacy
                    ? [account, contract_address]
                    : [contract_address]),
                {
                    gasLimit: gas_limit,
                },
            );

            await tx.wait();
            toast.success(`Successfully claimed tokens`);

            updateInvestmentVesting(
                contract_address,
                account,
                contract_type,
                chainId,
            );
        } catch (err) {
            toast.error(err.reason || err.message);
        }
        setClaimLoading(false);
    };

    const handleRefund = async () => {
        toast.info('Awaiting successful transaction');
        try {
            const tx = await vesting_contract.refund();
            await tx.wait();
            toast.success(`Successfully refunded investment`);

            updateInvestmentVesting(
                contract_address,
                account,
                contract_type,
                chainId,
            );

            return true;
        } catch (err) {
            toast.error(err.reason || err.message);
            return false;
        }
    };

    const handleRefundClick = () => {
        setRefundDetails({
            project_name: name,
            handleRefund,
            refund_deadline: dayjs(start_date)
                .add(refund_deadline, 'seconds')
                .toISOString(),
            total_invested: invest_amount,
            payment_symbol: payment_symbol,
            refund_chain_id: chainId,
        }),
            setRefundModalOpen(true);
    };

    useEffect(() => {
        if (dayjs().isBefore(dayjs(start_date))) {
            const handler = setInterval(() => {
                updateInvestmentVesting(
                    contract_address,
                    account,
                    contract_type,
                    chainId,
                );
            }, 3000);

            return () => clearInterval(handler);
        }
    }, [
        start_date,
        contract_address,
        account,
        is_legacy,
        chainId,
        contract_manager,
    ]);

    const importTokens = async () => {
        if (!window.ethereum) {
            toast.error('Please install MetaMask to import tokens.');
            return;
        }

        try {
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: token_address,
                        symbol: token_symbol,
                        decimals: decimals,
                    },
                },
            });
            toast.success('Token imported successfully!');
        } catch (error) {
            toast.error('Failed to import token');
        }
    };

    const handleMouseEnter = () => {
        setShowHelperBox(true);
    };

    const handleMouseLeave = () => {
        setShowHelperBox(false);
    };

    return (
        <div className="border-t-2 border-border border-opacity-10 mt-4 md:flex py-6 md:space-x-4">
            <div className="md:w-5/12">
                <div className="md:flex items-center md:space-x-4 w-full my-4">
                    {[
                        start_time,
                        end_time,
                        refund_deadline > 0 ? deadline_time : 'None',
                    ]
                        .filter((_, idx) => idx < 2 || !is_airdrop)
                        .map((t, idx) => (
                            <Card
                                className="p-4 bg-surface-level-two flex-1 my-4 md:my-0"
                                key={t.toString() + idx}
                            >
                                <DataLines
                                    top_size="xl"
                                    variant="verticle"
                                    label={data_title[idx].toUpperCase()}
                                    values={[
                                        {
                                            value: t,
                                        },
                                    ]}
                                />
                            </Card>
                        ))}
                </div>

                <Card className="p-5 bg-surface-level-two">
                    <div className="space-y-2 mb-8">
                        {[
                            { value: formatted_locked, label: 'Locked' },
                            { value: formatted_claimed, label: 'Claimed' },
                            {
                                value: formatted_available,
                                label: 'Available to claim',
                            },
                        ].map(({ value, label }) => (
                            <DataLines
                                key={label + value}
                                label={label}
                                values={[{ value }]}
                                size="md"
                            />
                        ))}
                    </div>
                    {!isConnectedChainEventChain(chainId) ? (
                        <Button
                            className="w-full"
                            onClick={async () => {
                                if (settingChain) return;
                                await setChainID(chainId);
                            }}
                            disabled={settingChain}
                        >
                            {settingChain
                                ? 'Switching Networks'
                                : `Switch to ${NETWORKS[chainId].network_name}`}
                        </Button>
                    ) : (
                        <>
                            <Button
                                className="w-full"
                                onClick={
                                    is_connected_verified_wallet
                                        ? () => handleClaim()
                                        : () => connect()
                                }
                                loading={
                                    is_connected_verified_wallet
                                        ? claim_loading
                                        : connecting
                                }
                                disabled={
                                    is_connected_verified_wallet
                                        ? account &&
                                          (!can_claim ||
                                              claim_loading ||
                                              refunded)
                                        : false
                                }
                            >
                                {account
                                    ? is_connected_verified_wallet
                                        ? 'Claim tokens'
                                        : 'Connect verified wallet'
                                    : 'Connect wallet'}
                            </Button>
                            {token_address && (
                                <div className="relative">
                                    {show_helper_box && (
                                        <div className="absolute bottom-[43px] left-1/2 transform -translate-x-1/2">
                                            <HelperBox
                                                className="w-[159px]"
                                                helper_text={
                                                    'Automatically add official token to your wallet to view and manage the balance.'
                                                }
                                            />
                                        </div>
                                    )}

                                    <Button
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        className="w-full mt-4 bg-surface-level-three hover:bg-surface-level-four text-secondary"
                                        onClick={importTokens}
                                        variant="custom"
                                    >
                                        {' '}
                                        {`Add ${token_symbol} to your wallet`}
                                    </Button>
                                </div>
                            )}

                            {!is_airdrop && (
                                <div>
                                    <Typography className="text-center my-4">
                                        OR
                                    </Typography>
                                    <Button
                                        className="w-full bg-surface-level-three hover:bg-surface-level-four text-secondary"
                                        onClick={handleRefundClick}
                                        variant="custom"
                                        disabled={!refund_available}
                                    >
                                        Refund
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </Card>
            </div>
            <div className="md:w-7/12 pt-4 md:mt-0 md:h-auto sm:h-[500px] h-[250px]">
                <VestingChart
                    amount_token={total}
                    cliff={cliff}
                    end_date={end_date}
                    initial_unlock={initial_unlock}
                    start_date={start_date}
                    symbol={token_symbol}
                    decimals={decimals}
                    is_legacy={is_legacy}
                />
            </div>
            {refund_available && <RefundModals event_name={name} />}
        </div>
    );
};
