import React from 'react';
import { Button, Card, TierPower, Toggle, Typography } from '@decub8/ui';

export interface RewhitelistEventProps
    extends React.HTMLAttributes<HTMLElement> {
    tier_img: string;
    toggle_terms: boolean;
    setToggleTerms: (toggle_terms: boolean) => void;
    terms_url: string;
    saft_url?: string;
    handleClick?: () => void;
    button_disabled?: boolean;
    button_loading?: boolean;
    current_value: string;
    token_symbol: string;
    whitelisted?: boolean;
}

export const WhitelistCard: React.FC<RewhitelistEventProps> = ({
    tier_img,
    toggle_terms,
    setToggleTerms,
    terms_url,
    handleClick,
    current_value,
    saft_url,
    button_disabled,
    button_loading,
    token_symbol,
    whitelisted,
}) => {
    return (
        <div>
            {!whitelisted ? (
                <Card
                    surface="two"
                    className={`p-5 ${whitelisted ? '' : 'corner-gradient'}`}
                >
                    <div className="corner-gradient-content">
                        <TierPower
                            className="z-10"
                            image_url={tier_img}
                            label={`Current ${token_symbol} power`}
                            value={current_value}
                            variant={'lg'}
                        />

                        <TermsSection
                            button_disabled={button_disabled}
                            button_loading={button_loading}
                            className="mt-4"
                            toggle_terms={toggle_terms}
                            setToggleTerms={setToggleTerms}
                            terms_url={terms_url}
                            handleClick={handleClick}
                            saft_url={saft_url}
                        />
                    </div>
                </Card>
            ) : (
                <div className="space-y-2">
                    <Card surface="two" className={`p-5`}>
                        <div className="corner-gradient-content">
                            <TierPower
                                className="z-10"
                                image_url={tier_img}
                                label={`Whitelisted ${token_symbol} power`}
                                value={current_value}
                                variant={'md'}
                            />
                        </div>
                    </Card>
                </div>
            )}
        </div>
    );
};

const TermsSection: React.FC<{
    toggle_terms: boolean;
    setToggleTerms: (toggle_terms: boolean) => void;
    terms_url: string;
    saft_url?: string;
    className?: string;
    handleClick?: () => void;
    button_disabled?: boolean;
    button_loading?: boolean;
}> = ({
    toggle_terms,
    setToggleTerms,
    terms_url,
    className,
    handleClick,
    saft_url,
    button_disabled,
    button_loading,
}) => {
    return (
        <div className={className}>
            <div className="flex space-x-5">
                <Toggle
                    className="mt-[1px]"
                    id="accept-terms"
                    active={toggle_terms}
                    onChange={() => setToggleTerms(!toggle_terms)}
                    variant="radio"
                />

                <Typography size="sm" variant="secondary">
                    I agree to{' '}
                    <a
                        className="hover:underline text-accent text-base"
                        href={terms_url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        Terms and conditions
                    </a>{' '}
                    and{' '}
                    <a
                        className="hover:underline text-accent text-base"
                        href={saft_url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        SAFT
                    </a>{' '}
                    and fully understand the risks.
                </Typography>
            </div>
            <Button
                disabled={button_disabled}
                loading={button_loading}
                onClick={handleClick}
                className="mt-4 w-full"
            >
                Whitelist for this event
            </Button>
        </div>
    );
};
