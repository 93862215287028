import { init, useConnectWallet, useSetChain } from '@web3-onboard/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Web3Provider as EthersWeb3Provider } from '@ethersproject/providers';
import { ConnectedChain, WalletState } from '@web3-onboard/core';
import gnosisModule from '@web3-onboard/gnosis';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule from '@web3-onboard/ledger';
import trustWalletModule from '@web3-onboard/trust';
import walletConnectModule from '@web3-onboard/walletconnect';

import {
    ALL_CHAIN_IDS,
    CONTENT,
    DEFAULT_CHAIN_ID,
    getRPCUrl,
    NETWORKS,
} from '@src/config';

const WC_PID = '5d572f0fd85939f6601fae5f50b3eb6c';

const LEDGER_SUPPOPRTED_NETWORKS = [1, 56, 137, 42161, 43114];

const injected = injectedModule();
const ledger = ledgerModule({
    projectId: WC_PID,
    walletConnectVersion: 2,
    requiredChains: ALL_CHAIN_IDS.filter((id) =>
        LEDGER_SUPPOPRTED_NETWORKS.includes(id),
    ),
});
const wallet_connect = walletConnectModule({
    dappUrl: CONTENT.dappUrl,
    projectId: WC_PID,
    version: 2,
    requiredChains: [DEFAULT_CHAIN_ID],
    optionalChains: ALL_CHAIN_IDS,
});
const gnosis = gnosisModule();
const trust = trustWalletModule();

export const web3onboard = init({
    wallets: [injected, trust, wallet_connect, gnosis, ledger],
    // map all CHAIN_IDS and use NETWORKS to get the rpcUrl
    chains: ALL_CHAIN_IDS.map((id) => ({
        id: '0x' + id.toString(16),
        token: NETWORKS[id].symbol,
        label: NETWORKS[id].network_name,
        rpcUrl: getRPCUrl(id),
    })),
    appMetadata: {
        name: `${CONTENT.companyName}`,
        logo: `${CONTENT.web3onboard.logo}`,
        description: `${CONTENT.web3onboard.description}`,
        recommendedInjectedWallets: [
            { name: 'MetaMask', url: 'https://metamask.io' },
        ],
        explore: CONTENT.dappUrl,
    },
    accountCenter: {
        desktop: { enabled: false, containerElement: 'body' },
        mobile: { enabled: false, containerElement: 'body' },
    },
});

interface IWeb3Context {
    account?: string;
    wallet: WalletState;
    connecting: boolean;
    settingChain: boolean;
    provider?: EthersWeb3Provider;
    connectedChain: ConnectedChain;
    connect: () => Promise<void>;
    disconnect: () => Promise<void>;
    setChainID: (id: number) => Promise<boolean>;
}

const Web3Context = createContext<IWeb3Context>(null);

export const Web3onboardProvider: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ connectedChain, settingChain }, setChain] = useSetChain();
    const [prev_account, setPrevAccount] = useState('');
    const account = wallet && wallet.accounts[0].address; // replace second with any address if needed for testing

    useEffect(() => {
        const previouslyConnectedWallets = JSON.parse(
            window.localStorage.getItem('connectedWallets'),
        );

        if (previouslyConnectedWallets?.length > 0) {
            // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load
            connect({
                autoSelect: {
                    label: previouslyConnectedWallets[0],
                    disableModals: true,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (
            account &&
            account !== prev_account &&
            !ALL_CHAIN_IDS.includes(parseInt(connectedChain?.id, 16))
        ) {
            setChain({ chainId: '0x' + DEFAULT_CHAIN_ID.toString(16) });
        }
        setPrevAccount(account);
    }, [account, connectedChain?.id]);

    const setChainID = async (id?: number) => {
        if (!id) id = DEFAULT_CHAIN_ID;
        const idInt = parseInt(id.toString());
        const idHex = '0x' + idInt.toString(16);
        return setChain({ chainId: idHex });
    };

    return (
        <Web3Context.Provider
            value={{
                wallet,
                account,
                connecting,
                connect: async () => {
                    await connect();
                },
                disconnect: async () => {
                    await disconnect(wallet);
                },
                connectedChain,
                settingChain,
                provider: wallet?.provider
                    ? new EthersWeb3Provider(wallet.provider)
                    : undefined,
                setChainID,
            }}
        >
            {children}
        </Web3Context.Provider>
    );
};

function useWeb3Onboard(): IWeb3Context {
    return useContext(Web3Context);
}

export { useWeb3Onboard };

export default web3onboard;
