import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { TierLevel, Tooltip } from '@decub8/ui';
import { TierColors } from '@decub8/ui/dist/molecules/types';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap';
import { Button } from '@src/components';
import { CONTENT } from '@src/config';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { User } from '@src/ts/interfaces';
import { uiLibTierColor } from '@src/utils/user';

import AccountItem from './AccountItem';

const onlyFirstLetterCapital = (s: string) => {
    const start = s.slice(0, 1).toUpperCase();
    const end = s.slice(1, s.length).toLowerCase();
    return start + end;
};

const UserInfo: React.FC<{ user: User }> = ({ user }) => {
    const { _tiers, _userTier, _setTierDrawerOpen } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const is_max = _userTier?.id === _tiers?.length - 1;
    const router = useRouter();

    const handleRequestChangePassword = async () => {
        setLoading(true);
        try {
            await api_client.mutate({
                mutation: USER.REQUEST_PASSWORD_CHANGE,
                variables: { email: user?.email },
            });
            toast.success(
                'An email has been sent to you with details on how to reset your password.',
            );

            router.push('/login/reset?email=' + user?.email);
        } catch (err) {
            toast.error(err.message);
        }
        setLoading(false);
    };

    return (
        <div>
            <AccountItem title="Account tier">
                <div className="flex items-center">
                    <div className="flex-1">
                        <TierLevel
                            image_url={
                                _userTier?.image_url ||
                                CONTENT.tiers.NoTierImage
                            }
                            is_max_tier={is_max}
                            text={_userTier?.name || 'No tier'}
                            tier_color={
                                uiLibTierColor(
                                    _userTier?.multiplier || 1,
                                ) as TierColors
                            }
                            multiplier={_userTier?.multiplier || 1}
                        />
                    </div>

                    <Button
                        className="float-right"
                        dark
                        onClick={() => {
                            _setTierDrawerOpen(true);
                        }}
                    >
                        Upgrade
                    </Button>
                </div>
            </AccountItem>
            <AccountItem
                title="Identity verification status"
                gradient={
                    user?.identity_verified !== IdentityVerifiedStatus.VERIFIED
                }
            >
                <div className="flex items-center">
                    <h5 className="text-lg flex-1">
                        {(user &&
                            onlyFirstLetterCapital(user.identity_verified)) ||
                            ''}
                    </h5>

                    {IdentityVerifiedStatus.UNVERIFIED ===
                        user?.identity_verified && (
                        <Tooltip text="To build a great community for all our users, we ask everyone to verify their accounts to be able to use all provided products.">
                            <Link
                                className="text-accent underline hover:opacity-80"
                                href="/verify/identity"
                            >
                                Verify your account
                            </Link>
                        </Tooltip>
                    )}
                </div>
            </AccountItem>

            {user?.first_name && (
                <AccountItem title="Name and surname">
                    <h5 className="text-lg">
                        {user?.first_name || ''} {user?.last_name || ''}
                    </h5>
                </AccountItem>
            )}

            <AccountItem title="Country">
                <h5 className="text-lg">{user?.country || ''}</h5>
            </AccountItem>

            <AccountItem title="Email address">
                <h5 className="text-lg">{user?.email || ''}</h5>
            </AccountItem>

            <AccountItem title="Password">
                <div className="flex items-center space-x-4">
                    <h5 className="text-lg flex-1">********</h5>
                    <button
                        className="text-accent underline hover:opacity-80"
                        onClick={handleRequestChangePassword}
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="loader">
                                <img
                                    src="/img/Spinner-Small.png"
                                    className="mx-auto"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    alt="Loading..."
                                />
                            </div>
                        ) : (
                            'Change'
                        )}
                    </button>
                </div>
            </AccountItem>

            <AccountItem title="Username">
                <h5 className="text-lg">{user?.nickname}</h5>
            </AccountItem>
        </div>
    );
};

export default UserInfo;
