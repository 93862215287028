import React from 'react';
import { PartnerSection as PartnerSectionUI } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

export const PartnerSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    return (
        <PartnerSectionUI
            className={className}
            title={section.title}
            logos={section.images.map(({ url, link }) => ({
                image: url,
                link,
            }))}
        />
    );
};
