import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Media, Tabs, Typography } from '@decub8/ui';
import { isAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap/index';
import { DEFAULT_CHAIN_ID } from '@src/config';
import {
    useAppDispatch,
    useAppSelector,
    useDecubateContract,
} from '@src/hooks/index';
import { getAndSetUser } from '@src/hooks/pages/useLogin/utils';
import { useContent } from '@src/hooks/useContent';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services/user';
import { ContractType } from '@src/ts/constants';
import { parseBalance } from '@src/utils/web3';

import { ClaimRewardModal } from './ClaimRewardModal';
import { CongratulationModal } from './CongratulationModal';
import { InviteEarn } from './InviteEarn';
import { RequirementsCard } from './RequirementsCard';
import { RewardCarousel } from './RewardCarousel';
import { getReferralData } from './utils';

export enum ReferralStage {
    Requirements,
    InviteEarn,
}

export enum ReferralTabs {
    InviteEarn = 'InviteEarn',
    Bonus = 'Bonus',
}

export const options = [
    { title: 'Invite & earn', id: ReferralTabs.InviteEarn },
    { title: 'Referral bonus', id: ReferralTabs.Bonus },
];

export interface TierRewards {
    [key: number]: BigNumber;
}

// is_tier_rewards_uniform is a boolean that checks if all the rewards in the tier_rewards object are the same other than zero values

export const ReferralSystem: React.FC<{ min_referrer_tier: number }> = ({
    min_referrer_tier,
}) => {
    const { _userTier, _tiers, contract_manager } = useContext(GlobalContext);
    const { referralConfig } = useContent();
    const [show_claim_modal, setShowClaimModal] = useState(false);
    const [show_congrats_modal, setShowCongratsModal] = useState(false);
    const [requiremments_complete, setRequirementsComplete] = useState(false);
    const [user_referred, setUserReferred] = useState(0);
    const [rewards_earned, setRewardsEarned] = useState('');
    const [tier_rewards, setTierRewards] = useState<TierRewards>({});
    const [claim_tier, setClaimTier] = useState<number>();
    const [claimed_reward, setClaimedReward] = useState(false);
    const [claimable, setClaimable] = useState();
    const [modal_loading, setModalLoading] = useState(false);
    const [claim_loading, setClaimLoading] = useState(false);
    const [initial, setInitial] = useState(true);
    const { user } = useAppSelector((state) => state.auth);

    const router = useRouter();
    const { referral } = router.query;
    const coming_from_referral_flow = referral === 'true';

    const [nav, setNav] = useState(
        coming_from_referral_flow
            ? ReferralTabs.Bonus
            : ReferralTabs.InviteEarn,
    );

    const [wallet_store] = useDecubateContract(ContractType.WalletStore, true);

    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};

    const dispatch = useAppDispatch();

    const is_max = _userTier.id === _tiers.length - 1;

    const already_has_referral_Id = !!user.referral_id;

    const was_referred = !!user.referred_by && !isAddress(user.referred_by);

    // call .toString() on each value in tier_rewards
    const readable_tier_rewards = Object.entries(tier_rewards).reduce(
        (acc, [key, value]) => {
            acc[key] = value.toString();
            return acc;
        },
        {},
    );

    // Find the last key where the reward is '0'
    const last_key_with_zero_reward = Math.max(
        ...Object.keys(readable_tier_rewards)
            .filter((key) => readable_tier_rewards[key] === '0')
            .map((key) => parseInt(key, 10)),
        -1,
    );

    // Filter rewards to include only those after the last '0' reward
    const rewards_after_last_zero = Object.values(readable_tier_rewards).filter(
        (_, index) => index > last_key_with_zero_reward,
    );

    // Check if all remaining rewards are the same
    const is_tier_rewards_uniform =
        rewards_after_last_zero.length > 0 &&
        new Set(rewards_after_last_zero).size === 1;

    useEffect(() => {
        getReferralData(
            contract_manager,
            setTierRewards,
            setUserReferred,
            setRewardsEarned,
            setClaimedReward,
            setClaimable,
            setInitial,
            user?.wallet_address,
        ).catch((err) =>
            log.error(
                `error while getting referral data for users wallet: ${user?.wallet_address}`,
                err,
            ),
        );
    }, [contract_manager, user, _userTier, claim_loading, modal_loading]);

    const handleRewardClaim = async () => {
        setClaimLoading(true);

        try {
            toast.info('Waiting for successful transaction...');
            const tx = await wallet_store.claimReward();
            await tx.wait();
            if (tx.status === 0)
                throw new Error(
                    'An error occured during the transaction, please try again later',
                );
            toast.info('Transaction successfully complete.');
        } catch (err) {
            log.error("error while claiming user's reward", err);
            toast.error(err.reason || err.message);
        }

        setClaimLoading(false);
    };

    const handleClaim = async () => {
        setModalLoading(true);

        try {
            toast.info('Processing your rewards, this can take up to a minute');
            const { claimReferralReward: tx_hash } = await api_client.mutate<{
                claimReferralReward: string;
            }>({
                mutation: USER.CLAIM_REFERRAL_REWARD,
            });
            const tx = await contract_manager
                .getProvider(DEFAULT_CHAIN_ID)
                .waitForTransaction(tx_hash, 1);

            if (tx.status === 0)
                throw new Error(
                    'An error occured during the transaction, please try again later',
                );
            toast.info('Transaction successfully complete.');
            setNav(ReferralTabs.InviteEarn);
            setRequirementsComplete(false);
            await getAndSetUser(dispatch);
            setShowClaimModal(false);
            setShowCongratsModal(true);
            setInitial(false);
        } catch (err) {
            toast.error(err.message);
        }

        setModalLoading(false);
    };

    const renderComponentForFlow = useCallback(() => {
        switch (nav) {
            case ReferralTabs.InviteEarn:
                return (
                    <InviteEarn
                        min_referrer_tier={min_referrer_tier}
                        user_referred={user_referred}
                        referral_id={user.referral_id}
                        className="mx-auto"
                        rewards_earned={rewards_earned}
                        claimable={claimable}
                        handleRewardClaim={handleRewardClaim}
                        loading={claim_loading}
                        share_options={referralConfig.share_options}
                    />
                );
            case ReferralTabs.Bonus:
                return is_tier_rewards_uniform ? (
                    <RequirementsCard
                        min_referrer_tier={min_referrer_tier}
                        nav={nav}
                        setRequirementsComplete={setRequirementsComplete}
                        className="mx-auto"
                        user={user}
                        wallet_verified={wallet_verified}
                        setShowCongratsModal={setShowCongratsModal}
                        last_key_with_zero_reward={last_key_with_zero_reward}
                        is_tier_rewards_uniform={is_tier_rewards_uniform}
                        setClaimTier={setClaimTier}
                        tier_rewards={tier_rewards}
                        setClaimedReward={setClaimedReward}
                        setNav={setNav}
                    />
                ) : (
                    <RewardCarousel
                        tier_rewards={tier_rewards}
                        setShowClaimModal={setShowClaimModal}
                        setClaimTier={setClaimTier}
                    />
                );
            default:
                return <></>;
        }
    }, [
        nav,
        user_referred,
        tier_rewards,
        setShowClaimModal,
        setClaimTier,
        handleRewardClaim,
        user,
        rewards_earned,
        claimable,
        claim_loading,
        modal_loading,
    ]);

    return (
        <div>
            <div className="mb-[120px]">
                <div className="flex items-center max-w-[580px] mx-auto mt-10 mb-[60px]">
                    <button
                        className="flex-none"
                        onClick={() => router.push('/account')}
                    >
                        <Media size={5} variant="chevron-left" />
                    </button>

                    <Typography
                        allBold
                        className="flex-grow text-center"
                        size="2xl"
                    >
                        Referral program
                    </Typography>
                </div>

                {was_referred && !claimed_reward && !initial && (
                    <Tabs
                        className="max-w-[580px] mx-auto mb-5"
                        options={options}
                        current={nav}
                        setCurrent={(id) => {
                            setRequirementsComplete(false);
                            setNav(id as ReferralTabs);
                        }}
                        variant="limited"
                    />
                )}
                <div>
                    {already_has_referral_Id || requiremments_complete ? (
                        renderComponentForFlow()
                    ) : (
                        <RequirementsCard
                            min_referrer_tier={min_referrer_tier}
                            nav={nav}
                            setRequirementsComplete={setRequirementsComplete}
                            className="mx-auto"
                            user={user}
                            wallet_verified={wallet_verified}
                            setShowCongratsModal={setShowCongratsModal}
                            last_key_with_zero_reward={
                                last_key_with_zero_reward
                            }
                            is_tier_rewards_uniform={is_tier_rewards_uniform}
                            setClaimTier={setClaimTier}
                            tier_rewards={tier_rewards}
                            setClaimedReward={setClaimedReward}
                            setNav={setNav}
                        />
                    )}
                </div>
            </div>

            <ClaimRewardModal
                show={show_claim_modal}
                setShow={setShowClaimModal}
                handleClaim={handleClaim}
                loading={modal_loading}
                reward={
                    tier_rewards
                        ? parseBalance(
                              is_max && tier_rewards[claim_tier]
                                  ? tier_rewards[claim_tier].mul(
                                        _userTier.multiplier,
                                    )
                                  : tier_rewards[claim_tier] || 0,
                          )
                        : '0'
                }
            />
            <CongratulationModal
                show={show_congrats_modal}
                setShow={setShowCongratsModal}
                tier={claim_tier}
                reward={
                    tier_rewards
                        ? parseBalance(
                              is_max && tier_rewards[claim_tier]
                                  ? tier_rewards[claim_tier].mul(
                                        _userTier.multiplier,
                                    )
                                  : tier_rewards[claim_tier] || 0,
                          )
                        : '0'
                }
            />
        </div>
    );
};
