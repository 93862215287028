import React, { useEffect, useState } from 'react';
import { Tabs, Typography } from '@decub8/ui';
import dayjs from 'dayjs';

import { BASE_IMAGE_URL } from '@src/config';
import { EventType } from '@src/ts/constants';
import {
    FAQ as FAQInterface,
    ProjectEvent,
    ProjectLinks,
    ProjectToken,
} from '@src/ts/interfaces';
import { ProjectItemProps } from '@src/ts/props';

import { EventContainer } from '../Event/EventContainer';
import { LearnToEarn } from '../LearnToEarn';
import { Socials } from '../Socials';

import FAQ from './FAQ';
import { ProjectDetails } from './ProjectDetails';
import VestingDetails from './VestingDetails';

interface OverviewProps extends ProjectItemProps {
    event: ProjectEvent;
    should_display_event: boolean;
    scrollToLearnToEarn: () => void;
}

const renderTab = (
    tab_id: string,
    token: ProjectToken,
    event: ProjectEvent,
    description: string,
    explanation: string,
    faqs: FAQInterface[],
    links: ProjectLinks,
    name: string,
) => {
    switch (tab_id) {
        case 'details':
            return (
                <ProjectDetails
                    token={token}
                    event={event}
                    description={description}
                    explanation={explanation}
                    links={links}
                />
            );
        case 'vesting-details':
            return <VestingDetails token={token} event={event} />;
        case 'faqs':
            // Only render FAQs if there are FAQs available
            return faqs.length > 0 ? <FAQ faqs={faqs} name={name} /> : null;
        default:
            return null;
    }
};

const ProjectOverview = React.forwardRef<HTMLDivElement, OverviewProps>(
    ({ project, scrollToLearnToEarn, event, should_display_event }, ref) => {
        const {
            id,
            name,
            subtitle,
            description,
            explanation,
            hero,
            social_platforms,
            token,
            links,
            faqs,
        } = project || {};

        const tab_options = [
            { title: 'Project details', id: 'details' },
            { title: 'Vesting details', id: 'vesting-details' },
            // Only include the "FAQs" tab if there are FAQs
            ...(faqs.length > 0 ? [{ title: 'FAQs', id: 'faqs' }] : []),
        ];

        const [nav, setNav] = useState(tab_options[0].id);

        useEffect(() => {
            const video = document.getElementById(
                'hero-video',
            ) as HTMLMediaElement;
            let listener;

            if (!listener) {
                video.addEventListener('loadeddata', () => {
                    //Video should now be loaded but we can add a second check

                    if (video.readyState >= 3) {
                        //your code goes here
                        video.controls = true;
                    }
                });
            }

            return () => {
                if (listener) {
                    video.removeEventListener('loadeddata', listener);
                }
            };
        }, []);

        const learn_to_earn_event =
            event && event.type === EventType.TokenClaim && event;

        const not_over =
            learn_to_earn_event &&
            dayjs(Number(learn_to_earn_event.start_date) * 1000).isAfter(
                dayjs(),
            );

        return (
            <div
                className={`mb-20 w-full ${
                    should_display_event ? '' : 'max-w-[800px] mx-auto'
                }`}
            >
                <Typography allBold as={'h1'} size="2xl">
                    {name}
                </Typography>
                <Typography className="mt-[6px]" as={'h2'} variant="secondary">
                    {subtitle}
                </Typography>

                <Socials social_platforms={social_platforms} className="my-5" />

                <div className="min-w-full">
                    <video
                        id="hero-video"
                        className="rounded-lg"
                        width="100%"
                        poster={`${BASE_IMAGE_URL}/${id}/${hero}`}
                    >
                        <source
                            className="rounded-lg"
                            src={`${BASE_IMAGE_URL}/${id}/video.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </div>

                {should_display_event && (
                    <EventContainer
                        project={project}
                        scrollToLearnToEarn={scrollToLearnToEarn}
                        event={event}
                        mobile
                    />
                )}

                <Tabs
                    className="mt-5 mb-20"
                    options={tab_options}
                    current={nav}
                    setCurrent={(id) => setNav(id)}
                    variant="limited"
                />

                {renderTab(
                    nav,
                    token,
                    event,
                    description,
                    explanation,
                    faqs,
                    links,
                    name,
                )}

                {learn_to_earn_event && not_over && (
                    <LearnToEarn
                        project={project}
                        event={learn_to_earn_event}
                        ref={ref}
                    />
                )}
            </div>
        );
    },
);

export default ProjectOverview;
