import { gql } from '@apollo/client';

export const TIER = {
    RETRIEVE_TIERS: gql`
        query RetrieveTiers {
            retrieveTiers {
                id
                name
                min_limit
                refund_fee
                image_url
            }
        }
    `,
    RETRIEVE_USER_TIER: gql`
        query RetrieveUserTier {
            retrieveUserTier {
                id
                name
                min_limit
                refund_fee
                image_url
                flag
                multiplier
                actual_amount
                power
                boost_percentage
            }
        }
    `,
};
