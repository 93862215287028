import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { ITier } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import { TierRewards } from '..';

export const addRewardToTiers = (reward_obj: TierRewards, array: ITier[]) => {
    return array.map((item) => {
        const id = item.id.toString();
        const bigNumberValue = reward_obj[id];
        return { ...item, reward: bigNumberValue };
    });
};

export const getButtonText = (
    is_user_tier: boolean,
    multi: number,
    user_multiplier: number,
    is_connected_verified_wallet: boolean,
    diff: number,
    account: string,
) => {
    if (
        is_user_tier &&
        multi === user_multiplier &&
        !!account &&
        is_connected_verified_wallet
    )
        return 'Claim reward';
    if (!account) return 'Connect wallet';
    if (!is_connected_verified_wallet) return 'Switch to verified wallet';

    return `Stake ${parseBalance(diff)} ${BaseTokenSymbol}`;
};

// todo: should this be taken from the global context?
export const getTierContent = (
    tier: number,
    refund_amount: string,
    is_max_tier: boolean,
    multiplier?: number,
) => {
    return [
        `${CONTENT.tiers.tierConstants[tier].name} tier level ${
            // is max tier
            is_max_tier ? multiplier + 'x' : ''
        }`,
        parseFloat(refund_amount) > 0
            ? `${refund_amount} refund on investments`
            : 'No refund on investments',
        'Increase all allocations',
        'Referral system access',
        ...(CONTENT.no_governance ? [] : ['Governance voting']),
    ];
};
