export enum ContentAlignment {
    Centre = 'Centre',
    Left = 'Left',
}
export enum ConfigKey {
    MinTokensForTierNoMin = 'min_tokens_for_tier_with_no_min',
    MinTierForRefer = 'min_tier_for_refer',
}

export enum ConfigType {
    string = 'string',
    number = 'number',
}

export enum ContentSectionType {
    LandingPageVideo = 'LandingPageVideo',
    PartnerSection = 'PartnerSection',
}

export enum ContractType {
    PaymentToken = 'PaymentToken',
    BaseToken = 'BaseToken',
    ERC20 = 'ERC20',

    TierMigrator = 'TierMigrator',
    Investments = 'Investments',
    NFT = 'NFT',
    Tiers = 'Tiers',
    WalletStore = 'WalletStore',
    MultiCall = 'MultiCall',
    UniswapV2Pair = 'UniswapV2Pair',
    PlatformVesting = 'PlatformVesting',
    EventFactory = 'EventFactory',
    // legacy event factory
    LegacyEventFactory = 'LegacyEventFactory',
    // staking
    StakingV1 = 'StakingV1',
    LegacyCompoundStaking = 'LegacyCompoundStaking',
    LegacyVault = 'LegacyVault',
    MultiAssetStaking = 'MultiAssetStaking',
    LegacyLiquidityStaking = 'LegacyLiquidityStaking',
    // new staking
    Vault = 'Vault',
    CompoundStaking = 'CompoundStaking',
    LiquidityStaking = 'LiquidityStaking',
}

export enum EventType {
    Crowdfunding = 'Crowdfunding',
    TokenClaim = 'TokenClaim',
}

export enum IdentityVerifiedStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
}

export enum LinkPosition {
    Both = 'both',
    Footer = 'footer',
    Header = 'header',
}

export enum ProjectStatus {
    ACTIVE = 'ACTIVE',
    APPROVED = 'APPROVED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
}

export enum StrategyType {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum UserRole {
    Admin = 'Admin',
    ProjectAdmin = 'ProjectAdmin',
    ProjectManager = 'ProjectManager',
    ComplianceOfficer = 'ComplianceOfficer',
    UserManager = 'UserManager',
    ProductManager = 'ProductManager',
    Manager = 'Manager',
    Innovator = 'Innovator',
    Investor = 'Investor',
    None = 'None',
}

export const UserGroup = {
    Admin: [UserRole.Admin],
    ProjectAdmin: [UserRole.Admin, UserRole.ProjectAdmin],
    ProductManager: [UserRole.Admin, UserRole.ProductManager, UserRole.Manager],
    ProjectManager: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.Manager,
    ],
    ComplianceOfficer: [UserRole.Admin, UserRole.ComplianceOfficer],
    UserManager: [
        UserRole.Admin,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
    ],
    TeamAuthenticated: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
    ],
    Authenticated: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
        UserRole.Innovator,
        UserRole.Investor,
    ],
    NoAuth: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
        UserRole.Innovator,
        UserRole.Investor,
        UserRole.None,
    ],
};

// corresponds to the enum in the backend
export enum StatisticType {
    UniqueParticipantsIDO = 'UniqueParticipantsIDO',
    UniqueParticipantsL2E = 'UniqueParticipantsL2E',
    ATHValueDistributed = 'ATHValueDistributed',
    TotalAmountRaised = 'TotalAmountRaised',
    EventsCompleteIDO = 'EventsCompleteIDO',
    EventsCompleteL2E = 'EventsCompleteL2E',
    TasksCompleted = 'TasksCompleted',
    AverageATH = 'AverageATH',
}
