import React from 'react';
import { Accordion, Typography } from '@decub8/ui';

import { FAQ as IFAQ } from '@src/ts/interfaces';

const FAQ: React.FC<{
    faqs: IFAQ[];
    name: string;
}> = ({ faqs }) => {
    return (
        <div>
            <Typography allBold as={'h2'} className="mb-5" size="3xl">
                Frequently asked questions
            </Typography>

            <div>
                {faqs?.map((d) => (
                    <div className="mb-4" key={JSON.stringify(d)}>
                        <Accordion subtitle={d.question}>
                            <div className="space-y-4">
                                {d.answer.map((answer, idx) => (
                                    <Typography
                                        key={`${answer}-${idx}`}
                                        variant="secondary"
                                    >
                                        {answer}
                                    </Typography>
                                ))}
                            </div>
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
