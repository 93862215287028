import React from 'react';
import { InviteEarn as InviteEarnUI, MediaVariant } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';

import { useInviteEarn } from './hook';

export const InviteEarn: React.FC<{
    className: string;
    referral_id: string;
    user_referred: number;
    rewards_earned: string;
    claimable: BigNumber;
    handleRewardClaim: () => void;
    loading: boolean;
    share_options: {
        name: MediaVariant;
        url: string;
    }[];
    min_referrer_tier: number;
}> = ({
    className,
    referral_id,
    user_referred,
    rewards_earned,
    claimable,
    handleRewardClaim,
    loading,
    min_referrer_tier,
}) => {
    const props = useInviteEarn(
        referral_id,
        user_referred,
        rewards_earned,
        claimable,
        handleRewardClaim,
        loading,
        min_referrer_tier,
    );
    return <InviteEarnUI className={className} {...props} />;
};
