import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useCountdown } from '@src/hooks';
import { displayCountdown } from '@src/utils/format';

dayjs.extend(utc);

export const useTimedText = (
    date: Dayjs,
    shorten: boolean,
    no_time?: boolean,
    no_utc?: boolean,
) => {
    const time_left = useCountdown(
        date.toString() !== 'Invalid Date'
            ? date.toISOString()
            : new Date().toISOString(),
    );

    const should_display_countdown =
        date.toString() !== 'Invalid Date' &&
        date.diff(dayjs(), 'hours') < 24 &&
        dayjs().isBefore(date);

    const time_to_use = no_utc ? date : date.utc();

    const text = should_display_countdown
        ? `${displayCountdown(time_left)}`
        : time_to_use.format(
              shorten ? 'HH:mm' : `DD MMM${no_time ? '' : ' / HH:mm'}`,
          );

    return `${text}${no_utc || should_display_countdown ? '' : ' UTC'}`;
};

export const TimeText: React.FC<{
    date: Dayjs;
    shorten: boolean;
    no_time?: boolean;
    no_utc?: boolean;
}> = ({ date, shorten, no_time, no_utc }) => {
    const text = useTimedText(date, shorten, no_time, no_utc);
    return (
        <h4 data-testid="time-text">
            <strong>{text}</strong>
        </h4>
    );
};
