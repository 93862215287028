import {
    BreakPoint,
    DesktopPool,
    MobilePool,
    PoolContainer as PoolContainerUI,
    useBreakpoint,
} from '@decub8/ui';

import { CONTENT } from '@src/config';
import { StakingPool } from '@src/ts/interfaces';

import { usePoolContainer, usePoolItem } from './hooks';

const PoolItem: React.FC<{ pool: StakingPool; is_mobile: boolean }> = ({
    pool,
    is_mobile,
}) => {
    const itemProps = usePoolItem(pool);
    const conProps = usePoolContainer(pool, is_mobile);

    const breakpoint = useBreakpoint();
    const use_mobile_pool = breakpoint < BreakPoint.LG;

    return (
        <PoolContainerUI {...conProps}>
            {use_mobile_pool ? (
                <MobilePool
                    has_accent_gradient={CONTENT.hasGradientStakingChips}
                    {...itemProps}
                />
            ) : (
                <DesktopPool
                    has_accent_gradient={CONTENT.hasGradientStakingChips}
                    {...itemProps}
                />
            )}
        </PoolContainerUI>
    );
};

export default PoolItem;
