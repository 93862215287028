import React from 'react';
import { Media, MediaVariant, Typography } from '@decub8/ui';
import Link from 'next/link';

import { SocialLink } from '@src/ts/interfaces';

export const Socials: React.FC<{
    social_platforms: SocialLink[];
    className?: string;
}> = ({ social_platforms, className }) => {
    return (
        <div className={`flex items-center space-x-8 ${className}`}>
            {social_platforms.map((s, i) => (
                <Link
                    href={((s.type === 'mail' && 'mailto:') || '') + s.url}
                    key={s.url + i}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center"
                >
                    <Media
                        size={6}
                        className="text-secondary hover:text-primary"
                        variant={s?.type as MediaVariant}
                    />
                </Link>
            ))}
        </div>
    );
};

export const L2ESocials: React.FC<{
    social_platforms: SocialLink[];
    className?: string;
    title?: string;
}> = ({ social_platforms, title }) => {
    return (
        <div className="flex justify-between items-center my-4 text-secondary p-4 bg-surface-level-one rounded space-x-4">
            <Typography>{title}</Typography>
            {social_platforms && (
                <div className="flex items-center space-x-4 md:space-x-8 justify-end">
                    {social_platforms.map((s, i) => (
                        <Link
                            href={
                                ((s.type === 'mail' && 'mailto:') || '') + s.url
                            }
                            key={s.url + i}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-secondary hover:text-white"
                        >
                            <Media
                                size={6}
                                className="text-secondary hover:text-primary"
                                variant={s?.type as MediaVariant}
                            />
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};
