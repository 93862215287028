import { gql } from '@apollo/client';

export const CONTENT_API = {
    RETRIEVE_CONTENT_SECTION: gql`
        query RetrieveContentSection($name: ContentSectionType!) {
            retrieveContentSection(name: $name) {
                alignment
                name
                text
                title
                buttons {
                    name
                    opaque
                    text
                    url
                }
                images {
                    name
                    url
                    link
                }
                hidden
            }
        }
    `,
};
