import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
    BreakPoint,
    SortSearch,
    StatsCards,
    Toggle,
    Typography,
    useBreakpoint,
} from '@decub8/ui';
import { TierColors } from '@decub8/ui/dist/molecules/types';
import { BigNumber } from '@ethersproject/bignumber';
import { commify } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { useSortSearch } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { getfilterData, getSortedData } from '@src/hooks/useSortSearch';
import { Investment, LiveTokenPriceAndIncrease } from '@src/ts/interfaces';
import { getMappedTokenPrices } from '@src/utils/getters';
import { uiLibTierColor } from '@src/utils/user';
import { bnSum, parseBalance } from '@src/utils/web3';

import { InvestmentItem } from '.';

const DEFAULT_PAYMENT_TOKEN = CONTRACT.PaymentToken[DEFAULT_CHAIN_ID];

export const InvestmentPanel: FC<{
    investments: Investment[];
}> = ({ investments }) => {
    const { _userTier, _tiers, _setTierDrawerOpen } = useContext(GlobalContext);
    const [claimable, setClaimable] = useState(true);
    const [invest_is_open, setInvestIsOpen] = useState<{
        [key: string]: boolean;
    }>({});

    const [query, setQuery] = useState('');
    const [investmentData, setInvestmentData] = useState<Investment[]>();
    const [mappedTokenPrices, setMappedTokenPrices] =
        useState<LiveTokenPriceAndIncrease>({});
    const sortProps = useSortSearch(
        investments,
        setInvestmentData,
        setQuery,
        claimable,
    );

    const breakpoint = useBreakpoint();
    const break_for_search_options = breakpoint < 1080;

    const is_mobile = BreakPoint.XS;
    const is_max = _userTier?.id === _tiers.length - 1;

    const total = useMemo(
        () =>
            bnSum(
                investments.map(
                    ({
                        invest_amount,
                        is_airdrop,
                        refunded,
                        payment_decimals,
                    }) => {
                        // multiply by 10^payment_decimals and then divde by 10^default_payment_decimals to scale numbers in same decimal range
                        const scaled = BigNumber.from(invest_amount)
                            .mul(
                                BigNumber.from(10).pow(
                                    DEFAULT_PAYMENT_TOKEN.decimals,
                                ),
                            )
                            .div(BigNumber.from(10).pow(payment_decimals));

                        return BigNumber.from(
                            is_airdrop || refunded ? 0 : scaled,
                        );
                    },
                ),
            ),
        [investments],
    );

    useEffect(() => {
        getMappedTokenPrices(investments, setMappedTokenPrices);
    }, [investments]);

    useEffect(() => {
        if (query !== '') {
            const data = investmentData?.filter((investment) =>
                investment.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, '')),
            );
            data && setInvestmentData([...data]);
        } else {
            const updated_investments = investments.map((investment, idx) => {
                return {
                    ...investment,
                    start_date: investment.start_date
                        ? investment.start_date
                        : dayjs(0).add(idx, 'weeks').toISOString(),
                    end_date: investment.end_date
                        ? investment.end_date
                        : dayjs(0).add(idx, 'weeks').toISOString(),
                };
            });

            let data = getfilterData(
                [...updated_investments],
                sortProps.selectedFilterOption,
                claimable,
            );

            data = getSortedData(data, sortProps.selectedSortOption);

            setInvestmentData(data);
        }
    }, [investments, query, claimable]);

    return investments.length > 0 ? (
        <div>
            <StatsCards
                tier_info={{
                    is_max_tier: is_max,
                    tier_name: _userTier?.name || 'No Tier',
                    multiplier: _userTier?.multiplier || 1,
                    img_url: _userTier?.image_url || CONTENT.tiers.NoTierImage,
                    tier_color: uiLibTierColor(
                        _userTier?.multiplier || 1,
                    ) as TierColors,
                }}
                total_stake={`${commify(
                    _userTier?.power.toFixed(2) || 0,
                )} ${BaseTokenSymbol}`}
                total_invested={`${parseBalance(
                    total,
                    DEFAULT_PAYMENT_TOKEN.decimals,
                )} ${DEFAULT_PAYMENT_TOKEN.symbol}`}
                events_participated={investments.length.toString()}
                handleUpgradeTier={() => _setTierDrawerOpen(true)}
            />

            <div className="my-12">
                <div
                    className={`${
                        break_for_search_options
                            ? 'space-y-5'
                            : 'flex justify-between items-center'
                    } w-full mb-5 md:mb-8`}
                >
                    <div className="flex items-center justify-between space-x-8">
                        <Typography allBold size="2xl">
                            {is_mobile ? 'Investments' : 'Your investments'}
                        </Typography>
                        <div className="flex items-center space-x-8">
                            <Toggle
                                className="mt-1"
                                active={claimable}
                                onChange={() => setClaimable(!claimable)}
                            />
                            <Typography>Claimable only</Typography>
                        </div>
                    </div>
                    <div
                        className={`${
                            break_for_search_options ? '' : 'w-[650px]'
                        }`}
                    >
                        <SortSearch {...sortProps} fullWidth />
                    </div>
                </div>

                {/* headings */}
                {investmentData?.length > 0 && (
                    <div className="items-center hidden md:flex">
                        <div className="grid grid-cols-15 text-secondary w-full flex-1 gap-[10px] py-4 px-5">
                            <div className="col-span-3">Event</div>
                            <div className="cols-span-1">Network</div>
                            <div className="col-span-2">Invested</div>
                            <div className="col-span-2">Tokens</div>
                            <div className="col-span-2">Status</div>
                            <div className="col-span-2">Purchase price</div>
                            <div className="col-span-2">Live price</div>
                            <div className="col-span-1"></div>
                        </div>
                    </div>
                )}
                {/* the actual investments - add a filter still to filter on chain id */}
                {investmentData &&
                    investmentData.map((i, idx) => (
                        <InvestmentItem
                            {...i}
                            invest_is_open={invest_is_open[JSON.stringify(i)]}
                            setInvestOpen={(isOpen: boolean) => {
                                setInvestIsOpen({
                                    [JSON.stringify(i)]: isOpen,
                                });
                            }}
                            live_token_price={
                                mappedTokenPrices[`${i.name}-${i.token_symbol}`]
                                    ?.live_token_price
                            }
                            token_change={
                                mappedTokenPrices[`${i.name}-${i.token_symbol}`]
                                    ?.percent_increase
                            }
                            token_price={
                                mappedTokenPrices[`${i.name}-${i.token_symbol}`]
                                    ?.initial_token_price
                            }
                            key={JSON.stringify(i + '' + idx)}
                        />
                    ))}
            </div>
        </div>
    ) : (
        <div className="my-32 text-center">
            <h2 className="text-2xl md:text-4xl mb-4 md:mb-8">
                <strong>You have no investments</strong>
            </h2>
            <p className="text-secondary md:text-lg">
                Add to your portfolio by participating in the platform events
            </p>
        </div>
    );
};
