import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SortSearchProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import type { Investment } from '@src/ts/interfaces';

export function getSortedData(data: Investment[], sortOption: string) {
    switch (sortOption) {
        case 'Newest':
            return [...data].sort(
                (a, b) =>
                    dayjs(b.start_date).unix() - dayjs(a.start_date).unix(),
            );
        case 'Oldest':
            return [...data].sort(
                (a, b) =>
                    dayjs(a.start_date).unix() - dayjs(b.start_date).unix(),
            );

        case 'Expiration':
            return [...data].sort(
                (a, b) => dayjs(a.end_date).unix() - dayjs(b.end_date).unix(),
            );
        default:
            return data; // Default return the data unchanged
    }
}

export function getfilterData(
    data: Investment[],
    filterOption: string,
    claimable: boolean,
) {
    if (claimable)
        data = data.filter(
            (investment) =>
                BigNumber.from(investment.available || '0').gt(0) &&
                !investment.refunded,
        );

    switch (filterOption) {
        case 'L2E':
            return data.filter((a) => a.is_airdrop);
        case 'Crowd..':
            return data.filter((investment) => !investment.is_airdrop);
        case 'Completed':
            return data.filter(
                (investment) =>
                    BigNumber.from(investment.total || '1').eq(
                        investment.released || '0',
                    ) && BigNumber.from(investment.available || '0').eq(0),
            );
        default:
            return data;
    }
}

export const useSortSearch = (
    investments: Investment[] | undefined,
    setInvestmentData: Dispatch<SetStateAction<Investment[] | undefined>>,
    setQuery: Dispatch<SetStateAction<string>>,
    claimable: boolean,
): SortSearchProps => {
    const [selectedFilterOption, setSelectedFilterOption] = useState('All');
    const [selectedSortOption, setSelectedSortOption] = useState('Newest');

    const updated_investments = investments.map((investment, idx) => {
        return {
            ...investment,
            start_date: investment.start_date
                ? investment.start_date
                : dayjs(0).add(idx, 'weeks').toISOString(),
            end_date: investment.end_date
                ? investment.end_date
                : dayjs(0).add(idx, 'weeks').toISOString(),
        };
    });

    useEffect(() => {
        if (!investments) return; // Ensure data is not undefined

        let data = getfilterData(
            [...updated_investments],
            selectedFilterOption,
            claimable,
        );
        data = getSortedData(data, selectedSortOption);
        setInvestmentData(data);
    }, [selectedFilterOption, selectedSortOption, investments]);

    const props: SortSearchProps = {
        searchText: 'Search your investments',
        selectedSortOption: selectedSortOption,
        sortOptions: [
            {
                name: 'Newest',
                onOptionClick: () => setSelectedSortOption('Newest'),
            },
            {
                name: 'Oldest',
                onOptionClick: () => setSelectedSortOption('Oldest'),
            },
            {
                name: 'Expiration',
                onOptionClick: () => setSelectedSortOption('Expiration'),
            },
        ],
        selectedFilterOption: selectedFilterOption,
        filterOptions: [
            {
                name: 'All',
                onOptionClick: () => setSelectedFilterOption('All'),
            },
            {
                name: 'L2E',
                onOptionClick: () => setSelectedFilterOption('L2E'),
            },
            {
                name: 'IDO',
                onOptionClick: () => setSelectedFilterOption('Crowd..'),
            },
            {
                name: 'Completed',
                onOptionClick: () => setSelectedFilterOption('Completed'),
            },
        ],
        handleSearch: (val) => setQuery(val),
    };

    return props;
};
