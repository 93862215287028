import { useEffect, useState } from 'react';
import { NotificationBarProps, NotificationType } from '@decub8/ui';
import { log } from '@logtail/next';
import deepEqual from 'deep-equal';
import { useRouter } from 'next/router';

import { useAppSelector } from '@src/hooks/index';
import { UserGroup } from '@src/ts/constants';
import { NotificationData } from '@src/ts/interfaces';
import { getNotificationData } from '@src/utils/getters';

export const useNotification = (): NotificationBarProps | undefined => {
    const [notification, setNotification] = useState<NotificationData>({
        active: false,
        button_text: '',
        link: '',
        message: '',
        type: NotificationType.Default,
    });

    const user = useAppSelector((state) => state.auth.user);

    const router = useRouter();

    useEffect(() => {
        getNotificationData()
            .then((getNotification) => {
                const dismissedMessage = JSON.parse(
                    localStorage.getItem('dismissedMessage') || '{}',
                );

                if (
                    deepEqual(dismissedMessage, notification) &&
                    getNotification.active
                ) {
                    setNotification({ ...getNotification, active: false });
                } else {
                    setNotification(getNotification);
                }
            })
            .catch((err) => log.error('error getting notification data', err));
    }, []);

    const dismissedMessage = JSON.parse(
        (typeof window !== 'undefined' &&
            localStorage.getItem('dismissedMessage')) ||
            '{}',
    );

    const message_already_exists = deepEqual(dismissedMessage, notification);

    if (
        !notification.active ||
        (message_already_exists &&
            !UserGroup.ProjectManager.includes(user?.role))
    )
        return;

    return {
        active: notification.active,
        handleClose: () => {
            setNotification({ ...notification, active: false });
            localStorage.setItem(
                'dismissedMessage',
                JSON.stringify(notification),
            );
        },
        text: notification?.message,
        handleClick: () =>
            notification?.link?.includes('http')
                ? window.open(notification.link, '_blank')
                : router.push(notification?.link),
        bg_color: notification?.type as NotificationType,
        btn_text: notification?.button_text,
    };
};
