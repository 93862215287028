import React from 'react';

import { ContentProps } from '@src/ts/props';

export const Content: React.FC<ContentProps> = ({
    children,
    className = '',
    padding = true,
}) => {
    return (
        <div
            className={`flex-1 container max-w-screen-lg mx-auto text-left text-primary ${
                padding ? 'p-5' : ''
            } ${className}`}
        >
            {children}
        </div>
    );
};
