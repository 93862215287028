import React from 'react';

const AccountItem: React.FC<{
    title: string;
    gradient?: boolean;
    children?: React.ReactNode;
}> = ({ children, title, gradient = false }) => (
    <div
        className={`border border-border border-opacity-5 p-5 my-4 rounded ${
            gradient
                ? 'bg-clip-padding bg-gradient-to-bl from-surface-level-one via-surface-level-one to-warning'
                : 'bg-surface-level-one'
        }`}
    >
        {children}
        <p className={`text-sm text-secondary mt-2`}>{title.toUpperCase()}</p>
    </div>
);

export default AccountItem;
