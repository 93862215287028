import { ContentSectionType } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';

import { LandingPageVideoSection } from './LandingPageVideo';
import { PartnerSection } from './PartnerSection';

export const ContentSection: React.FC<{
    className?: string;
    section: IContentSection;
}> = ({ section, className }) => {
    switch (section?.name) {
        case ContentSectionType.LandingPageVideo:
            return <LandingPageVideoSection section={section} />;
        case ContentSectionType.PartnerSection:
            return <PartnerSection section={section} className={className} />;
        default:
            return (
                <div>
                    ContentSection {section?.name || 'UNDEFINED'} not yet
                    implemented!
                </div>
            );
    }
};
