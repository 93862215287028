export const Card: React.FC<{
    className?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}> = ({
    children,
    className,
    onClick = () => {
        return;
    },
}) => (
    <div
        data-testid="card"
        className={`rounded border border-border border-opacity-5 ${
            className || 'bg-surface-level-one p-4'
        }`}
        onClick={onClick}
    >
        {children}
    </div>
);
