import { useMemo } from 'react';
import { Contract, ContractInterface } from '@ethersproject/contracts';

import { DEFAULT_CHAIN_ID } from '@src/config';
import { ABI } from '@src/contracts/index';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';

import { useGlobalContext } from '../useGlobalContext';

export const useContract = (
    address: string,
    abi: ContractInterface,
    withSigner = false,
): Contract => {
    const { provider, account } = useWeb3Onboard();

    return useMemo(
        () =>
            !!address && !!ABI && !!provider
                ? new Contract(
                      address,
                      abi,
                      withSigner
                          ? provider.getSigner(account).connectUnchecked()
                          : provider,
                  )
                : undefined,
        [address, ABI, withSigner, provider, account],
    );
};

export const useDecubateContract = (
    type: ContractType,
    withSigner = false,
    chainId = DEFAULT_CHAIN_ID,
): [Contract, string] => {
    const { provider, account } = useWeb3Onboard();
    const { contract_manager } = useGlobalContext();
    const address = useMemo(
        () => contract_manager.getContractAddress(type, chainId),
        [type, chainId],
    );
    const abi = ABI[type];

    return [
        useMemo(
            () =>
                !!address && !!ABI && !!provider
                    ? new Contract(
                          address,
                          abi,
                          withSigner
                              ? provider.getSigner(account).connectUnchecked()
                              : provider,
                      )
                    : undefined,

            [address, abi, withSigner, provider, account],
        ),
        address,
    ];
};

export const useDecubateContractByAddress = (
    address: string,
    type: ContractType,
    withSigner = false,
): Contract => {
    const { provider, account } = useWeb3Onboard();

    const abi = ABI[type];

    return useMemo(
        () =>
            !!address && !!ABI && !!provider
                ? new Contract(
                      address,
                      abi,
                      withSigner
                          ? provider.getSigner(account).connectUnchecked()
                          : provider,
                  )
                : undefined,
        [address, abi, withSigner, provider, account],
    );
};

export const useERC20Contract = (
    address: string,
    withSigner = false,
): Contract => {
    const { provider, account } = useWeb3Onboard();

    const abi = ABI[ContractType.ERC20];

    return useMemo(
        () =>
            !!address && !!ABI && !!provider
                ? new Contract(
                      address,
                      abi,
                      withSigner
                          ? provider.getSigner(account).connectUnchecked()
                          : provider,
                  )
                : undefined,
        [address, abi, withSigner, provider, account],
    );
};
