import React from 'react';

import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';

const NetworkIcon: React.FC<{ chain_id: number }> = ({
    chain_id = DEFAULT_CHAIN_ID,
}) => {
    return (
        <img
            className="w-8 h-8"
            src={NETWORKS[chain_id || DEFAULT_CHAIN_ID].network_logo}
            alt="network"
        />
    );
};

export default NetworkIcon;
