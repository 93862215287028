import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from 'react';

// Lightbox for landing page video context

interface LightboxContextType {
    lightboxOpen: boolean;
    setLightboxOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultState: LightboxContextType = {
    lightboxOpen: false,
    setLightboxOpen: () => {
        console.warn('setLightboxOpen not implemented');
    }, // Empty function as a placeholder
};

const LightboxContext = createContext<LightboxContextType>(defaultState);

export const useLightbox = () => useContext(LightboxContext);

export const LightboxProvider: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);

    return (
        <LightboxContext.Provider value={{ lightboxOpen, setLightboxOpen }}>
            {children}
        </LightboxContext.Provider>
    );
};
