import React from 'react';

import { is_browser } from '../constants';

export const isTouchDevice = (): boolean => {
    return (
        is_browser &&
        ('ontouchstart' in window || window.navigator.maxTouchPoints > 0)
    );
};

export const scrollIntoView = (ref: React.RefObject<HTMLElement>): void =>
    ref.current.scrollIntoView();
