import React from 'react';

import { useNotification } from '@src/components/Layout/Header/hooks/useNotification';
import { Project, ProjectEvent as IProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../../context';
import { ProjectEvent } from '../';

export const EventContainer: React.FC<{
    project: Project;
    scrollToLearnToEarn: () => void;
    event: IProjectEvent;
    mobile?: boolean;
}> = ({ event, project, scrollToLearnToEarn, mobile = false }) => {
    // TODO: when the notification is closed when on the event page the notification value does not update
    const notification = useNotification();

    // when the buy dcb modal is open make sure the event widget is smaller z index
    const { show_buy_modal } = useProjectContext();

    return (
        <div
            className={`mb-20 ${
                mobile
                    ? 'w-full lg:hidden mt-5'
                    : 'hidden lg:inline-block lg:w-[380px]'
            }`}
        >
            <div
                className={
                    mobile
                        ? ''
                        : `rounded  overflow-y-scroll sticky ${
                              show_buy_modal ? '-z-10' : ''
                          } ${notification?.active ? 'top-[144px]' : 'top-20'}`
                }
            >
                <ProjectEvent
                    project_id={project.id}
                    scrollToLearnToEarn={scrollToLearnToEarn}
                    event={event}
                />
            </div>
        </div>
    );
};
