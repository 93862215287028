import React from 'react';

const types = {
    up: 'M5 15l7-7 7 7',
    down: 'M19 9l-7 7-7-7',
    left: 'M15 19l-7-7 7-7',
    right: 'M9 5l7 7-7 7',
};

export const Chevron: React.FC<{
    type: 'up' | 'down' | 'left' | 'right';
    width?: string;
    height?: string;
}> = ({ type, width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-${height || 6} w-${width || 6}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={types[type]}
            />
        </svg>
    );
};
