import React, { useMemo } from 'react';
import { Button, Typography } from '@decub8/ui';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useAppSelector } from '@src/hooks';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { ProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../../context';
import { EventRegister } from '../Register';

const LearnToEarn: React.FC<{
    event: ProjectEvent;
    scrollToLearnToEarn: () => void;
}> = ({ event, scrollToLearnToEarn }) => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const router = useRouter();
    const { settingChain } = useSwitchChain();

    const registration_over = useMemo(
        () => dayjs(parseInt(event.start_date) * 1000).isBefore(dayjs()),
        [event?.start_date],
    );

    const { registered, data_loading } = useProjectContext();

    const handleClick = () => {
        user ? scrollToLearnToEarn() : router.push('/login');
    };

    return (
        <div>
            {(data_loading && (
                <div className="animate-pulse space-y-2 my-1">
                    <div className="w-full h-4 bg-surface-level-two rounded"></div>
                    <div className="w-3/4 h-4 bg-surface-level-two rounded"></div>
                </div>
            )) || (
                <>
                    {registered ? (
                        <EventRegister event={event} />
                    ) : (
                        <Typography
                            variant="secondary"
                            className={`${registered ? '' : 'mb-4'}`}
                        >
                            {event.contract?.address
                                ? `Complete the challenge to earn your allocation.`
                                : 'This event will be live soon'}
                        </Typography>
                    )}
                    {event?.contract.address &&
                        (!user || (!registration_over && !registered)) && (
                            <Button
                                onClick={handleClick}
                                className="w-full mb-4"
                                loading={settingChain}
                            >
                                {user ? 'Go to challenge' : 'Log in'}
                            </Button>
                        )}
                </>
            )}
        </div>
    );
};

export default LearnToEarn;
