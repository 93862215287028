import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { staking_contract_versions } from '@src/constants';
import { IContractManager } from '@src/contracts';
import { ContractType } from '@src/ts/constants';
import { StakingPool } from '@src/ts/interfaces';
import { legacy_staking_versions, StakingVersion } from '@src/ts/types';

export const getStakingPools = async (
    versions: StakingVersion[],
    contract_manager: IContractManager,
): Promise<StakingPool[]> => {
    const staking_pools = (
        await Promise.all(
            versions.map(async (v) => {
                const is_legacy = (
                    Array.from(legacy_staking_versions) as string[]
                ).includes(v);

                const type = staking_contract_versions[v];

                if (is_legacy) {
                    return contract_manager
                        .getContract(type)
                        .contract.getPools()
                        .then((pools) =>
                            pools.map((p, contract_idx) => ({
                                ...p,
                                contract_idx,
                                version: v,
                                chain_id: DEFAULT_CHAIN_ID,
                            })),
                        )
                        .catch((e) => {
                            console.error('Error fetching legacy pools', e);
                            return [];
                        });
                } else {
                    const chains_for_version = Object.keys(CONTRACT[type]).map(
                        Number,
                    );

                    const all_pools = await Promise.all(
                        chains_for_version.map((chain_id) => {
                            const staking = contract_manager.getContract(
                                type,
                                chain_id,
                            );
                            return staking.contract
                                .getPools()
                                .then((pools) =>
                                    pools.map((p, contract_idx) => ({
                                        ...p,
                                        contract_idx,
                                        version: v,
                                        chain_id,
                                    })),
                                )
                                .catch((e) => {
                                    console.error('Error fetching pools', e);
                                    return [];
                                });
                        }),
                    );

                    return all_pools.flat();
                }
            }),
        )
    ).reduce((acc, curr) => [...acc, ...curr], []);

    const mapped = (await Promise.all(
        staking_pools.map((p, idx) => mapPool(p, idx, contract_manager)),
    )) as StakingPool[];

    return mapped;
};

export const mapPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version } = p;

    switch (version) {
        case 'multi-asset':
            return mapMultiAssetPool(p, id, contract_manager);
        case 'legacy-compound':
            return mapLegacyCompoundPool(p, id, contract_manager);
        case 'compound':
            return mapCompoundPool(p, id, contract_manager);
        case 'legacy-liquidity':
            return mapLegacyLiquidityStakingPool(p, id, contract_manager);
        case 'liquidity':
            return mapLiquidityStakingPool(p, id, contract_manager);
        default:
            return mapLegacyPool(p, id, contract_manager);
    }
};

const mapMultiAssetPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, chain_id } = p;

    const input_token = p.inputToken[0].toLowerCase();
    const reward_token = p.rewardToken[0].toLowerCase();

    const staking_addr = contract_manager
        .getContractAddress(staking_contract_versions[version], chain_id)
        .toLowerCase();
    const staking_iface = contract_manager.getContractInterface(
        staking_contract_versions[version],
    );
    const erc20_iface = contract_manager.getContractInterface(
        ContractType.ERC20,
    );
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    const [
        nft,
        [input_symbol],
        [input_decimals],
        [reward_symbol],
        [reward_decimals],
        [boost_value],
    ] = await contract_manager.multicall(
        [
            {
                target: staking_addr,
                func_name: 'nftInfo',
                iface: staking_iface,
                params: [contract_idx],
            },
            {
                target: input_token,
                func_name: 'symbol',
                iface: erc20_iface,
            },
            {
                target: input_token,
                func_name: 'decimals',
                iface: erc20_iface,
            },
            {
                target: reward_token,
                func_name: 'symbol',
                iface: erc20_iface,
            },
            {
                target: reward_token,
                func_name: 'decimals',
                iface: erc20_iface,
            },
            {
                target: tiers.contract.address,
                iface: tiers.contract.interface,
                func_name: 'boostValues',
                params: [staking_addr, contract_idx],
            },
        ],
        { chain_id },
    );

    const i = {
        address: input_token,
        decimals: input_decimals,
        symbol: input_symbol,
    };

    const r = {
        address: reward_token,
        decimals: reward_decimals,
        symbol: reward_symbol,
    };

    const { startIdx = 0, endIdx = 0 } = nft;

    return {
        id,
        contract_addr: staking_addr,
        chain_id,
        contract_idx,
        type: version,
        apr: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: [i],
        reward_token: r,
        total_staked: [p.totalDeposit?.toString()],
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multiplier?.toString() || '10',
        nft_multiplier_used: nft?.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_indexes: [startIdx, endIdx],
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent: p.rebatePercent,
        early_withdraw_penality: p.earlyWithdrawPenalty,
        is_early_withdraw_active: p.isEarlyWithdrawActive,
    };
};

const mapLiquidityStakingPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, chain_id } = p;

    const staking_iface = contract_manager.getContractInterface(
        staking_contract_versions[version],
    );
    const pair_iface = contract_manager.getContractInterface(
        ContractType.UniswapV2Pair,
    );
    const erc20_iface = contract_manager.getContractInterface(
        ContractType.ERC20,
    );

    const staking_addr = contract_manager
        .getContractAddress(staking_contract_versions[version], chain_id)
        .toLowerCase();
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    const pair_addr = p.input;
    const reward_token = p.reward;

    const [
        [is_native],
        nft,
        [token0_addr],
        [token1_addr],
        reserves,
        [total_lps],
        [boost_value],
    ] = await contract_manager.multicall(
        [
            {
                target: staking_addr,
                func_name: 'isWrappedNative',
                iface: staking_iface,
                params: [pair_addr],
            },
            {
                target: staking_addr,
                func_name: 'multis',
                iface: staking_iface,
                params: [contract_idx],
            },
            {
                target: pair_addr,
                func_name: 'token0',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'token1',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'getReserves',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'totalSupply',
                iface: pair_iface,
            },
            {
                target: tiers.contract.address,
                iface: tiers.contract.interface,
                func_name: 'boostValues',
                params: [staking_addr, contract_idx],
            },
        ],
        { chain_id },
    );

    const [
        [token0_symbol],
        [token0_decimals],
        [token1_symbol],
        [token1_decimals],
        [reward_symbol],
        [reward_decimals],
    ] = await contract_manager.multicall(
        [
            {
                target: token0_addr,
                func_name: 'symbol',
            },
            {
                target: token0_addr,
                func_name: 'decimals',
            },
            {
                target: token1_addr,
                func_name: 'symbol',
            },
            {
                target: token1_addr,
                func_name: 'decimals',
            },
            {
                target: reward_token,
                func_name: 'symbol',
            },
            {
                target: reward_token,
                func_name: 'decimals',
            },
        ],
        { default_iface: erc20_iface, chain_id },
    );

    const i = [
        {
            address: token0_addr.toLowerCase(),
            decimals: token0_decimals,
            symbol: token0_symbol,
        },
        {
            address: token1_addr.toLowerCase(),
            decimals: token1_decimals,
            symbol: token1_symbol,
        },
    ];

    const r = {
        address: reward_token.toLowerCase(),
        decimals: reward_decimals,
        symbol: reward_symbol,
    };

    const token0_amount = reserves.reserve0
        .mul(p.totalInvested)
        .div(total_lps.eq(0) ? 1 : total_lps);
    const token1_amount = reserves.reserve1
        .mul(p.totalInvested)
        .div(total_lps.eq(0) ? 1 : total_lps);

    const matching_token =
        token0_symbol === reward_symbol ? reserves.reserve0 : reserves.reserve1;

    const reward_value_lp = matching_token
        .mul(BigNumber.from(10).pow(18))
        .div(total_lps.eq(0) ? 1 : total_lps)
        .mul(2);

    const reward_token_value = parseFloat(
        formatUnits(reward_value_lp, reward_decimals),
    );

    const re = p.rewardRate / 1000;

    const reward_token_rate = ((re / reward_token_value) * 100).toFixed(2);

    return {
        apr: reward_token_rate + '%',
        id,
        chain_id,
        contract_addr: staking_addr,
        contract_idx,
        type: version,
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: i,
        reward_token: r,
        total_staked: [token0_amount, token1_amount],
        hardcap: '0',
        end_date: dayjs(p.endDate * 1000)
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multi?.toString() || '100',
        nft_multiplier_used: nft?.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_indexes: [nft.start.toNumber(), nft.end.toNumber()],
        pair: pair_addr,
        is_native: [is_native !== 2, is_native],
        is_unlocked: !p.isWithdrawLocked,
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent: p.rebatePercent,
        early_withdraw_penality: p.earlyWithdrawPenalty,
        is_early_withdraw_active: p.isEarlyWithdrawActive,
    };
};

const mapLegacyLiquidityStakingPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, chain_id } = p;

    const staking_iface = contract_manager.getContractInterface(
        staking_contract_versions[version],
    );
    const pair_iface = contract_manager.getContractInterface(
        ContractType.UniswapV2Pair,
    );
    const erc20_iface = contract_manager.getContractInterface(
        ContractType.ERC20,
    );

    const staking_addr = contract_manager
        .getContractAddress(staking_contract_versions[version], chain_id)
        .toLowerCase();
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    const pair_addr = p.input;
    const reward_token = p.reward;

    const [
        [is_native],
        nft,
        [token0_addr],
        [token1_addr],
        reserves,
        [total_lps],
        [boost_value],
    ] = await contract_manager.multicall(
        [
            {
                target: staking_addr,
                func_name: 'isWrappedNative',
                iface: staking_iface,
                params: [pair_addr],
            },
            {
                target: staking_addr,
                func_name: 'multis',
                iface: staking_iface,
                params: [contract_idx],
            },
            {
                target: pair_addr,
                func_name: 'token0',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'token1',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'getReserves',
                iface: pair_iface,
            },
            {
                target: pair_addr,
                func_name: 'totalSupply',
                iface: pair_iface,
            },
            {
                target: tiers.contract.address,
                iface: tiers.contract.interface,
                func_name: 'boostValues',
                params: [staking_addr, contract_idx],
            },
        ],
        { chain_id },
    );

    const [
        [token0_symbol],
        [token0_decimals],
        [token1_symbol],
        [token1_decimals],
        [reward_symbol],
        [reward_decimals],
    ] = await contract_manager.multicall(
        [
            {
                target: token0_addr,
                func_name: 'symbol',
            },
            {
                target: token0_addr,
                func_name: 'decimals',
            },
            {
                target: token1_addr,
                func_name: 'symbol',
            },
            {
                target: token1_addr,
                func_name: 'decimals',
            },
            {
                target: reward_token,
                func_name: 'symbol',
            },
            {
                target: reward_token,
                func_name: 'decimals',
            },
        ],
        { default_iface: erc20_iface, chain_id },
    );

    const i = [
        {
            address: token0_addr.toLowerCase(),
            decimals: token0_decimals,
            symbol: token0_symbol,
        },
        {
            address: token1_addr.toLowerCase(),
            decimals: token1_decimals,
            symbol: token1_symbol,
        },
    ];

    const r = {
        address: reward_token.toLowerCase(),
        decimals: reward_decimals,
        symbol: reward_symbol,
    };

    const token0_amount = reserves.reserve0.mul(p.totalInvested).div(total_lps);
    const token1_amount = reserves.reserve1.mul(p.totalInvested).div(total_lps);

    return {
        id,
        chain_id,
        contract_addr: staking_addr,
        contract_idx,
        type: version,
        apr: Math.round((p.rewardRate.toNumber() / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: i,
        reward_token: r,
        total_staked: [token0_amount, token1_amount],
        hardcap: '0',
        end_date: dayjs(p.endDate * 1000)
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multi?.toString() || '100',
        nft_multiplier_used: nft?.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_indexes: [nft.start.toNumber(), nft.end.toNumber()],
        pair: pair_addr,
        is_native: [is_native !== 2, is_native],
        is_unlocked: !p.isWithdrawLocked,
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent: p.rebatePercent,
        early_withdraw_penality: p.earlyWithdrawPenalty,
        is_early_withdraw_active: p.isEarlyWithdrawActive,
    };
};

export const mapLegacyCompoundPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, nft = {}, chain_id } = p;
    const token =
        p.token?.toLowerCase() ||
        contract_manager
            .getContractAddress(ContractType.BaseToken, chain_id)
            .toLowerCase();

    const iface = contract_manager.getContractInterface(ContractType.ERC20);
    const staking_addr = contract_manager
        .getContractAddress(staking_contract_versions[version], chain_id)
        .toLowerCase();
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    const [[input_symbol], [input_decimals], [boost_value]] =
        await contract_manager.multicall(
            [
                {
                    target: token,
                    func_name: 'symbol',
                },
                {
                    target: token,
                    func_name: 'decimals',
                },
                {
                    target: tiers.contract.address,
                    iface: tiers.contract.interface,
                    func_name: 'boostValues',
                    params: [
                        contract_manager.getContractAddress(
                            ContractType.LegacyVault,
                            chain_id,
                        ),
                        contract_idx,
                    ],
                },
            ],
            { default_iface: iface, chain_id },
        );

    const t = {
        address: token,
        decimals: input_decimals,
        symbol: input_symbol,
    };

    const { startIdx = 0, endIdx = 0 } = nft || {};

    return {
        id,
        chain_id,
        contract_addr: staking_addr,
        contract_idx,
        type: version,
        apr: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: [t],
        reward_token: t,
        total_staked: [p.totalDeposit?.toString()],
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multiplier?.toString() || '10',
        nft_multiplier_used: nft?.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_indexes: [startIdx, endIdx],
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent: p.rebatePercent,
        early_withdraw_penality: p.earlyWithdrawPenalty,
        is_early_withdraw_active: p.isEarlyWithdrawActive,
    };
};

const mapCompoundPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, chain_id } = p;

    const token =
        p.token?.toLowerCase() ||
        contract_manager
            .getContractAddress(ContractType.BaseToken, chain_id)
            .toLowerCase();

    const iface = contract_manager.getContractInterface(ContractType.ERC20);
    const staking = contract_manager.getContract(
        staking_contract_versions[version],
        chain_id,
    );
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    /**
     * the rebate staking is currently only used for aitech's staking
     */

    const vault = contract_manager.getContract(ContractType.Vault, chain_id);
    let rebate_percent = 0;
    let is_early_withdraw_active = false;
    let early_withdraw_penality = 0;

    try {
        const [
            [
                _is_early_withdraw_active,
                _rebate_percent,
                _early_withdraw_penality,
            ],
        ] = await contract_manager.multicall(
            [
                {
                    target: vault.contract.address,
                    iface: vault.contract.interface,
                    func_name: 'rebates',
                    params: [contract_idx],
                },
            ],
            { chain_id },
        );

        if (Number(_rebate_percent) !== 0) {
            rebate_percent = _rebate_percent / 100;
        }
        is_early_withdraw_active = _is_early_withdraw_active;
        early_withdraw_penality = _early_withdraw_penality / 100;
    } catch (e) {
        // console.error('REBATE POOL ERROR', e);
    }

    const [nft, [input_symbol], [input_decimals], [boost_value]] =
        await contract_manager.multicall(
            [
                {
                    target: staking.contract.address,
                    func_name: 'nftInfo',
                    iface: staking.contract.interface,
                    params: [contract_idx],
                },
                {
                    target: token,
                    func_name: 'symbol',
                    iface,
                },
                {
                    target: token,
                    func_name: 'decimals',
                    iface,
                },
                {
                    target: tiers.contract.address,
                    iface: tiers.contract.interface,
                    func_name: 'boostValues',
                    params: [
                        contract_manager.getContractAddress(
                            ContractType.Vault,
                            chain_id,
                        ),
                        contract_idx,
                    ],
                },
            ],
            {
                chain_id,
            },
        );

    const t = {
        address: token,
        decimals: input_decimals,
        symbol: input_symbol,
    };

    const { startIdx = 0, endIdx = 0 } = nft;

    return {
        id,
        contract_addr: staking.contract.address.toLowerCase(),
        contract_idx,
        chain_id,
        type: version,
        apr: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: [t],
        reward_token: t,
        total_staked: [p.totalDeposit?.toString()],
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multiplier?.toString() || '10',
        nft_multiplier_used: nft?.active || false,
        nft_indexes: [startIdx, endIdx],
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent,
        early_withdraw_penality,
        is_early_withdraw_active,
    };
};

const mapLegacyPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
    contract_manager: IContractManager,
): Promise<StakingPool> => {
    const { version, contract_idx, nft = {}, chain_id } = p;
    const token = contract_manager
        .getContractAddress(ContractType.BaseToken, chain_id)
        .toLowerCase();
    const iface = contract_manager.getContractInterface(ContractType.ERC20);
    const staking_addr = contract_manager
        .getContractAddress(staking_contract_versions[version], chain_id)
        .toLowerCase();
    const tiers = contract_manager.getContract(ContractType.Tiers, chain_id);

    const [[input_symbol], [input_decimals], [boost_value]] =
        await contract_manager.multicall(
            [
                {
                    target: token,
                    func_name: 'symbol',
                },
                {
                    target: token,
                    func_name: 'decimals',
                },
                {
                    target: tiers.contract.address,
                    iface: tiers.contract.interface,
                    func_name: 'boostValues',
                    params: [staking_addr, contract_idx],
                },
            ],
            { default_iface: iface, chain_id },
        );
    const t = {
        address: token,
        decimals: input_decimals,
        symbol: input_symbol,
    };
    const { startIdx = 0, endIdx = 0 } = nft || {};

    return {
        id,
        contract_addr: staking_addr,
        contract_idx,
        chain_id,
        type: version,
        apr: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: [t],
        reward_token: t,
        total_staked: [p.totalDeposit?.toString()],
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multiplier?.toString() || '10',
        nft_multiplier_used: nft?.active || false,
        ratio: p.ratio?.toString() || '1',
        is_reward_above_input: p.isRewardAboveInput || false,
        nft_indexes: [startIdx, endIdx],
        tier_boost: boost_value.toNumber() / 10,
        rebate_percent: p.rebatePercent,
        early_withdraw_penality: p.earlyWithdrawPenalty,
        is_early_withdraw_active: p.isEarlyWithdrawActive,
    };
};
