import React from 'react';
import { Media, Tooltip } from '@decub8/ui';

export interface InfoTextProps {
    text: string;
    info: string;
    text_color?: string;
}

export const InfoText: React.FC<InfoTextProps> = ({
    info,
    text,
    text_color,
}) => {
    return (
        <div className="flex items-center flex-wrap space-x-2">
            <p className={text_color}>{text}</p>

            <Tooltip text={info}>
                <Media
                    className="text-accent"
                    size={5}
                    variant="info-outline"
                />
            </Tooltip>
        </div>
    );
};
