import { useEffect } from 'react';

import { setInitial, setPools, updatePools } from '@src/features/staking';
import { useAppDispatch } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ExchangeTypes } from '@src/ts/types';
import { getStakingPools } from '@src/utils/getters';
import { getSupportedStakingVersions } from '@src/utils/staking';

export const getInitialPathIndex = (current: string, paths: string[]) => {
    if (current.split('/').length > 2) {
        let idx = 0;
        let first_found = false;
        for (let i = 0; i < current.length; i++) {
            const c = current[i];
            if (c === '/') {
                if (first_found) {
                    idx = i;
                    break;
                } else {
                    first_found = true;
                }
            }
        }
        current = current.slice(0, idx);
    }
    return paths.findIndex((p) => p === current);
};
export const useStakingPools = () => {
    const dispatch = useAppDispatch();
    const { account } = useWeb3Onboard();
    const { contract_manager } = useGlobalContext();
    useEffect(() => {
        try {
            getStakingPools(
                getSupportedStakingVersions(),
                contract_manager,
            ).then((pools) => {
                account
                    ? dispatch(updatePools(account, pools, contract_manager))
                    : dispatch(setPools(pools));
                dispatch(setInitial(false));
            });
        } catch (e) {
            console.error(e);
        }
    }, [account]);
};

export const EXCHANGE_NAMES: ExchangeTypes[] = [
    'ascendex',
    'bitfinex',
    'dcx',
    'bybit',
    'gate',
    'kucoin',
    'pancakeswap',
    'uniswap',
    'bitget',
    'bitmart',
    'coinstore',
    'coinw',
    'lbank',
    'mexc',
    'poloniex',
    'bitpanda',
    'bingx',
    'htx',
    'coinex',
];

export const getExchangeData = (icon: ExchangeTypes) => {
    switch (icon) {
        case 'ascendex':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Ascendex-exchange-logo.svg',
                title: 'Ascendex',
                is_dex: false,
            };
        case 'bitfinex':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/bitfinex-exchange-logo.svg',
                title: 'Bitfinex',
                is_dex: false,
            };
        case 'dcx':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/DCX-exchange-logo.svg',
                title: 'CoinDCX',
                is_dex: false,
            };
        case 'bybit':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/bybit-exchange-logo.svg',
                title: 'ByBit',
                is_dex: false,
            };
        case 'gate':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/gate-exchange-logo.svg',
                title: 'Gate.io',
                is_dex: false,
            };
        case 'kucoin':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/kucoin-exchange-logo.svg',
                title: 'KuCoin',
                is_dex: false,
            };
        case 'pancakeswap':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/pancakeswap-exchange-logo.svg',
                title: 'Pancakeswap',
                is_dex: true,
            };
        case 'uniswap':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/uniswap-exchange-logo.svg',
                title: 'Uniswap',
                is_dex: true,
            };
        case 'bitget':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Bitget-exchange-logo.svg',
                title: 'Bitget',
                is_dex: false,
            };
        case 'bitmart':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Bitmart-exchange-logo.svg',
                title: 'BitMart',
                is_dex: false,
            };
        case 'coinstore':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Coinstore-exchange-logo.svg',
                title: 'Coinstore',
                is_dex: false,
            };
        case 'coinw':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Coinw-exchange-logo.svg',
                title: 'CoinW',
                is_dex: false,
            };
        case 'lbank':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Lbank-exchange-logo.svg',
                title: 'LBank',
                is_dex: false,
            };
        case 'mexc':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/MEXC-exchange-logo.svg',
                title: 'MEXC',
                is_dex: false,
            };
        case 'poloniex':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Ploniex-exchange-logo.svg',
                title: 'Poloniex',
                is_dex: false,
            };
        case 'bitpanda':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/Bitpanda.svg',
                title: 'Bitpanda',
                is_dex: false,
            };
        case 'bingx':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/BingX-exchange-logo.svg',
                title: 'BingX',
                is_dex: false,
            };
        case 'htx':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/exchange-logos/htx-token-ht-logo.svg',
                title: 'HTX',
                is_dex: false,
            };
        case 'coinex':
            return {
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/content/coinex.png',
                title: 'CoinEx',
                is_dex: false,
            };

        default:
            return { image: '', title: '' };
    }
};
