import React, { useContext } from 'react';
import { Card } from '@decub8/ui';
import dayjs from 'dayjs';

import { Loader } from '@src/components/Layout';
import { Typography } from '@src/components/Typography';
import { useAppSelector } from '@src/hooks';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { EventType } from '@src/ts/constants';
import { Project, ProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../context';
import { Qualify } from '../Event/Qualify';

import { Challenge } from './Challenge';

export const RenderChallenge: React.FC<{
    project: Project;
    event: ProjectEvent;
}> = ({ event, project }) => {
    const { _userTier } = useContext(GlobalContext);
    const { wallet_verified, user } =
        useAppSelector((state) => state?.auth) || {};

    const { min_tier = 0 } = event.event_details || {};

    const {
        registered,
        data_loading,
        registerForWhitelist,
        whitelist_loading,
    } = useProjectContext();

    const not_eligble =
        !user || !wallet_verified || !_userTier || _userTier.id < min_tier;

    const is_over = dayjs().isAfter(dayjs(Number(event.start_date) * 1000));

    if (data_loading) {
        return (
            <Card>
                <Loader />
            </Card>
        );
    } else if (registered || is_over) {
        return (
            <Card className="p-5">
                <Typography type="h3">
                    {registered
                        ? 'Congratulations! You have completed the challenge.'
                        : 'This event has ended'}
                </Typography>
            </Card>
        );
    } else if (not_eligble) {
        return (
            <Card className="p-5 my-auto">
                {event.type === EventType.TokenClaim && (
                    <Typography type="h4" className="mb-4">
                        Please complete the event qualification process
                    </Typography>
                )}
                <div className="w-full md:w-3/4">
                    <Qualify
                        event={event}
                        min_tier={min_tier}
                        registered={registered}
                    />
                </div>
            </Card>
        );
    }
    return (
        <Challenge
            project={project}
            event={event}
            registerForWhitelist={registerForWhitelist}
            whitelist_loading={whitelist_loading}
        />
    );
};
