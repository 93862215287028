export const cancellablePromise = (
    // eslint-disable-next-line
    promise: Promise<any | void>,
): { promise: Promise<unknown>; cancel: () => void } => {
    const isCancelled = { value: false };
    const wrappedPromise = new Promise((res, rej) => {
        promise
            .then((d) => {
                return isCancelled.value ? rej(isCancelled) : res(d);
            })
            .catch((e) => {
                rej(isCancelled.value ? isCancelled : e);
            });
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            isCancelled.value = true;
        },
    };
};

export function delay(ms: number): Promise<unknown> {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms); // (A)
    });
}

export function timeout<T>(ms: number, promise: Promise<T>): Promise<T> {
    return new Promise(function (resolve, reject) {
        promise.then(resolve);
        setTimeout(function () {
            reject(new Error('Timeout after ' + ms + ' ms')); // (A)
        }, ms);
    });
}
