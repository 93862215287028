import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { StakingPool } from '@src/ts/interfaces';
import { removeCommas } from '@src/utils/web3';

import {
    NFT_ID_MAP,
    STAKE_METHOD,
    unstake_field,
    UNSTAKE_METHOD,
} from './constants';

const LIQUIDITY_SLIPPAGE_BASIS_POINTS = 25;

export const findNft = (start: number, end: number): string => {
    let nft = 'None';
    Object.entries(NFT_ID_MAP).forEach(([name, [s, e]]) => {
        if (s === start && e === end) {
            nft = name;
        }
    });

    return nft;
};

export const getStakeArgs = (
    pool: StakingPool,
    deposit_amount: string,
    token1_deposit_amount: string,
): [string, unknown[]] => {
    return [
        STAKE_METHOD[pool.type],
        stakeArgs(pool, deposit_amount, token1_deposit_amount),
    ];
};

export const getUnstakeArgs = (
    pool: StakingPool,
    withdraw_percent: number,
): [string, unknown[]] => {
    return [UNSTAKE_METHOD[pool.type], unstakeArgs(pool, withdraw_percent)];
};

const stakeArgs = (
    pool: StakingPool,
    deposit_amount_token0: string,
    deposit_amount_token1: string,
) => {
    const parsed_deposit_token0 = parseUnits(
        removeCommas(deposit_amount_token0 || '0'),
        pool.input_token[0].decimals,
    );

    const parsed_deposit_token1 = parseUnits(
        removeCommas(deposit_amount_token1 || '0'),
        pool.input_token[1]?.decimals,
    );

    const { is_native = [false, 0] } = pool;

    const is_wrapped_native_token = is_native[0];

    const native_is_token0 = is_native[1] === 0;

    const native_token_amount =
        (is_wrapped_native_token &&
            (native_is_token0
                ? parsed_deposit_token0
                : parsed_deposit_token1)) ||
        '0';

    switch (pool.type) {
        case 'liquidity':
            return [
                pool.contract_idx,
                is_wrapped_native_token && native_is_token0
                    ? 0
                    : parsed_deposit_token0,
                is_wrapped_native_token && !native_is_token0
                    ? 0
                    : parsed_deposit_token1,
                parsed_deposit_token0
                    .mul(10000 - LIQUIDITY_SLIPPAGE_BASIS_POINTS)
                    .div(10000),
                parsed_deposit_token1
                    .mul(10000 - LIQUIDITY_SLIPPAGE_BASIS_POINTS)
                    .div(10000),
                {
                    value: native_token_amount,
                },
            ];
        case 'legacy-liquidity':
            return [
                pool.contract_idx,
                parsed_deposit_token0,
                parsed_deposit_token1,
                {
                    value:
                        (is_native[0] &&
                            (is_native[1] === 0
                                ? parsed_deposit_token0
                                : parsed_deposit_token1)) ||
                        '0',
                },
            ];
        default:
            return [pool.contract_idx, parsed_deposit_token0, {}];
    }
};

const unstakeArgs = (pool: StakingPool, withdraw_percent: number) => {
    const field = unstake_field[pool.type];

    const token0_min = BigNumber.from((pool?.user_stake || [])[0] || 0)
        .mul(withdraw_percent)
        .div(100);

    const token1_min = BigNumber.from((pool.user_stake || [])[1] || 0)
        .mul(withdraw_percent)
        .div(100);

    switch (pool.type) {
        case 'compound':
            return [pool.contract_idx, pool[field], {}];
        case 'legacy-compound':
            return [pool.contract_idx, pool[field], {}];
        case 'liquidity':
            return [
                pool.contract_idx,
                BigNumber.from(pool[field] || 0)
                    .mul(withdraw_percent || 0)
                    .div(100),
                token0_min
                    .mul(10000 - LIQUIDITY_SLIPPAGE_BASIS_POINTS)
                    .div(10000),
                token1_min
                    .mul(10000 - LIQUIDITY_SLIPPAGE_BASIS_POINTS)
                    .div(10000),
            ];
        case 'legacy-liquidity':
            return [
                pool.contract_idx,
                BigNumber.from(pool[field] || 0)
                    .mul(withdraw_percent || 0)
                    .div(100),
                {},
            ];
        default:
            return [pool.contract_idx, (pool[field] || ['0'])[0], {}];
    }
};
