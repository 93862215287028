import { FC } from 'react';
import { Chip, Typography } from '@decub8/ui';

import { EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { CrowdfundingProgress } from './CrowdfundingProgress';

export const EventHeader: FC<{
    event: ProjectEvent;
}> = ({ event }) => {
    const is_crowdfunding = event.type === EventType.Crowdfunding;

    return (
        <div>
            <div className="bg-surface-level-two px-6 py-3 low-border rounded relative w-full text-left cursor-default flex items-center space-x-3">
                <Typography allBold>{event.name}</Typography>

                {event?.refund_deadline > 0 && (
                    <img
                        alt="protected invetment"
                        src="https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg"
                        className="w-8 h-8"
                    />
                )}
                {event.is_hidden && (
                    <Chip size="xs" className="pl-3 pr-3">
                        hidden
                    </Chip>
                )}
            </div>
            {is_crowdfunding && <CrowdfundingProgress event={event} />}
        </div>
    );
};
