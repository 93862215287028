import React, { useState } from 'react';
import { Button, DataLines, Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { formatEther } from '@ethersproject/units';
import { useRouter } from 'next/router';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { PaymentTokenSymbol } from '@src/contracts/index';
import { useAppSelector } from '@src/hooks';
import { parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../../context';

import { EventCompleteAlert } from './EventCompleteAlert';

export const CrowdfundingComplete: React.FC = () => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const router = useRouter();

    const {
        project: { name, token },
        event,
        num_whitelisted = 0,
        crowdfunding: { total_raised, hardcap, user_investment } = {
            total_raised: '0',
            hardcap: '0',
            user_investment: '0',
        },
    } = useProjectContext();

    const [loading] = useState(false);

    // use 1 if hardcap === 0 as 0/0 === NaN
    const denom = Number(formatEther(hardcap)) || 1;
    const percent_filled = (Number(formatEther(total_raised)) / denom) * 100;

    const invested = BigNumber.from(user_investment).gt(0);

    const handleClick = async () =>
        router.push(`/portfolio/${user?.wallet_address}`);
    const event_chain_id = event?.chainId || DEFAULT_CHAIN_ID;

    const payment_decimals = CONTRACT.PaymentToken[event_chain_id].decimals;

    return (
        <div className="mt-5">
            {invested && !user && (
                <Typography variant="secondary" size="sm" className="my-4">
                    Thank you for investing in {name}, you can claim the token
                    after the token generation event.
                </Typography>
            )}

            <div className={`flex mt-4 text-lg ${invested ? '' : 'mb-4'}`}>
                <DataLines
                    className="flex-1"
                    variant="verticle"
                    label={'Funded'}
                    values={[
                        {
                            value: `${percent_filled.toFixed(1)}%`,
                        },
                    ]}
                    top_size="3xl"
                    bottom_size="md"
                />
                <DataLines
                    className="flex-1"
                    variant="verticle"
                    label={'Participants'}
                    values={[
                        {
                            value: num_whitelisted.toString(),
                        },
                    ]}
                    top_size="3xl"
                    bottom_size="md"
                />
            </div>

            {!invested && <EventCompleteAlert />}
            {user && invested && (
                <div className="border bg-transparent border-secondary p-5 rounded mt-5">
                    <Typography size="sm" variant="secondary">
                        Event Complete
                    </Typography>
                    <Typography size="sm" variant="secondary" className="mt-4">
                        Thank you for investing in {name}, you can claim the
                        token after the token generation event.
                    </Typography>
                    <div className="space-y-4 mt-4">
                        <DataLines
                            label={'Total investment'}
                            values={[
                                {
                                    value:
                                        parseBalance(
                                            user_investment,
                                            payment_decimals,
                                        ) +
                                        ' ' +
                                        PaymentTokenSymbol(event_chain_id),
                                },
                            ]}
                        />
                        <DataLines
                            label={'Token generation event'}
                            values={[
                                {
                                    value: token?.tge || 'TBA',
                                },
                            ]}
                        />
                    </div>
                    <Button
                        loading={loading}
                        disabled={loading}
                        className="w-full py-2 mt-4"
                        onClick={handleClick}
                    >
                        View in your portfolio
                    </Button>
                </div>
            )}
        </div>
    );
};
