import { StatsBar } from '@decub8/ui';

import { useStakingAnalytics } from './hooks';

export const StakingAnalytics: React.FC<{
    is_legacy?: boolean;
}> = ({ is_legacy }) => {
    const props = useStakingAnalytics(is_legacy);

    return <StatsBar {...props} />;
};
