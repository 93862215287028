import { formatUnits } from '@ethersproject/units';
import { log } from '@logtail/next';
import axios from 'axios';

import { api_client } from '@src/bootstrap';
import {
    DEFAULT_CHAIN_ID,
    EVENT_FACTORY_CHAIN_IDS,
    HAS_LEGACY_EVENT_FACTORY,
    HAS_LEGACY_INVESTMENTS,
} from '@src/config';
import { TOKEN_DECIMALS } from '@src/constants';
import { IContractManager, PaymentTokenSymbol } from '@src/contracts';
import { PROJECT } from '@src/services';
import { ContractType } from '@src/ts/constants';
import { INFT, LiveTokenPriceAndIncrease } from '@src/ts/interfaces';
import { Investment } from '@src/ts/interfaces';

import { formatTicker } from '../format';

export const getTokenPrices = async (
    tokens: { symbol: string; address?: string }[],
): Promise<string[]> => {
    //TODO check if first char is *, is so, remove it - quick ugly fix for Sidus (they have a project of whcih the symbol starts with an *).
    tokens = tokens.map((t) => ({
        ...t,
        symbol: formatTicker(t.symbol),
    }));

    const { getTokenPrices } = await api_client.query<{
        getTokenPrices: string[];
    }>({
        query: PROJECT.GET_TOKEN_PRICES,
        variables: {
            tokens,
        },
    });

    return getTokenPrices;
};

export const getMappedTokenPrices = (
    investments: Investment[],
    setMappedTokenPrices: (mapped: LiveTokenPriceAndIncrease) => void,
) => {
    const initial_prices = investments.reduce((acc, investment) => {
        const key = `${investment.name}-${investment.token_symbol}`; // Unique key
        acc[key] = formatUnits(
            investment.token_price,
            investment.payment_decimals,
        );

        return acc;
    }, {});

    const tokens_to_fetch = investments.map(
        ({ token_symbol, token_address }) => ({
            symbol: token_symbol,
            address: token_address,
        }),
    );

    const fetchTokenPrices = async () => {
        if (tokens_to_fetch.length === 0) {
            return;
        }

        try {
            const live_prices_arr = await getTokenPrices(tokens_to_fetch);

            const new_prices = {};

            investments.forEach((investment, index) => {
                const key = `${investment.name}-${investment.token_symbol}`;
                const livePrice = parseFloat(live_prices_arr[index]);
                const initialPrice = parseFloat(initial_prices[key]);

                if (initialPrice === 0) {
                    new_prices[key] = {
                        percent_increase: undefined,
                        live_token_price:
                            livePrice === 0 ? undefined : livePrice,
                        initial_token_price: undefined,
                    };
                } else {
                    const percentageIncrease =
                        ((livePrice - initialPrice) / initialPrice) * 100;
                    new_prices[key] = {
                        percent_increase: percentageIncrease,
                        live_token_price: livePrice,
                        initial_token_price: initialPrice,
                    };
                }
            });

            setMappedTokenPrices(new_prices);
        } catch (error) {
            console.error('Error fetching token prices:', error);
        }
    };

    fetchTokenPrices();
};

export const getUserInvestments = (
    addr: string,
    contract_manager: IContractManager,
): Promise<Investment[]> => {
    return Promise.all([
        HAS_LEGACY_INVESTMENTS
            ? getLegacyUserInvestments(addr, contract_manager)
            : [],
        HAS_LEGACY_EVENT_FACTORY
            ? getLegacyCrosschainUserInvestments(addr, contract_manager)
            : [],
        getCrosschainUserInvestments(addr, contract_manager),
    ]).then((all) => all.flat());
};

export const getCrosschainUserInvestments = async (
    addr: string,
    contract_manager: IContractManager,
): Promise<Investment[]> => {
    const promises: Promise<Investment | null>[] = [];

    for (const chainId of EVENT_FACTORY_CHAIN_IDS) {
        try {
            const investments = contract_manager.getContract(
                ContractType.EventFactory,
                chainId,
            );

            // Attempt to fetch all investment addresses
            const addrs = await investments.contract.getUserInvestments(addr);

            const chainPromises: Promise<Investment | null>[] = addrs.map(
                async (event_addr: string) => {
                    try {
                        const [
                            event,
                            // eslint-disable-next-line
                            _,
                            vesting_info,
                            whitelist_info,
                            claimable,
                        ] = await investments.contract.getInvestmentInfo(
                            addr,
                            event_addr,
                        );

                        const token = contract_manager.getContractByAddress(
                            event.tokenAddress,
                            ContractType.ERC20,
                            chainId,
                        );
                        const payment = contract_manager.getContractByAddress(
                            event.paymentToken,
                            ContractType.ERC20,
                            chainId,
                        );
                        const vesting = contract_manager.getContractByAddress(
                            event.vestingAddress,
                            ContractType.PlatformVesting,
                            chainId,
                        );
                        const is_airdrop = event.eventType !== 0;

                        const [
                            token_symbol,
                            decimals,
                            payment_symbol,
                            payment_decimals,
                            grace_period,
                        ] = await Promise.all([
                            token.contract.symbol(),
                            token.contract.decimals(),
                            is_airdrop
                                ? Promise.resolve('')
                                : payment.contract.symbol(),
                            is_airdrop
                                ? Promise.resolve(18)
                                : payment.contract.decimals(),
                            is_airdrop
                                ? Promise.resolve(0)
                                : vesting.contract.gracePeriod(),
                        ]);

                        const invested = whitelist_info.value;
                        const amount = whitelist_info.amount;

                        const price = invested
                            .mul(BigInt(10 ** decimals).toString())
                            .div(amount);

                        return {
                            name: event.name,
                            invest_amount: invested.toString(),
                            token_price: price.toString(),
                            token_symbol,
                            vesting_active: true,
                            contract_address: event_addr,
                            is_airdrop,
                            decimals,
                            start_date: new Date(
                                vesting_info.start.mul(1000).toNumber(),
                            ).toISOString(),
                            cliff: new Date(
                                vesting_info.cliff.mul(1000).toNumber(),
                            ).toISOString(),
                            end_date: new Date(
                                vesting_info.cliff
                                    .add(vesting_info.duration)
                                    .mul(1000)
                                    .toNumber(),
                            ).toISOString(),
                            released:
                                whitelist_info.distributedAmount.toString(),
                            total: amount.toString(),
                            available: claimable.toString(),
                            initial_unlock:
                                vesting_info.initialUnlockPercent.toString(),
                            refunded: whitelist_info.refunded,
                            refund_deadline: parseInt(grace_period.toString()),
                            vesting_address: vesting.contract.address,
                            payment_symbol,
                            payment_decimals,
                            chainId,
                            token_address: event.tokenAddress,
                            contract_type: ContractType.EventFactory,
                        };
                    } catch (error) {
                        log.error(
                            `Error processing investment at ${event_addr} for ${addr}`,
                            error,
                        );
                        return null;
                    }
                },
            );

            promises.push(...chainPromises);
        } catch (error) {
            log.error(
                `Error fetching investments for chain ${chainId} and user ${addr}`,
                error,
            );
        }
    }

    try {
        const results = await Promise.all(promises);
        return results.filter(Boolean) as Investment[];
    } catch (error) {
        log.error(
            `Error resolving investment promises for user ${addr}`,
            error,
        );
        return [];
    }
};

export const getLegacyCrosschainUserInvestments = async (
    addr: string,
    contract_manager: IContractManager,
): Promise<Investment[]> => {
    const promises: Promise<Investment[]>[] = [];

    for (const chainId of EVENT_FACTORY_CHAIN_IDS) {
        try {
            const investments = contract_manager.getContract(
                ContractType.LegacyEventFactory,
                chainId,
            );

            const addrs = await investments.contract.getUserInvestments(addr);

            const chainPromises = addrs.map(async (event_addr: string) => {
                try {
                    const _info = await investments.contract.getInvestmentInfo(
                        addr,
                        event_addr,
                    );

                    const token = contract_manager.getContractByAddress(
                        _info[0].tokenAddress,
                        ContractType.ERC20,
                        chainId,
                    );
                    const payment = contract_manager.getContractByAddress(
                        _info[0].paymentToken,
                        ContractType.ERC20,
                        chainId,
                    );
                    const vesting = contract_manager.getContractByAddress(
                        _info[0].vestingAddress,
                        ContractType.PlatformVesting,
                        chainId,
                    );
                    const is_airdrop = _info[0].eventType !== 0;

                    const [
                        token_symbol,
                        decimals,
                        payment_symbol,
                        payment_decimals,
                        grace_period,
                    ] = await Promise.all([
                        token.contract.symbol(),
                        token.contract.decimals(),
                        is_airdrop
                            ? Promise.resolve('')
                            : payment.contract.symbol(),
                        is_airdrop
                            ? Promise.resolve(18)
                            : payment.contract.decimals(),
                        is_airdrop
                            ? Promise.resolve(0)
                            : vesting.contract.gracePeriod(),
                    ]);

                    const invested = _info.w.value;
                    const amount = _info.w.amount;

                    const price = invested
                        .mul(BigInt(10 ** decimals).toString())
                        .div(amount);

                    return {
                        name: _info[0].name,
                        invest_amount: invested.toString(),
                        token_price: price.toString(),
                        token_symbol,
                        vesting_active: true,
                        contract_address: event_addr,
                        is_airdrop,
                        decimals,
                        start_date: new Date(
                            _info.v.start.mul(1000).toNumber(),
                        ).toISOString(),
                        cliff: new Date(
                            _info.v.cliff.mul(1000).toNumber(),
                        ).toISOString(),
                        end_date: new Date(
                            _info.v.cliff
                                .add(_info.v.duration)
                                .mul(1000)
                                .toNumber(),
                        ).toISOString(),
                        released: _info.w.distributedAmount.toString(),
                        total: amount.toString(),
                        available: _info.claimable.toString(),
                        initial_unlock: _info.v.initialUnlockPercent.toString(),
                        refunded: _info.w.refunded,
                        refund_deadline: parseInt(grace_period.toString()),
                        vesting_address: vesting.contract.address,
                        payment_symbol,
                        payment_decimals,
                        chainId,
                        token_address: _info[0].tokenAddress,
                        contract_type: ContractType.LegacyEventFactory,
                    };
                } catch (error) {
                    log.error(
                        `Error processing investment at ${event_addr} for ${addr}`,
                        error,
                    );
                    return null;
                }
            });

            promises.push(
                Promise.all(chainPromises).then((investments) =>
                    investments.filter(Boolean),
                ) as Promise<Investment[]>,
            );
        } catch (error) {
            log.error(
                `Error fetching investments for chain ${chainId} and user ${addr}`,
                error,
            );
        }
    }

    try {
        const results = await Promise.all(promises);
        return results.flat();
    } catch (error) {
        log.error(
            `Error resolving investment promises for user ${addr}`,
            error,
        );
        return [];
    }
};

const getLegacyUserInvestments = async (
    addr: string,
    contract_manager: IContractManager,
): Promise<Investment[]> => {
    // if legacy return empty array
    if (!HAS_LEGACY_INVESTMENTS) return [];

    const investments = contract_manager.getContract(
        ContractType.Investments,
        DEFAULT_CHAIN_ID,
    );

    const addrs = await investments.contract.getUserInvestments(addr);

    return Promise.all(
        addrs.map(async (crowdfunding_addr: string) => {
            const {
                name,
                invested,
                tokenPrice,
                tokenSymbol: token_symbol,
                vestingActive: vesting_active,
                isAirdrop: is_airdrop,
            } = await investments.contract.getInvestmentInfo(
                addr,
                crowdfunding_addr,
            );

            let info: Investment = {
                name,
                invest_amount: invested.toString(),
                token_price: tokenPrice.toString(),
                token_symbol,
                vesting_active,
                contract_address: crowdfunding_addr,
                is_airdrop,
                decimals: TOKEN_DECIMALS[token_symbol] || 18,
                payment_symbol: PaymentTokenSymbol(DEFAULT_CHAIN_ID),
                payment_decimals: 18,
                chainId: DEFAULT_CHAIN_ID,
                contract_type: ContractType.Investments,
            };

            if (vesting_active) {
                const {
                    startDate,
                    cliff,
                    duration,
                    total,
                    available,
                    released,
                    initialUnlockPercent: initial_unlock,
                } = await investments.contract.getVestingInfo(
                    addr,
                    crowdfunding_addr,
                );

                info = {
                    ...info,
                    start_date: new Date(
                        startDate.mul(1000).toNumber(),
                    ).toISOString(),
                    cliff: new Date(cliff.mul(1000).toNumber()).toISOString(),
                    end_date: new Date(
                        cliff.add(duration).mul(1000).toNumber(),
                    ).toISOString(),
                    released: released.toString(),
                    total: total.toString(),
                    available: available.toString(),
                    initial_unlock: initial_unlock.toString(),
                };
            }

            return info;
        }) as Investment[],
    );
};

export const getNFTToken = async (
    token_id: string,
    contract_manager: IContractManager,
): Promise<INFT> => {
    const nft = contract_manager.getContract(ContractType.NFT);

    const uri = await nft.contract.tokenURI(token_id);
    const { data } = await axios.get(uri);

    return { ...data, id: token_id };
};

export const getUsersNfts = async (
    address: string,
    contract_manager: IContractManager,
): Promise<INFT[]> => {
    const nft = contract_manager.getContract(ContractType.NFT);
    const token_ids = await nft.contract.walletOfOwner(address);

    const uris = await contract_manager.multicall(
        token_ids.map((id) => ({
            func_name: 'tokenURI',
            target: nft.contract.address,
            params: [id],
        })),
        { default_iface: nft.contract.interface },
    );

    const all_data = await Promise.all(
        uris.map(([uri]) => axios.get(uri).then(({ data }) => data)),
    );

    return all_data.map((d, i) => ({ ...d, id: token_ids[i].toString() }));
};
