import React, { useContext } from 'react';
import { AllocationPopUp } from '@decub8/ui';
import { useRouter } from 'next/router';

import { GlobalContext } from '@src/hooks/useGlobalContext';

const AllocationPopUpModal: React.FC = () => {
    const { showAllocationPopup, setShowAllocationPopup } =
        useContext(GlobalContext);

    const router = useRouter();

    const handleFinalClick = () => {
        router.push('/');
    };
    return (
        <AllocationPopUp
            show={showAllocationPopup}
            image_data={{
                image_url:
                    'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/GoldTier-Large.svg',
                value: 'Gold tier',
                label: 'Your investor tier',
            }}
            setShow={setShowAllocationPopup}
            progress_value="50,000.00"
            progress_percent={65}
            handleFinalClick={handleFinalClick}
        />
    );
};

export default AllocationPopUpModal;
