import React, { useEffect, useState } from 'react';

import { is_browser } from '@src/constants';
import { useAppSelector } from '@src/hooks';

export const VideoNFT: React.FC<{ src: string }> = ({ src }) => {
    const { refreshed } = useAppSelector((state) => state.layout);
    const [loaded, setLoaded] = useState(!refreshed);
    const loadingComplete = () => setLoaded(true);

    useEffect(() => {
        const video = is_browser && document.getElementById(src);
        video?.addEventListener('loadeddata', loadingComplete);
        return () => {
            video?.removeEventListener('loadeddata', loadingComplete);
        };
    }, []);

    return (
        <video
            width="100%"
            className={`"rounded-t ${loaded ? '' : 'animate-pulse'}`}
            autoPlay
            id={src}
            loop
            muted
            poster={'/icons/nft-placeholder.svg'}
        >
            <source src={src} type="video/mp4" />
            Your browser does not support this video.
        </video>
    );
};
