export const unstake_field = {
    'legacy-compound': 'user_shares',
    compound: 'user_shares',
    liquidity: 'lp_amount',
    'legacy-liquidity': 'lp_amount',
    'multi-asset': 'user_stake',
    v1: 'user_stake',
};

export const NFT_ID_MAP = {
    Genesis: [0, 570],
    ARize: [571, 1141],
    Babylon: [1142, 2025],
};

export const STAKE_METHOD = {
    compound: 'deposit',
    'legacy-compound': 'deposit',
    liquidity: 'addLiquidityAndLock',
    'legacy-liquidity': 'addLiquidityAndLock',
    'multi-asset': 'stake',
    v1: 'stake',
};

export const UNSTAKE_METHOD = {
    'legacy-compound': 'withdraw',
    compound: 'withdraw',
    liquidity: 'unlockAndRemoveLP',
    'legacy-liquidity': 'unlockAndRemoveLP',
    'multi-asset': 'unStake',
    v1: 'unStake',
};

export const pool_headings = [
    {
        text: 'Pools',
    },
    {
        text: 'Reward',
        info: {
            info: 'The estimated yearly returns made from the pool.',
            text_color: 'text-secondary',
        },
    },
    {
        text: 'NFT boost',
        info: {
            info: 'Specified APY boost applies if you own an appropriate NFT',
            text_color: 'text-secondary',
        },
    },
    {
        text: 'Lock term',
    },
    {
        text: 'Capacity',
    },
    {
        text: 'Your stake',
    },
];
