import React from 'react';

interface SliderProps {
    className?: string;
    inputClassName?: string;
    label?: string;
    max: number;
    min?: number;
    step?: number;
    onChange: (e) => void;
    value: string;
    is_percentage?: boolean;
}

export const SliderInput: React.FC<SliderProps> = ({
    className,
    inputClassName,
    label,
    max,
    min = 0,
    step = 1,
    onChange,
    value,
    is_percentage,
}) => {
    return (
        <div className={`${className || ''}`}>
            <label
                htmlFor="default-range"
                className="block mb-2 text-sm font-medium text-secondary"
            >
                {label}
            </label>
            <div className="bg-surface-level-two rounded flex space-x-4 items-center">
                <input
                    onChange={onChange}
                    max={max}
                    step={step}
                    min={min}
                    id="slider"
                    type="range"
                    value={value}
                    className={`w-full h-3 overflow-hidden bg-surface-level-three rounded appearance-none cursor-pointer ${inputClassName}`}
                />
                {is_percentage && <div className="w-12">{value}%</div>}
            </div>
        </div>
    );
};
