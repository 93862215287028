import React, { useState } from 'react';

import { INFT } from '@src/ts/interfaces';
import { isTouchDevice } from '@src/utils/device';

import { VideoNFT } from '.';

export const NFT: React.FC<{ data: INFT }> = ({ data }) => {
    const { data: src, name, project, description } = data;
    const [hovering, setHovering] = useState(false);
    const is_touchscreen = isTouchDevice();
    return (
        <div
            className="rounded bg-surface-level-one border border-border border-opacity-5 relative"
            onMouseEnter={() => !is_touchscreen && setHovering(true)}
            onMouseLeave={() => !is_touchscreen && setHovering(false)}
            onClick={() => is_touchscreen && setHovering(!hovering)}
        >
            <VideoNFT src={src} />

            <div className="my-4 px-4">
                <h4 className="text-xl mb-2">
                    <strong>{name}</strong>
                </h4>
                <p className="text-secondary">{project}</p>
            </div>
            {/* Covering text that appears on hover */}
            <div
                className={`w-full h-full animated fadeIn faster left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover text-white absolute p-3 ${
                    hovering ? '' : 'hidden'
                }`}
                id="modal-id"
            >
                <div className="absolute bg-surface-level-one inset-0 z-0 p-4">
                    <h3 className="text-2xl mb-1 mt-4">
                        <strong>{name}</strong>
                    </h3>
                    <p className="text-secondary mb-8 text-sm">{project}</p>

                    <p className="text-sm">{description}</p>
                </div>
            </div>
        </div>
    );
};
