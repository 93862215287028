import { Interface, Result } from '@ethersproject/abi';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import axios from 'axios';

import { NETWORKS } from '@src/config';
import { ContractType } from '@src/ts/constants';
import { ContractConfig } from '@src/ts/interfaces';
import { Evt } from '@src/utils/event';

export interface IRpcManager {
    provider: JsonRpcProvider;
    setProvider(): void;
    getChainId(): number;
}

export interface IContractManager {
    /**
     * Function that gets a contract based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContract(type: ContractType, chainId?: number): ContractWrapper;
    /**
     * Function that gets a contracts address based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContractAddress(type: ContractType, chainId?: number): string;
    /**
     * Function that gets a contract based on the address and type
     * @param address contract address
     * @param type contract type
     * @param chainId chain id for the contract
     */
    getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets a contract based on the address and abi
     * @param address contract address
     * @param abi contract abi
     * @param chainId chain id for the contract
     */
    getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets the contract interface based on the type
     * @param type ContractType enum
     */
    getContractInterface(type: ContractType): Interface;
    /**
     * Function that gets the provider based on the chain id
     * @param chainId chain id for the provider
     */
    getProvider(chainId: number): JsonRpcProvider;
    init(): Promise<void>;
    /**
     * Function that makes a multicall to the multicall contract
     * @param calls Array of calls to make
     * @param params Parameters for the multicall
     */
    multicall(calls: Call[], params?: MultiCallParams): Promise<Result[]>;
}

// RPC Manager class that manages the RPC provider
export class RpcManager implements IRpcManager {
    public NewProvider = new Evt<JsonRpcProvider>();
    public provider: JsonRpcProvider;
    chain_id: number;

    constructor(chain_id: number) {
        this.chain_id = chain_id;
        this.setProvider();
    }

    public setProvider(): void {
        const endpoint = this.getNetwork(this.chain_id);
        if (!endpoint) {
            console.log(
                `[RPC MANAGER] No endpoint for chain ${this.chain_id}. Please check the RPC urls in the config`,
            );
            return;
        }

        this.provider = new JsonRpcProvider(endpoint);
    }

    // get url based on given weights
    private getNetwork(chain_id: number): string {
        const chainData = NETWORKS[chain_id];
        if (!chainData || chainData.rpc.length === 0) {
            console.log('[RPC_MANAGER] No chaindata for chain', chain_id);
            return null;
        }
        if (chainData.rpc.length !== chainData.weights.length) {
            console.log(
                `[RPC_MANAGER] Weights and urls do not match for chain ${chain_id}. Using fixed url, not weighted`,
            );
            return chainData.rpc[0];
        }

        const random = Math.random();
        let cumulativeWeight = 0;

        for (let i = 0; i < chainData.weights.length; i++) {
            cumulativeWeight += chainData.weights[i];
        }

        if (cumulativeWeight !== 1) {
            console.log(
                `[RPC_MANAGER] Weights do not add up to 1 for chain ${this.chain_id}. Please check the RPC urls in the config to ensure expected behavior`,
            );
        }

        for (let i = 0; i < chainData.rpc.length; i++) {
            cumulativeWeight += chainData.weights[i];
            if (random < cumulativeWeight) {
                return chainData.rpc[i];
            }
        }
        return null;
    }

    public getChainId(): number {
        return this.chain_id;
    }
}

// todo | at some point add a way to handle errors and retrying.
// todo | maybe its an idea to have an 'execute(function, ...paramaters)' method on this class, that takes a function w/ params and retries it a few times or something.
// todo | will need to be careful with this. As contract calling is done all over the place, easy to miss something while implementing this idea.
// ContractWrapper class that wraps the ethers contract class
export class ContractWrapper {
    public contract: Contract;

    constructor(
        private address: string,
        private abi: ContractInterface,
        private provider: JsonRpcProvider,
    ) {
        this.contract = new Contract(address, abi, provider);
    }

    public setProvider(provider: JsonRpcProvider): void {
        this.provider = provider;
        this.contract = new Contract(this.address, this.abi, provider);
    }
}

// Manager for contracts on a single chain
export class SinlgeChainContractManager {
    private contracts: { [key: string]: ContractWrapper } = {};
    private tried_urls: string[] = [];

    constructor(private rpcManager: IRpcManager) {
        this.tried_urls.push(this.rpcManager.provider.connection.url);
        this.setProvider = this.setProvider.bind(this);
    }

    public async init(): Promise<void> {
        // check the current provider endpoint which has been set in the constructor of the rpcManager
        const isHealthy = await this.isRPCHealthy(
            this.rpcManager.provider.connection.url,
        );
        if (!isHealthy.good) {
            console.log(
                `[SINGLECHAIN_CONTRACT_MANAGER] Endpoint for chain ${this.rpcManager.getChainId()} is unhealthy. url: ${
                    this.rpcManager.provider.connection.url
                }`,
            );

            this.tried_urls.push(this.rpcManager.provider.connection.url);
            this.rpcManager.provider = undefined;
            for (const url of NETWORKS[this.rpcManager.getChainId()].rpc) {
                if (this.tried_urls.includes(url)) continue;
                this.tried_urls.push(url);

                const isHealthy = await this.isRPCHealthy(url);
                if (isHealthy.good) {
                    this.rpcManager.provider = new JsonRpcProvider(url);
                    break;
                }
            }

            if (this.rpcManager.provider?.connection?.url === undefined) {
                console.log(
                    `[SINGLECHAIN_CONTRACT_MANAGER] No healthy provider for chain ${this.rpcManager.getChainId()} set. Please check the RPC urls in the config`,
                );
            }
        }

        return this.rpcManager.setProvider();
    }

    private async isRPCHealthy(
        url: string,
    ): Promise<{ good: boolean; chain_id: number; url: string }> {
        let res;
        try {
            res = await axios.post(
                url,
                {
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'eth_blockNumber',
                    params: [],
                },
                { timeout: 3000 },
            );

            if (res.data.error) {
                console.log(
                    '[SINGLECHAIN_CONTRACT_MANAGER] error checking health of',
                    url,
                    res.data.error,
                );
                return {
                    good: false,
                    chain_id: this.rpcManager.getChainId(),
                    url,
                };
            }

            return {
                good: res.status === 200,
                chain_id: this.rpcManager.getChainId(),
                url,
            };
        } catch (error) {
            console.log(
                '[SINGLECHAIN_CONTRACT_MANAGER] error checking health of',
                url,
                error,
            );
            return {
                good: false,
                chain_id: this.rpcManager.getChainId(),
                url,
            };
        }
    }

    public getContract(
        address: string,
        abi: ContractInterface,
    ): ContractWrapper {
        if (this.contracts[address]) return this.contracts[address];
        this.contracts[address] = new ContractWrapper(
            address,
            abi,
            this.rpcManager.provider,
        );
        return this.contracts[address];
    }

    private setProvider(): void {
        for (const contract of Object.values(this.contracts)) {
            contract.setProvider(this.rpcManager.provider);
        }
    }
}

export class ContractManager implements IContractManager {
    private managers: { [key: number]: SinlgeChainContractManager } = {};

    constructor(
        private rpcManagers: { [key: number]: RpcManager },
        private config: ContractConfig,
        private abis: { [key in ContractType]: ContractInterface },
        private default_chain_id: number,
    ) {
        for (const chain_id of Object.keys(rpcManagers)) {
            this.managers[chain_id] = new SinlgeChainContractManager(
                rpcManagers[chain_id],
            );
        }
    }

    public async init(): Promise<void> {
        await Promise.all(
            Object.values(this.managers).map((manager) => manager.init()),
        );
    }

    public getContract(type: ContractType, chainId?: number): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;
        return this.managers[chainId].getContract(
            this.getContractAddress(type, chainId),
            this.abis[type],
        );
    }

    public getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, this.abis[type]);
    }

    public getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, abi);
    }

    public getContractAddress(type: ContractType, chainId?: number): string {
        if (!chainId) chainId = this.default_chain_id;

        if (
            [
                ContractType.MultiCall,
                ContractType.Investments,
                ContractType.EventFactory,
                ContractType.LegacyEventFactory,
                ContractType.CompoundStaking,
                ContractType.Vault,
                ContractType.Tiers,
                ContractType.LiquidityStaking,
            ].includes(type)
        ) {
            return this.config[type][chainId];
        }

        if (
            [ContractType.BaseToken, ContractType.PaymentToken].includes(type)
        ) {
            return this.config[type][chainId].address;
        }

        return this.config[type];
    }

    public getContractInterface(type: ContractType): Interface {
        return new Interface(JSON.stringify(this.abis[type]));
    }

    public getProvider(chainId: number): JsonRpcProvider {
        return this.rpcManagers[chainId].provider;
    }

    public async multicall(
        calls: Call[],
        params: MultiCallParams = {},
    ): Promise<Result[]> {
        let { chain_id } = params;
        if (!chain_id) chain_id = this.default_chain_id;

        const { default_iface } = params;

        const multicall = this.getContract(ContractType.MultiCall, chain_id);

        const mapped_calls = calls.map(
            ({ iface = default_iface, target, func_name, params = [] }) => ({
                target,
                callData: iface.encodeFunctionData(func_name, params),
            }),
        );

        const res = await multicall.contract.callStatic.aggregate(mapped_calls);
        try {
            return calls.map(({ iface = default_iface, func_name }, idx) =>
                iface.decodeFunctionResult(func_name, res.returnData[idx]),
            );
        } catch (error) {
            console.log('error decoding multicall', error);
            return [];
        }
    }
}

export interface Call {
    target: string;
    func_name: string;
    iface?: Interface;
    params?: unknown[];
}

export interface MultiCallParams {
    default_iface?: Interface;
    chain_id?: number;
}
