import React from 'react';
import { TierCarousel } from '@decub8/ui';

import { TierRewards } from '..';

import { useRewardCarousel } from './hook';

export const RewardCarousel: React.FC<{
    tier_rewards: TierRewards;
    setShowClaimModal: (bool: boolean) => void;
    setClaimTier: (num: number) => void;
}> = ({ tier_rewards, setShowClaimModal, setClaimTier }) => {
    const props = useRewardCarousel(
        tier_rewards,
        setShowClaimModal,
        setClaimTier,
    );

    return <TierCarousel {...props} />;
};
