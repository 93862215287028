import { useEffect } from 'react';
import router from 'next/router';

import { useAppSelector } from '@src/hooks/index';

export const useProtectedRoute = (noUnsupportedCountry: boolean): boolean => {
    const { user, loading } = useAppSelector((state) => state?.auth) || {};

    useEffect(() => {
        if (!user && !loading) {
            router.push('/login');
        } else if (user && noUnsupportedCountry && !user.country_supported) {
            router.push('/account');
        }
    }, [user, loading, noUnsupportedCountry]);

    return (
        !user || loading || (noUnsupportedCountry && !user.country_supported)
    );
};
