import React from 'react';

import { useAppSelector } from '@src/hooks/index';
import { UserGroup } from '@src/ts/constants';
import { ProjectItemProps } from '@src/ts/props';

import { EventContainer } from './Event/EventContainer';
import { ProjectContextProvider } from './context';
import ProjectOverview from './Overview';

export const ProjectComponent: React.FC<ProjectItemProps> = ({ project }) => {
    const learn_to_earn_ref = React.createRef<HTMLDivElement>();
    const { user } = useAppSelector((state) => state.auth);
    const {
        events: [event],
    } = project;

    const has_event = !!event;
    const should_display_event = has_event
        ? event?.is_hidden
            ? UserGroup.ProjectManager.includes(user?.role)
            : true
        : false;

    const scrollToLearnToEarn = () =>
        learn_to_earn_ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    return (
        <ProjectContextProvider
            project={project}
            event={event}
            children={
                <div className="flex flex-col lg:flex-row lg:gap-5">
                    <div
                        className={`w-full ${
                            should_display_event ? 'lg:w-[780px]' : ''
                        }`}
                    >
                        <ProjectOverview
                            project={project}
                            ref={learn_to_earn_ref}
                            scrollToLearnToEarn={scrollToLearnToEarn}
                            event={event}
                            should_display_event={should_display_event}
                        />
                    </div>
                    {should_display_event && (
                        <EventContainer
                            project={project}
                            scrollToLearnToEarn={scrollToLearnToEarn}
                            event={event}
                        />
                    )}
                </div>
            }
        />
    );
};
