import { gql } from '@apollo/client';

export const CONFIG = {
    GET_CONFIG_ITEM: gql`
        query GetConfig($key: ConfigKey) {
            retrieveConfig(key: $key) {
                id
                type
                value
            }
        }
    `,
};
