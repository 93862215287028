import { api_client } from '@src/bootstrap/index';
import { PROJECT } from '@src/services/project';
import { NotificationData } from '@src/ts/interfaces';

export const getNotificationData = async (): Promise<NotificationData> => {
    const { getNotification } = await api_client.query<{
        getNotification: NotificationData;
    }>({
        query: PROJECT.GET_NOTIFICATION_DATA,
        fetchPolicy: 'network-only',
    });

    return getNotification;
};

export const getPlatformImages = async () => {
    const images = await api_client.query({
        query: PROJECT.GET_PLATFORM_IMAGES,
    });
    return images['retrieveImages'];
};
