import React, { useState } from 'react';

import { Check } from '@src/components/Check';
import { InputProps } from '@src/ts/props';

export const Input: React.FC<InputProps> = ({
    className,
    inputClassName,
    id,
    label,
    name,
    onChange,
    onBlur,
    readOnly,
    placeholder,
    helperText,
    type,
    value,
    error = false,
    max,
    valid,
    initialized = true,
    lighter = false,
    children,
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div className={`${className || ''}`}>
            <div className="flex space-between items-center text-secondary">
                {label && (
                    <label className="text-sm mb-2" htmlFor={id ? id : name}>
                        {label}
                    </label>
                )}
                {(initialized && error && (
                    <div className="flex-1 text-right text-xs text-accent mb-2">
                        {error}
                    </div>
                )) ||
                    (helperText && (
                        <div
                            className={`flex-1 text-right text-xs mb-2 ${
                                !error && valid ? 'text-green-500' : ''
                            }`}
                        >
                            {helperText}
                        </div>
                    ))}
                {valid && (
                    <div
                        data-testid="check-icon div"
                        className={`text-right ${helperText ? '' : 'flex-1'}`}
                    >
                        <Check className="text-green-500 mr-auto mb-2 ml-2 float-right" />
                    </div>
                )}
            </div>

            <div
                data-testid="input div"
                className={`flex items-center border rounded ${
                    (initialized && error && ' border-accent') ||
                    (focused && 'border-secondary') ||
                    'border-border border-opacity-5'
                } ${
                    inputClassName || lighter
                        ? 'bg-surface-level-one'
                        : 'bg-surface-level-zero'
                }`}
            >
                <input
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        if (onBlur) {
                            onBlur();
                        }
                        setFocused(false);
                    }}
                    className={`flex-1 shadow appearance-none w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline rounded dark-placeholder ${
                        inputClassName || lighter
                            ? 'bg-surface-level-one'
                            : 'bg-surface-level-zero'
                    }`}
                    id={id}
                    name={name}
                    data-testid={id}
                    readOnly={readOnly}
                    onChange={onChange}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    maxLength={max}
                />
                {children}
            </div>
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
};

export { CurrencyInput } from './CurrencyInput';
export { SliderInput } from './SliderInput';
