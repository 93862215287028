import Head from 'next/head';

import { CONTENT } from '@src/config';
import { MetaProps } from '@src/ts/props';

const Meta: React.FC<MetaProps> = ({
    title = 'Home',
    keywords = CONTENT.metaKeywords,
    description = CONTENT.metaDescription,
    image_url,
    current_url,
}) => {
    return (
        <Head>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta charSet="utf-8" />

            <link rel="icon" href={CONTENT.favicon} />
            <title>{`${CONTENT.companyName} | ${title}`}</title>
            <link href={CONTENT.font.url} rel="stylesheet" />
            <script src="https://www.google.com/recaptcha/enterprise.js?render=6LceFXoeAAAAANTg7s3ZU8UZ5xUEeK1Hw0IbQlZ2" />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image_url} />
            <meta property="og:url" content={current_url} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={current_url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image_url} />
        </Head>
    );
};

export default Meta;
