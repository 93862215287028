import React from 'react';

export const ArrowRight: React.FC<{ className?: string }> = ({
    className = '',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 ${className}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            data-testid="arrow-right-icon"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
        </svg>
    );
};
