import { Header as HeaderUI } from '@decub8/ui';

import { useInvestmentContext } from '@src/hooks/usePortfolioContext';

import { useHeader } from './hooks';

const Header: React.FC<{
    className?: string;
}> = ({ className }) => {
    const props = useHeader();

    const { refund_complete_modal_open, refund_modal_open } =
        useInvestmentContext();

    return refund_complete_modal_open || refund_modal_open ? null : (
        <HeaderUI {...props} className={className} />
    );
};

export default Header;
