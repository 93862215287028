import { api_client } from '@src/bootstrap/index';
import { TIER } from '@src/services/tier';
import { ITier, IUserTier } from '@src/ts/interfaces';

export const getTierOfUser = async (): Promise<IUserTier> => {
    try {
        const { retrieveUserTier } = await api_client.query<{
            retrieveUserTier: IUserTier;
        }>({
            query: TIER.RETRIEVE_USER_TIER,
            fetchPolicy: 'network-only',
        });

        return retrieveUserTier;
    } catch {
        // If error is thrown likely due to user not having wallet attached to their account yet
    }
};

export const getTiers = async (): Promise<ITier[]> => {
    const res = api_client.query({
        query: TIER.RETRIEVE_TIERS,
    });

    return res.then((data: { retrieveTiers: ITier[] }) => {
        return data.retrieveTiers || [];
    });
};
