import React from 'react';

export const SearchIcon: React.FC = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.2222 13.4444C10.6586 13.4444 13.4444 10.6586 13.4444 7.2222C13.4444 3.78577 10.6586 1 7.2222 1C3.78577 1 1 3.78577 1 7.2222C1 10.6586 3.78577 13.4444 7.2222 13.4444Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.9998 15L11.6165 11.6167"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
