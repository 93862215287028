import React from 'react';
import { RequirementsCard as RequirementsCardUI } from '@decub8/ui';

import { User } from '@src/ts/interfaces';

import { ReferralTabs, TierRewards } from '..';

import { useRequirementsCard } from './hook';

export const RequirementsCard: React.FC<{
    user: User;
    wallet_verified: boolean;
    nav: ReferralTabs;
    setRequirementsComplete: (bool: boolean) => void;
    className: string;
    min_referrer_tier: number;
    setShowCongratsModal: (bool: boolean) => void;
    last_key_with_zero_reward: number;
    is_tier_rewards_uniform: boolean;
    setClaimTier: (tier: number) => void;
    tier_rewards: TierRewards;
    setNav: (nav: ReferralTabs) => void;
    setClaimedReward: (bool: boolean) => void;
}> = ({
    user,
    wallet_verified,
    className,
    nav,
    setRequirementsComplete,
    min_referrer_tier,
    setShowCongratsModal,
    last_key_with_zero_reward,
    is_tier_rewards_uniform,
    tier_rewards,
    setClaimTier,
    setNav,
    setClaimedReward,
}) => {
    const props = useRequirementsCard(
        user,
        wallet_verified,
        nav,
        min_referrer_tier,
        setShowCongratsModal,
        last_key_with_zero_reward,
        is_tier_rewards_uniform,
        tier_rewards,
        setClaimTier,
        setRequirementsComplete,
        setNav,
        setClaimedReward,
    );
    return <RequirementsCardUI className={className} {...props} />;
};
