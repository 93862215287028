import { SocialLink } from '@src/ts/interfaces';
const resource_links = [
    'medium',
    'web',
    'youtube',
    'twitch',
    'github',
    'gitbook',
    'whitepaper',
];
const social_links = ['telegram', 'twitter', 'discord'];

export const reduceSocials = (
    socials: SocialLink[],
): { socials: SocialLink[]; resources: SocialLink[] } =>
    socials.reduce(
        (acc, curr) => {
            if (social_links.includes(curr.type)) {
                return {
                    ...acc,
                    socials: [...acc.socials, curr],
                };
            } else if (resource_links.includes(curr.type)) {
                return {
                    ...acc,
                    resources: [...acc.resources, curr],
                };
            }
            return acc;
        },
        {
            socials: [],
            resources: [],
        },
    );
