import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { ContractType } from '@src/ts/constants';
import { nFormatter } from '@src/utils/format';
import { cancellablePromise } from '@src/utils/promise';

const burn_addr = '0x000000000000000000000000000000000000dEaD';

const base_token = CONTRACT[ContractType.BaseToken][DEFAULT_CHAIN_ID];

export const useBurn = (burnMechanism: boolean) => {
    const [burn_amount, setBurnAmount] = useState('');
    const { contract_manager } = useGlobalContext();

    useEffect(() => {
        if (!burnMechanism) return;

        const token = contract_manager.getContract(ContractType.BaseToken);

        const { promise, cancel } = cancellablePromise(
            Promise.all([
                token.contract
                    .balanceOf(burn_addr)
                    .then((b: BigNumber) =>
                        parseFloat(formatUnits(b, base_token.decimals)),
                    ),
                base_token.max_supply
                    ? token.contract
                          .totalSupply()
                          .then((b: BigNumber) =>
                              parseFloat(formatUnits(b, base_token.decimals)),
                          )
                    : Promise.resolve(0),
            ]),
        );

        promise
            .then(([burned, totalSupply]: number[]) => {
                if (base_token.max_supply) {
                    burned += base_token.max_supply - totalSupply;
                }

                setBurnAmount(nFormatter(burned, 0));
            })
            .catch(() => {
                return true;
            });

        return cancel;
    }, [contract_manager, burn_amount]);

    return {
        dcb_burned: burnMechanism ? burn_amount : undefined,
    };
};
