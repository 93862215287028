import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
    allocation_drawer_open: boolean;
    connecting: boolean;
    connection_error_modal_open: boolean;
    incorrect_network_modal_open: boolean;
    safety_modal_open: boolean;
    staking_modal_open: boolean;
    verification_modal_open: boolean;
    verficiation_modal_text: { title: string; body: string };
    refreshed: boolean;
}

const initialState = {
    allocation_drawer_open: false,
    connecting: false,
    connection_error_modal_open: false,
    incorrect_network_modal_open: false,
    safety_modal_open: false,
    staking_modal_open: false,
    verification_modal_open: false,
    verficiation_modal_text: { title: '', body: '' },
    refreshed: false,
} as LayoutState;

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setUpgradeTierDrawerOpen(state, action: PayloadAction<boolean>) {
            state.allocation_drawer_open = action.payload;
        },
        setConnecting(state, action: PayloadAction<boolean>) {
            state.connecting = action.payload;
        },
        setConnectionErrorModalOpen(state, action: PayloadAction<boolean>) {
            state.connection_error_modal_open = action.payload;
        },
        setIncorrectNetworkModalOpen(state, action: PayloadAction<boolean>) {
            state.incorrect_network_modal_open = action.payload;
        },
        setSafetyModalOpen(state, action: PayloadAction<boolean>) {
            state.safety_modal_open = action.payload;
        },
        setStakingModalOpen(state, action: PayloadAction<boolean>) {
            state.staking_modal_open = action.payload;
        },
        setVerificationModalOpen(state, action: PayloadAction<boolean>) {
            state.verification_modal_open = action.payload;
        },
        setVerificationModalDetails(
            state,
            action: PayloadAction<{ title: string; body: string }>,
        ) {
            state.verficiation_modal_text = action.payload;
        },
        setRefreshed(state, action: PayloadAction<boolean>) {
            state.refreshed = action.payload;
        },
    },
});

export const {
    setUpgradeTierDrawerOpen,
    setConnecting,
    setConnectionErrorModalOpen,
    setIncorrectNetworkModalOpen,
    setSafetyModalOpen,
    setStakingModalOpen,
    setVerificationModalOpen,
    setVerificationModalDetails,
    setRefreshed,
} = layoutSlice.actions;
export default layoutSlice.reducer;
