import { AppDispatch } from '@src/bootstrap/store';
import { setStakingModalOpen } from '@src/features/layout';
import { setCurrentPool, setModalState } from '@src/features/staking';
import { StakingModalTabs } from '@src/ts/types';

export const handleOpenDrawer = (
    dispatch: AppDispatch,
    has_stake: boolean,
    can_claim: boolean,
    id: number,
): void => {
    if (has_stake && can_claim) {
        dispatch(setModalState(StakingModalTabs.Withdraw));
    }
    dispatch(setStakingModalOpen(true));
    dispatch(setCurrentPool(id));
};
