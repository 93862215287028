import { useEffect, useMemo, useState } from 'react';
import { StatsBarProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import axios, { AxiosResponse } from 'axios';

import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts';
import { useAppSelector } from '@src/hooks';
import { bnFormatter, nFormatter } from '@src/utils/format';
import { cancellablePromise } from '@src/utils/promise';
import { getStakingVersions } from '@src/utils/staking';

import { filterPoolsByStakingVersions } from '../StakingPools/hooks';

import {
    aggregateValues,
    getTokenPrices,
    reducePools,
    weiToNumber,
} from './util';

export const useStakingAnalytics = (is_legacy: boolean): StatsBarProps => {
    const { pools } = useAppSelector((state) => state.staking);

    const versions = useMemo(() => getStakingVersions(is_legacy), [is_legacy]);
    const filtered_pools = useMemo(
        () => filterPoolsByStakingVersions(pools, versions),
        [pools, versions],
    );

    const [prices, setPrices] = useState({});
    const [circulating_supply, setCircSupply] = useState(0);
    const [data, setData] = useState(reducePools(filtered_pools));

    const base_token_staked =
        data[BaseTokenSymbol]?.total_staked || BigNumber.from(0);

    const percent_of_supply =
        (weiToNumber(base_token_staked.toString()) /
            (circulating_supply || 1)) *
        100;

    useEffect(() => {
        setData(reducePools(filtered_pools));
    }, [filtered_pools]);

    useEffect(() => {
        let is_mounted = true;
        const updatePrices = async () => {
            const prices = await getTokenPrices(data);
            is_mounted && setPrices(prices);
        };
        updatePrices();
        return () => {
            is_mounted = false;
        };
    }, [data]);

    useEffect(() => {
        const { promise, cancel } = cancellablePromise(
            axios.get(CONTENT.tokenInfoApi),
        );

        promise
            .then(({ data }: AxiosResponse) => setCircSupply(data || 0))
            .catch(() => true);

        return cancel;
    }, [CONTENT.tokenInfoApi]);

    return {
        data: [
            {
                label: 'Total value locked',
                value: `${nFormatter(
                    aggregateValues('total_staked', data, prices),
                )} USD`,
                logo: 'locked',
            },
            {
                label: `Total ${BaseTokenSymbol} staked (${percent_of_supply.toFixed(
                    2,
                )}%)`,
                value: `${bnFormatter(base_token_staked)} ${BaseTokenSymbol}`,
                logo: 'staking-stats',
            },
            {
                label: 'Total rewards earned',
                value: `${
                    aggregateValues('earned_rewards', data, prices) === 0
                        ? '0.0'
                        : nFormatter(
                              aggregateValues('earned_rewards', data, prices),
                          )
                } USD`,
                logo: 'reward-stats',
            },
            {
                label: 'Total value staked',
                value: `${
                    aggregateValues('user_stake', data, prices) === 0
                        ? '0.0'
                        : nFormatter(
                              aggregateValues('user_stake', data, prices),
                          )
                } USD`,
                logo: 'power',
            },
        ],
    };
};
