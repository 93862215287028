import { ExclamationIcon } from '@heroicons/react/solid';

export const Warning: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return (
        <div className="text-sm rounded bg-surface-level-two p-4 my-8 border border-border border-opacity-5">
            <div className="flex space-x-2 mb-2">
                <ExclamationIcon className="w-8 h-8 text-secondary" />
                <h3 className="text-lg">
                    <strong> Important information</strong>
                </h3>
            </div>
            {children}
        </div>
    );
};
