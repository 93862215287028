import React from 'react';

import { useProtectedRoute } from './hooks';

export const ProtectedRoute: React.FC<{
    children?: JSX.Element | JSX.Element[];
    noUnsupportedCountry?: boolean;
}> = ({ children, noUnsupportedCountry = false }) => {
    const loading = useProtectedRoute(noUnsupportedCountry);

    if (loading) return null;

    return <>{children}</>;
};
