import React from 'react';

import { User } from '@src/ts/interfaces';

import SecurityInfo from './SecurityInfo';
import UserInfo from './UserInfo';
import WalletInfo from './WalletInfo';

export const AccountInfo: React.FC<{ user: User }> = ({ user }) => {
    return (
        <div className="my-5">
            <h4 className="text-lg mb-8">
                <strong>Account</strong>
            </h4>
            <UserInfo user={user} />
            <WalletInfo user={user} />
            <SecurityInfo user={user} />
        </div>
    );
};
