import React from 'react';

export type TypographyType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export type TextStyle = 'primary' | 'secondary';

export const Typography: React.FC<{
    type?: TypographyType;
    style?: TextStyle;
    className?: string;
    children?: React.ReactNode;
}> = ({ children, className = '', type = 'p', style = 'primary' }) => {
    switch (type) {
        case 'h1':
            return (
                <h1
                    className={`text-3xl md:text-4xl text-${style} ${className}`}
                >
                    <strong>{children}</strong>
                </h1>
            );

        case 'h2':
            return (
                <h2
                    className={`text-xl md:text-2xl text-${style} ${className}`}
                >
                    <strong>{children}</strong>
                </h2>
            );

        case 'h3':
            return (
                <h3 className={`text-lg md:text-xl text-${style} ${className}`}>
                    <strong>{children}</strong>
                </h3>
            );

        case 'h4':
            return (
                <h4 className={`md:text-lg text-${style} ${className}`}>
                    <strong>{children}</strong>
                </h4>
            );

        case 'h5':
            return (
                <h4
                    className={`text-sm md:text-base text-${style} ${className}`}
                >
                    <strong>{children}</strong>
                </h4>
            );

        default:
            return <p className={`text-${style} ${className}`}>{children}</p>;
    }
};
