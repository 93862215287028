import { CONTENT } from '@src/config';
import { IContractManager } from '@src/contracts/manager';
import { ContractType } from '@src/ts/constants';
import { parseBalance } from '@src/utils/web3';

import { TierRewards } from '.';

const ZERO_ADDR = '0x0000000000000000000000000000000000000000';

export const getReferralData = async (
    contract_manager: IContractManager,
    setTierRewards: (rewards: TierRewards) => void,
    setUserReferred: (referred: number) => void,
    setRewardsEarned: (earned: string) => void,
    setClaimedReward: (claimed: boolean) => void,
    setClaimable: (claimable: (prevState: undefined) => undefined) => void,
    setInitial: (initial: boolean) => void,
    account?: string,
) => {
    const ro_wallet_store = contract_manager.getContract(
        ContractType.WalletStore,
    );
    const { address, interface: iface } = ro_wallet_store.contract;
    const [
        referred_data,
        { totalClaimed, totalClaimable, referrer },
        ...response
    ] = await contract_manager.multicall([
        {
            target: address,
            func_name: 'getReferrals',
            iface,
            params: [account || ZERO_ADDR],
        },
        {
            target: address,
            func_name: 'users',
            iface,
            params: [account || ZERO_ADDR],
        },
        ...CONTENT.tiers.tierConstants.map((_, id) => ({
            target: address,
            func_name: 'rewards',
            iface,
            params: [id],
        })),
    ]);

    const tier_rewards = response.reduce(
        // eslint-disable-next-line
        (rewards, [_, amount], idx) => ({
            ...rewards,
            [idx]: amount,
        }),
        {},
    );

    setTierRewards(tier_rewards);
    setUserReferred(referred_data[0].length);
    setRewardsEarned(parseBalance(totalClaimed));
    setClaimedReward(referrer !== ZERO_ADDR);
    setClaimable(totalClaimable);
    setInitial(false);
};
