import React from 'react';
import { Footer as FooterUI } from '@decub8/ui';

import { useFooter } from './hooks';

const Footer: React.FC = () => {
    const props = useFooter();

    return <FooterUI {...props} />;
};

export default Footer;
