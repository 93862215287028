import { RefObject, useEffect, useState } from 'react';

export const useIntersection = (
    element: RefObject<Element>,
    rootMargin: string,
): boolean => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );

        element.current && observer.observe(element.current);

        return () =>
            element.current !== null && observer.unobserve(element.current);
    }, []);

    return isVisible;
};
