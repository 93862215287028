// crowdfunding address -> vesting site
export const UNSUPPORTED_VESTING_LINKS = {
    56: {
        '0x7664D534F097596Db72a5AA660Ce6d069eeAD625':
            'https://vesting.battleverse.io',
        '0x953db3Fa62F08AF70CD1ef9E2dA85131B7E5860A':
            'https://algoblocks.decubate.com',
        '0x835539be1c675949C1877A71Ba8141541586d5BF':
            'https://vesting.arizetech.com',
        '0xd356d9EB7502e2062dD392A0be804ddCf1BaCcb4':
            'https://vesting.arizetech.com',
        '0xD6d79556031359F9eb9Ae7116dd9a032D09b7e08':
            'https://tradetomato-private.decubate.com/',
        '0x5A9E81F2DD3De01ad28F608F23d726F3235742bc':
            'https://decubate.vesting.eof.gg',
    },
    97: {
        '0xe684341a8eb0301EF5ba316F9438E63EE508d6f6':
            'https://vesting.battleverse.io',
    },
};
